import { Pipe, PipeTransform } from '@angular/core';

export const joinWithAnd = (arr: any[], andReplacement: string) => {
  if (arr.length === 1) {
    return arr[0];
  }
  const firsts = arr.slice(0, arr.length - 1);
  const last = arr[arr.length - 1];
  return `${firsts.join(', ')}${andReplacement}${last}`;
};

@Pipe({
  name: 'joinWithAnd',
  standalone: false,
})
export class JoinWithAndPipe implements PipeTransform {
  public transform(value: any[] | any, and: string = ' and ', propertyName?: string): string {
    if (!Array.isArray(value)) {
      return value;
    }

    if (!value.length) {
      return null;
    }

    if (propertyName) {
      return joinWithAnd(
        value.map((value) => value[propertyName]),
        and,
      );
    }

    return joinWithAnd(value, and);
  }
}

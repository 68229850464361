import { Pipe, PipeTransform } from '@angular/core';

const values: Record<string, string> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  approved: 'Pricing Approved',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  denied: 'Pricing Rejected',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  escalate_to_manager: 'Manager Approval Requested',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  manager_approved: 'Manager Approved',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  manager_denied: 'Manager Denied',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  billing_rejected: 'Sent back to Price QA',
};

@Pipe({
  name: 'priceQADecision',
  standalone: false,
})
export class PriceQADecisionPipe implements PipeTransform {
  public transform(value: string): string {
    return values[value] || value;
  }
}

@switch (shippingLineName) { @case ('Maersk') {
<img src="assets/icons/shiplines/maersk-white.svg" class="h-4 w-16 hidden dark:block" />
<img src="assets/icons/shiplines/maersk-black.svg" class="h-4 w-16 block dark:hidden" />
} @case ('Hapag-Lloyd') {
<img src="assets/icons/shiplines/hapag-lloyd-white.svg" class="h-4 w-16 hidden dark:block" />
<img src="assets/icons/shiplines/hapag-lloyd-black.svg" class="h-4 w-16 block dark:hidden" />
} @case ('CMA') {
<img src="assets/icons/shiplines/cma-white.svg" class="h-4 w-16 hidden dark:block" />
<img src="assets/icons/shiplines/cma-black.svg" class="h-4 w-16 block dark:hidden" />
} @case ('Hyundai') {
<img src="assets/icons/shiplines/hmm-white.svg" class="h-4 w-16 hidden dark:block" />
<img src="assets/icons/shiplines/hmm.svg" class="h-4 w-16 block dark:hidden" />
} @case ('MSC') {
<img src="assets/icons/shiplines/msc-white.svg" class="h-4 w-16 hidden dark:block" />
<img src="assets/icons/shiplines/msc-black.svg" class="h-4 w-16 block dark:hidden" />
} @case ('ZIM') {
<img src="assets/icons/shiplines/zim-white.svg" class="h-4 w-16 hidden dark:block" />
<img src="assets/icons/shiplines/zim.svg" class="h-4 w-16 block dark:hidden" />
} @case ('COSCO') {
<img src="assets/icons/shiplines/cosco.svg" class="h-4 w-16" />
} @case ('Evergreen') {
<img src="assets/icons/shiplines/evergreen.svg" class="h-4 w-16" />
} @case ('Hamburg Sud') {
<img src="assets/icons/shiplines/hamburg.svg" class="h-4 w-16" />
} @case ('ONE') {
<img src="assets/icons/shiplines/one.svg" class="h-4 w-16" />
} @case ('OOCL') {
<img src="assets/icons/shiplines/oocl.png" class="h-4 w-16" />
} }

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const years_1 = require("../../../calculation/years");
const constants_1 = require("../constants");
const constants_2 = require("../constants");
const constants_3 = require("../constants");
const pattern_1 = require("../../../utils/pattern");
const AbstractParserWithWordBoundary_1 = require("../../../common/parsers/AbstractParserWithWordBoundary");
const PATTERN = new RegExp(`(?:с)?\\s*(${constants_3.ORDINAL_NUMBER_PATTERN})` + `(?:` + `\\s{0,3}(?:по|-|–|до)?\\s{0,3}` + `(${constants_3.ORDINAL_NUMBER_PATTERN})` + `)?` + `(?:-|\\/|\\s{0,3}(?:of)?\\s{0,3})` + `(${pattern_1.matchAnyPattern(constants_1.MONTH_DICTIONARY)})` + `(?:` + `(?:-|\\/|,?\\s{0,3})` + `(${constants_2.YEAR_PATTERN}(?![^\\s]\\d))` + `)?` + `${constants_1.REGEX_PARTS.rightBoundary}`, constants_1.REGEX_PARTS.flags);
const DATE_GROUP = 1;
const DATE_TO_GROUP = 2;
const MONTH_NAME_GROUP = 3;
const YEAR_GROUP = 4;
class RUMonthNameLittleEndianParser extends AbstractParserWithWordBoundary_1.AbstractParserWithWordBoundaryChecking {
  patternLeftBoundary() {
    return constants_1.REGEX_PARTS.leftBoundary;
  }
  innerPattern() {
    return PATTERN;
  }
  innerExtract(context, match) {
    const result = context.createParsingResult(match.index, match[0]);
    const month = constants_1.MONTH_DICTIONARY[match[MONTH_NAME_GROUP].toLowerCase()];
    const day = constants_3.parseOrdinalNumberPattern(match[DATE_GROUP]);
    if (day > 31) {
      match.index = match.index + match[DATE_GROUP].length;
      return null;
    }
    result.start.assign("month", month);
    result.start.assign("day", day);
    if (match[YEAR_GROUP]) {
      const yearNumber = constants_2.parseYear(match[YEAR_GROUP]);
      result.start.assign("year", yearNumber);
    } else {
      const year = years_1.findYearClosestToRef(context.refDate, day, month);
      result.start.imply("year", year);
    }
    if (match[DATE_TO_GROUP]) {
      const endDate = constants_3.parseOrdinalNumberPattern(match[DATE_TO_GROUP]);
      result.end = result.start.clone();
      result.end.assign("day", endDate);
    }
    return result;
  }
}
exports.default = RUMonthNameLittleEndianParser;

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureFlagService } from '../../services/feature-flag.service';
import { AuthService } from '../../services/auth.service';
import { CarrierCompletedLoadsService } from '../../../modules/carrier/carrier-completed-loads/carrier-completed-loads.service';
import { CarrierPendingLoadsService } from '../../../modules/carrier/carrier-pending-loads/carrier-pending-loads.service';
import { map } from 'rxjs/operators';
import { CpgUfgService } from '../../../modules/carrier/cpg-ufg/cpg-ufg.service';
import { IntercomService } from '../../services/intercom.service';
import { MatDialog } from '@angular/material/dialog';
import { CpgUfgRankingsComponent } from '../../../modules/carrier/cpg-ufg/cpg-ufg-rankings/cpg-ufg-rankings.component';

@Component({
  selector: 'td-side-nav-carrier',
  templateUrl: './side-nav-carrier.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SideNavCarrierComponent {
  public canRunVPF$: Observable<boolean>;
  public loadFinderToken$: Observable<string>;
  public completedActionNeededCount$: Observable<number> = this.completedLoadsService.actionNeededCount$;
  public pendingActionNeededCount$: Observable<number> = this.pendingLoadsService.pendingSummary$.pipe(
    map((summary) => summary.exclusiveCount + summary.upForGrabsCount + summary.signatureNeededCount),
  );
  public isCpg$: Observable<boolean> = this.authService.userInfo$.pipe(
    map((userInfo) => {
      return userInfo?.isCompanyCPG || false;
    }),
  );
  public isCpgUfg$: Observable<boolean> = this.cpgUfgService.isCpgUfg$;

  public isImportExport$: Observable<boolean> = this.authService.userInfo$.pipe(
    map((userInfo) => {
      return userInfo?.isCompanyImportExport || userInfo?.isCompanyDomestic || false;
    }),
  );

  constructor(
    private featureFlagService: FeatureFlagService,
    private authService: AuthService,
    private completedLoadsService: CarrierCompletedLoadsService,
    private pendingLoadsService: CarrierPendingLoadsService,
    private cpgUfgService: CpgUfgService,
    private intercom: IntercomService,
    private matDialog: MatDialog,
  ) {
    this.canRunVPF$ = this.featureFlagService.isFlagActive$('canRunVPF');
    this.loadFinderToken$ = this.authService.carrierToken$;
  }

  public async openCarrierRankings() {
    this.matDialog.open(CpgUfgRankingsComponent, {
      panelClass: ['w-full', 'lg:w-1/2', 'xl:w-1/3'],
      autoFocus: false,
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

@Pipe({
  name: 'phone',
  standalone: false,
})
export class PhonePipe implements PipeTransform {
  public transform(value: any): string {
    return phoneTransform(value);
  }
}

export const phoneTransform = (value: any): string => {
  const parsedNumber = parsePhoneNumberFromString(`${value}`, 'US');
  if (parsedNumber && parsedNumber.isValid()) {
    return parsedNumber.formatNational();
  }
  return value;
};

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const AbstractTimeExpressionParser_1 = require("../../../common/parsers/AbstractTimeExpressionParser");
class FRTimeExpressionParser extends AbstractTimeExpressionParser_1.AbstractTimeExpressionParser {
  primaryPrefix() {
    return "(?:(?:[àa])\\s*)?";
  }
  followingPhase() {
    return "\\s*(?:\\-|\\–|\\~|\\〜|[àa]|\\?)\\s*";
  }
  extractPrimaryTimeComponents(context, match) {
    if (match[0].match(/^\s*\d{4}\s*$/)) {
      return null;
    }
    return super.extractPrimaryTimeComponents(context, match);
  }
}
exports.default = FRTimeExpressionParser;

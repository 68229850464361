import { Pipe, PipeTransform } from '@angular/core';
import { TierType } from '../../modules/internal/dashboard/interfaces';

const tierTypeToGradient = {
  platinum: 'from-[#949494] to-[#797979]',
  black: 'from-[#545456] to-[#2D2D2E]',
  gold: 'from-[#FDD169] to-[#FEB300]',
  silver: 'from-[#92A0AD] to-[#53687A]',
  probation: 'from-[#F47661] to-[#EF6048]',
};

@Pipe({
  name: 'tierTypeGradient',
  standalone: false,
})
export class TierTypeGradientPipe implements PipeTransform {
  public transform(value: TierType | string): string {
    if (!value) {
      return '';
    }

    return tierTypeToGradient[(value as string).toLowerCase()];
  }
}

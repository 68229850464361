"use strict";

const mapAgeCleaner = require("map-age-cleaner");
class ExpiryMap {
  constructor(maxAge, data) {
    this.maxAge = maxAge;
    this[Symbol.toStringTag] = 'Map';
    this.data = new Map();
    // Bootstrap the cleanup process which frees up memory when an item expires
    mapAgeCleaner(this.data);
    if (data) {
      // tslint:disable-line:early-exit
      for (const [key, value] of data) {
        this.set(key, value);
      }
    }
  }
  get size() {
    return this.data.size;
  }
  clear() {
    this.data.clear();
  }
  delete(key) {
    return this.data.delete(key);
  }
  has(key) {
    return this.data.has(key);
  }
  get(key) {
    const value = this.data.get(key);
    if (value) {
      return value.data;
    }
    return;
  }
  set(key, value) {
    this.data.set(key, {
      maxAge: Date.now() + this.maxAge,
      data: value
    });
    return this;
  }
  values() {
    return this.createIterator(item => item[1].data);
  }
  keys() {
    return this.data.keys();
  }
  entries() {
    return this.createIterator(item => [item[0], item[1].data]);
  }
  forEach(callbackfn, thisArg) {
    for (const [key, value] of this.entries()) {
      callbackfn.apply(thisArg, [value, key, this]);
    }
  }
  [Symbol.iterator]() {
    return this.entries();
  }
  *createIterator(projection) {
    for (const item of this.data.entries()) {
      yield projection(item);
    }
  }
}
module.exports = ExpiryMap;
import {
  AfterViewInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  ElementRef,
  HostListener,
  Input,
  QueryList,
} from '@angular/core';
import { ScrollIntoViewAnchorDirective } from './scroll-into-view-anchor.directive';
import { BehaviorSubject, Observable } from 'rxjs';

@Directive({
  selector: '[tdScrollIntoViewContainer]',
  standalone: false,
})
export class ScrollIntoViewContainerDirective implements AfterViewInit {
  private currentAnchor: ScrollIntoViewAnchorDirective;
  @ContentChildren(ScrollIntoViewAnchorDirective) private anchors: QueryList<ScrollIntoViewAnchorDirective>;
  @Input() public marginPx = 40; // mt-5 but kind of magic number that felt good
  private height$$ = new BehaviorSubject<number>(null);

  public get height$(): Observable<number> {
    return this.height$$.asObservable();
  }

  constructor(private cd: ChangeDetectorRef, private el: ElementRef<HTMLElement>) {}

  public ngAfterViewInit(): void {
    const fragment = window.location.hash;
    if (fragment) {
      this.scrollToFragment(fragment.replace('#', ''));
    } else {
      this.updateActiveElement(this.el.nativeElement);
    }
    this.setupResizeObserver();
  }

  @HostListener('scroll', ['$event'])
  private onScroll($event: Event) {
    this.updateActiveElement($event.target as HTMLElement);
  }

  private updateActiveElement(el: HTMLElement) {
    if (!this.anchors) {
      return;
    }
    const targetRect = el.getBoundingClientRect();
    for (let i = this.anchors.length - 1; i >= 0; i--) {
      const anchor = this.anchors.get(i);
      const rect = anchor.getBoundingClientRect();
      if (rect) {
        if (rect.top - targetRect.top < this.marginPx) {
          this.setAnchor(anchor);
          return;
        }
      }
    }
  }

  private scrollToFragment(fragment: string) {
    if (!fragment) {
      return;
    }
    const anchor = this.anchors.find((a) => a.tdScrollIntoViewAnchor === fragment);
    if (anchor) {
      anchor.scrollIntoView(true);
      anchor.setActive(true);
    }
  }

  private setAnchor(anchor: ScrollIntoViewAnchorDirective) {
    if (this.currentAnchor === anchor) {
      return;
    }
    this.currentAnchor = anchor;
    for (const a of this.anchors.toArray()) {
      a.setActive(a === anchor);
    }
  }

  private setupResizeObserver() {
    const ne = this.el.nativeElement;
    this.height$$.next(ne.offsetHeight);
    const ro = new ResizeObserver((entries) => {
      if (entries?.length) {
        this.height$$.next(entries[0].contentRect.height);
      }
    });
    ro.observe(ne);
  }
}

var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
export function purry(fn, args, lazy) {
  var diff = fn.length - args.length;
  var arrayArgs = Array.from(args);
  if (diff === 0) {
    return fn.apply(void 0, arrayArgs);
  }
  if (diff === 1) {
    var ret = function (data) {
      return fn.apply(void 0, __spreadArray([data], arrayArgs, false));
    };
    if (lazy || fn.lazy) {
      ret.lazy = lazy || fn.lazy;
      ret.lazyArgs = args;
    }
    return ret;
  }
  throw new Error('Wrong number of arguments');
}
import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, combineLatestWith, Observable, Subject, takeUntil } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { InternalService } from '../services/internal.service';

@Directive({
  selector: '[tdInternal]',
  standalone: false,
})
export class InternalDirective implements OnDestroy {
  private hasView = false;
  private destroy$$ = new Subject<void>();
  private isInternal$: Observable<boolean>;

  private elseTemplateRef$$ = new BehaviorSubject<TemplateRef<any>>(null);

  @Input()
  public set tdInternal(_: void) {}

  @Input()
  public set tdInternalElse(templateRef: TemplateRef<any>) {
    this.elseTemplateRef$$.next(templateRef);
  }

  constructor(
    private authService: InternalService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cd: ChangeDetectorRef,
  ) {
    this.isInternal$ = this.authService.isInternalShared$;
    this.listenForViewUpdates();
  }

  public ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  private listenForViewUpdates() {
    this.isInternal$
      .pipe(takeUntil(this.destroy$$), combineLatestWith(this.elseTemplateRef$$))
      .subscribe(([isInternal, elseRef]) => {
        if (!isInternal) {
          this.clearView();
          if (elseRef) {
            this.viewContainer.createEmbeddedView(elseRef);
            this.hasView = true;
          }
          this.cd.markForCheck();
        } else {
          this.clearView();
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
          this.cd.markForCheck();
        }
      });
  }

  private clearView() {
    if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
      this.cd.markForCheck();
    }
  }
}

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const constants_1 = require("../constants");
const pattern_1 = require("../../../utils/pattern");
const AbstractParserWithWordBoundary_1 = require("../../../common/parsers/AbstractParserWithWordBoundary");
const PATTERN = new RegExp(`([0-9]{4})[\\.\\/\\s]` + `(?:(${pattern_1.matchAnyPattern(constants_1.MONTH_DICTIONARY)})|([0-9]{1,2}))[\\.\\/\\s]` + `([0-9]{1,2})` + "(?=\\W|$)", "i");
const YEAR_NUMBER_GROUP = 1;
const MONTH_NAME_GROUP = 2;
const MONTH_NUMBER_GROUP = 3;
const DATE_NUMBER_GROUP = 4;
class NLCasualYearMonthDayParser extends AbstractParserWithWordBoundary_1.AbstractParserWithWordBoundaryChecking {
  innerPattern() {
    return PATTERN;
  }
  innerExtract(context, match) {
    const month = match[MONTH_NUMBER_GROUP] ? parseInt(match[MONTH_NUMBER_GROUP]) : constants_1.MONTH_DICTIONARY[match[MONTH_NAME_GROUP].toLowerCase()];
    if (month < 1 || month > 12) {
      return null;
    }
    const year = parseInt(match[YEAR_NUMBER_GROUP]);
    const day = parseInt(match[DATE_NUMBER_GROUP]);
    return {
      day: day,
      month: month,
      year: year
    };
  }
}
exports.default = NLCasualYearMonthDayParser;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const constants_1 = require("../../nl/constants");
const pattern_1 = require("../../../utils/pattern");
const AbstractParserWithWordBoundary_1 = require("../../../common/parsers/AbstractParserWithWordBoundary");
const weekdays_1 = require("../../../common/calculation/weekdays");
const PATTERN = new RegExp("(?:(?:\\,|\\(|\\（)\\s*)?" + "(?:op\\s*?)?" + "(?:(deze|vorige|volgende)\\s*(?:week\\s*)?)?" + `(${pattern_1.matchAnyPattern(constants_1.WEEKDAY_DICTIONARY)})` + "(?=\\W|$)", "i");
const PREFIX_GROUP = 1;
const WEEKDAY_GROUP = 2;
const POSTFIX_GROUP = 3;
class NLWeekdayParser extends AbstractParserWithWordBoundary_1.AbstractParserWithWordBoundaryChecking {
  innerPattern() {
    return PATTERN;
  }
  innerExtract(context, match) {
    const dayOfWeek = match[WEEKDAY_GROUP].toLowerCase();
    const weekday = constants_1.WEEKDAY_DICTIONARY[dayOfWeek];
    const prefix = match[PREFIX_GROUP];
    const postfix = match[POSTFIX_GROUP];
    let modifierWord = prefix || postfix;
    modifierWord = modifierWord || "";
    modifierWord = modifierWord.toLowerCase();
    let modifier = null;
    if (modifierWord == "vorige") {
      modifier = "last";
    } else if (modifierWord == "volgende") {
      modifier = "next";
    } else if (modifierWord == "deze") {
      modifier = "this";
    }
    return weekdays_1.createParsingComponentsAtWeekday(context.reference, weekday, modifier);
  }
}
exports.default = NLWeekdayParser;

<form class="grid grid-cols-1 relative" [formGroup]="formGroup" (ngSubmit)="doSave()">
  <mat-form-field class="flex flex-col py-1">
    <mat-label>Anything else we should keep in mind when booking loads for you?</mat-label>
    <textarea class="w-full" rows="3" matInput formControlName="additionalPreference"></textarea>
  </mat-form-field>
  <div class="flex justify-end w-full col-span-2">
    <button mat-flat-button color="primary" type="submit">Save</button>
  </div>
  @if (networkActive) {
  <div class="bg-white opacity-50 absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
    <mat-spinner></mat-spinner>
  </div>
  }
</form>

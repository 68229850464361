{
  "name": "@csvbox/angular",
  "version": "1.1.14",
  "description": "Angular adapter for csvbox.io",
  "author": "csvbox-io",
  "license": "MIT",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/csvbox-io/angular-adapter.git"
  },
  "peerDependencies": {
    "@angular/common": "^8.0.0 || ^9.0.0 || ^10.0.0 || ^11.0.0 || ^12.0.0 || ^13.0.0 || ^14.0.0 || ^15.0.0 || ^16.0.0 || ^17.0.0 || ^18.0.0 || ^19.0.0",
    "@angular/core": "^8.0.0 || ^9.0.0 || ^10.0.0 || ^11.0.0 || ^12.0.0 || ^13.0.0 || ^14.0.0 || ^15.0.0 || ^16.0.0 || ^17.0.0 || ^18.0.0 || ^19.0.0"
  },
  "dependencies": {
    "tslib": "^2.0.0"
  },
  "keywords": [
    "csv",
    "importer",
    "uploader",
    "csvbox",
    "parser",
    "csv-parse",
    "excel",
    "xls",
    "csv import",
    "csv upload",
    "spreadsheet import",
    "angular csv",
    "csv parse",
    "csv to json"
  ],
  "bugs": {
    "url": "https://github.com/csvbox-io/angular-adapter/issues"
  },
  "homepage": "https://github.com/csvbox-io/angular-adapter#readme",
  "directories": {
    "example": "example"
  },
  "main": "bundles/csvbox-angular.umd.js",
  "module": "fesm2015/csvbox-angular.js",
  "es2015": "fesm2015/csvbox-angular.js",
  "esm2015": "esm2015/csvbox-angular.js",
  "fesm2015": "fesm2015/csvbox-angular.js",
  "typings": "csvbox-angular.d.ts",
  "sideEffects": false
}
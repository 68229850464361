import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { LohiLoad } from '../../../../global-types';
import { LoadBillingService } from '../../../../services/load-billing.service';

@Component({
  selector: 'td-load-billiing-deadhead-dispute',
  templateUrl: './load-billiing-deadhead-dispute.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LoadBilliingDeadheadDisputeComponent {
  @Input() public load: LohiLoad;
  @Input() public oldRate: number;
  public loadType: string;
  public deadHeadPayout: number;
  public disputeResponse: boolean = false;
  constructor(private lbs: LoadBillingService, private cdr: ChangeDetectorRef) {}
  public async disputeDeadhead(event: Event) {
    const resp = await this.lbs.runDisputeDeadhead(this.load.id, this.oldRate);
    this.deadHeadPayout = resp['cents'] / 100;
    this.disputeResponse = true;
    this.cdr.detectChanges();
  }
}

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const constants_1 = require("../constants");
const pattern_1 = require("../../../utils/pattern");
const AbstractParserWithWordBoundary_1 = require("../../../common/parsers/AbstractParserWithWordBoundary");
const weekdays_1 = require("../../../common/calculation/weekdays");
const PATTERN = new RegExp("(?:(?:\\,|\\(|\\（)\\s*)?" + "(?:(?:ce)\\s*)?" + `(${pattern_1.matchAnyPattern(constants_1.WEEKDAY_DICTIONARY)})` + "(?:\\s*(?:\\,|\\)|\\）))?" + "(?:\\s*(dernier|prochain)\\s*)?" + "(?=\\W|\\d|$)", "i");
const WEEKDAY_GROUP = 1;
const POSTFIX_GROUP = 2;
class FRWeekdayParser extends AbstractParserWithWordBoundary_1.AbstractParserWithWordBoundaryChecking {
  innerPattern() {
    return PATTERN;
  }
  innerExtract(context, match) {
    const dayOfWeek = match[WEEKDAY_GROUP].toLowerCase();
    const weekday = constants_1.WEEKDAY_DICTIONARY[dayOfWeek];
    if (weekday === undefined) {
      return null;
    }
    let suffix = match[POSTFIX_GROUP];
    suffix = suffix || "";
    suffix = suffix.toLowerCase();
    let modifier = null;
    if (suffix == "dernier") {
      modifier = "last";
    } else if (suffix == "prochain") {
      modifier = "next";
    }
    return weekdays_1.createParsingComponentsAtWeekday(context.reference, weekday, modifier);
  }
}
exports.default = FRWeekdayParser;

import { _reduceLazy } from './_reduceLazy';
import { purry } from './purry';
export function flatten() {
  return purry(_flatten, arguments, flatten.lazy);
}
function _flatten(items) {
  return _reduceLazy(items, flatten.lazy());
}
(function (flatten) {
  function lazy() {
    return function (next) {
      if (Array.isArray(next)) {
        return {
          done: false,
          hasNext: true,
          hasMany: true,
          next: next
        };
      }
      return {
        done: false,
        hasNext: true,
        next: next
      };
    };
  }
  flatten.lazy = lazy;
})(flatten || (flatten = {}));
import { Pipe, PipeTransform } from '@angular/core';
import { CarrierContactType } from '../global-types';

const statuses: Record<CarrierContactType, string> = {
  admin: 'Admin',
  driver: 'Driver',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  internal_dispatcher: 'Internal Dispatcher',
  owner: 'Owner',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '3rd_party_dispatcher': '3rd Party Dispatcher',
};

@Pipe({
  name: 'carrierContactTypePipe',
  standalone: false,
})
export class CarrierContactTypePipe implements PipeTransform {
  public transform(value: CarrierContactType): string {
    return statuses[value];
  }
}

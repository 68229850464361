import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LohiLoad } from '../../../global-types';
import {
  TourChargeUpload,
  TourLoadPendingCharge,
} from '../../../../modules/carrier/carrier-loads/carrier-loads.service';
import { NetworkableDestroyableComponent } from '../../networkable-destroyable.component';
import { LightboxComponent } from '../../lightbox/lightbox.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadBillingService } from '../../../services/load-billing.service';
import { isFileUploadTypeValid } from '../../../utilities/isFileUploadTypeValid';
import { SnackBarService } from '../../../services/snackbar.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'td-load-billing-pending-charge-list',
  templateUrl: './load-billing-pending-charge-list.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LoadBillingPendingChargeListComponent extends NetworkableDestroyableComponent {
  private imageRef: MatDialogRef<LightboxComponent>;
  private networkActiveCharge$$ = new BehaviorSubject<Record<number, boolean>>({});
  public networkActiveCharge$ = this.networkActiveCharge$$.asObservable();
  @Input() public load: LohiLoad = null;
  @Input() public pendingCharges: TourLoadPendingCharge[] = [];
  @Input() public canEditCharges: boolean = false;
  @Input() public hideLastBorder: boolean = false;

  constructor(
    public matDialog: MatDialog,
    private loadBillingService: LoadBillingService,
    private snackbar: SnackBarService,
  ) {
    super();
  }

  public async onTourChargeEditFileChange(pendingCharge: TourLoadPendingCharge, event) {
    if (isFileUploadTypeValid(event.target.files)) {
      const [file]: File[] = event.target.files;
      if (file) {
        this.networkActiveChargeSet(pendingCharge.id, true);
        await this.loadBillingService.uploadTourPendingChargeFile(pendingCharge, file);
        event.target.value = '';
        this.networkActiveChargeSet(pendingCharge.id, false);
      } else {
        this.snackbar.showError('No File Specified');
        event.target.value = '';
      }
    } else {
      this.snackbar.showError('No file selected or invalid file type selected in upload');
    }
  }

  public async deletePendingCharge(pendingCharge: TourLoadPendingCharge) {
    this.networkActiveChargeSet(pendingCharge.id, true);
    await this.loadBillingService.deletePendingCharge(pendingCharge.id);
    this.networkActiveChargeSet(pendingCharge.id, false);
  }

  public async deleteCustomChargeFile(pendingCharge: TourLoadPendingCharge, fileId: number) {
    this.networkActiveChargeSet(pendingCharge.id, true);
    await this.loadBillingService.removePendingChargeFile(fileId);
    this.networkActiveChargeSet(pendingCharge.id, false);
  }

  private networkActiveChargeSet(chargeID: number, b: boolean) {
    const val = {
      ...this.networkActiveCharge$$.value,
    };
    val[chargeID] = b;
    this.networkActiveCharge$$.next(val);
  }
}

<div class="flex flex-col mb-1">
  <div class="flex flex-row justify-between">
    <div class="flex flex-1 bg-white dark:bg-gray-900 rounded-sm p-1">{{ comment.comment }}</div>
    @if (comment.isMyComment) {
    <div class="flex">
      <button mat-icon-button (click)="deleteComment(comment)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    }
  </div>
  <div class="flex flex-row justify-between">
    <div class="flex flex-col">
      <div class="flex text-xs text-gray-700 dark:text-white">{{ comment.commenterName }}</div>
    </div>
    <div class="flex text-xs text-gray-700 dark:text-white">{{ comment.createdAt | standardDate }}</div>
  </div>
</div>

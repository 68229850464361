<div class="flex flex-row items-center justify-between py-2">
  Which facility had live loading or unloading?
  <div class="p-2 flex flex-col flex-1">
    <mat-select placeholder="Select a facility" [formControl]="facilityControl">
      @for (stop of load.stops; track stop.id) {
      <mat-option [value]="stop.facilityId">
        {{ stop.facilityName }}
      </mat-option>
      }
    </mat-select>
  </div>
  <button color="primary" mat-raised-button (click)="disputeStopType($event)">Dispute Stop Type</button>
</div>
@if (stopTypePromopt) {
<div class="text-orange-500 text-lg">
  {{ stopTypePromopt }}
</div>
}

import { Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, ViewChild } from '@angular/core';

export interface ActionEvent {
  type: string;
  value: 'create' | 'edit' | 'delete';
}

@Component({
  selector: 'td-geofence-dropdown',
  templateUrl: './dropdown.component.html',
  standalone: false,
})
export class DropdownComponent {
  @Input() public dropdownType: 'create' | 'edit' | 'delete';
  @Input() public options: { label: string; value: string }[];
  @Output() public optionSelected = new EventEmitter<ActionEvent>();

  @ViewChild('itemList', { static: false }) public dropdownElement: ElementRef;
  @ViewChild('dropdownButton', { static: false }) public dropdownBtnElement: ElementRef;

  private _isDropdownOpen = false;

  @HostListener('document:click', ['$event'])
  public onOutsideClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (
      this._isDropdownOpen &&
      target.parentElement != this.dropdownBtnElement.nativeElement &&
      target !== this.dropdownBtnElement.nativeElement &&
      target !== this.dropdownElement.nativeElement
    ) {
      this._isDropdownOpen = false;
    }
  }

  public get isDropdownOpen() {
    return this._isDropdownOpen;
  }

  public toggleDropdown() {
    this._isDropdownOpen = !this._isDropdownOpen;
  }

  public selectOption(value: string) {
    this.optionSelected.emit({ type: value, value: this.dropdownType });
    this._isDropdownOpen = false;
  }

  public trackByFn(index: number, item: { label: string; value: string }) {
    return item.value;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { isBefore } from 'date-fns';
import { stringOrDateToDate } from '../utilities/dateTime';

@Pipe({
  name: 'isBefore',
  standalone: false,
})
export class IsBeforePipe implements PipeTransform {
  public transform(value: string | Date, dateToCompare: string | Date = new Date()): boolean {
    if (!value) {
      return false;
    }
    const date = stringOrDateToDate(value);
    const parsedDateToCompare = stringOrDateToDate(dateToCompare);
    return isBefore(date, parsedDateToCompare);
  }
}

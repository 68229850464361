import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstIncomplete',
  standalone: false,
})
export class FirstIncompletePipe implements PipeTransform {
  public transform<T extends { completedAt?: string }>(value: T[]): T {
    return value.find((value1) => !value1.completedAt);
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Optional,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FusedObservable } from '../../utilities/fusedObservable';
import { filter, map, startWith, take } from 'rxjs/operators';
import { combineLatestWith, interval, lastValueFrom, Observable, takeUntil } from 'rxjs';
import { NetworkableDestroyableComponent } from '../networkable-destroyable.component';
import {
  CarrierTag,
  CarrierTouchpoint,
  CarrierTouchpointType,
  CompanyCarrierTag,
  LongLat,
  TrailerType,
} from '../../global-types';
import { HereLookupResult } from '../../services/here.service';
import { ConstantsService } from '../../services/constants.service';
import { SnackBarService } from '../../services/snackbar.service';
import { CarrierTouchpointService } from './carrier-touchpoint.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  CarrierAssets,
  CarrierListItem,
  CarrierListService,
  LaneOfInterest,
} from '../../../modules/internal/carrier-list/carrier-list.service';
import { AssignCarrierPointOfContactComponent } from '../../../modules/internal/carrier-list/assign-carrier-point-of-contact/assign-carrier-point-of-contact.component';
import { AssignCarrierRepresentativeComponent } from '../../../modules/internal/carrier-list/assign-carrier-representative/assign-carrier-representative.component';

type LaneOfInterestForm = FormGroup<{
  id: FormControl<string>;
  originCity: FormControl<string>;
  originState: FormControl<string>;
  originLngLat: FormControl<LongLat>;
  destinationCity: FormControl<string>;
  destinationState: FormControl<string>;
  destinationLngLat: FormControl<LongLat>;
  desiredRateCents: FormControl<number>;
  trailerTypes: FormControl<TrailerType[]>;
  confirmedCarrier: FormControl<boolean>;
}>;

@Component({
  selector: 'td-carrier-touchpoint',
  templateUrl: './carrier-touchpoint.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CarrierTouchpointComponent extends NetworkableDestroyableComponent implements AfterViewInit {
  public carrierSearch = new FormControl<string>(null);
  public filteredCarriers$ = new FusedObservable(
    this.carrierListService.carriers$.pipe(map((carriers) => carriers.filter((carrier) => carrier.isCompany))),
    this.carrierSearch.valueChanges,
    ['name'],
  ).fused$;
  public carrierControl = new FormControl<CarrierListItem>(null);
  public carrierSelected = false;

  public carrierLanesOfInterest$ = this.carrierTouchpointService.carrierLanesOfInterest$;
  public loiForm = this.fb.group({
    lanesOfInterest: this.fb.array<LaneOfInterestForm>([]),
  });
  public equipment$ = this.constantsService.equipments$;

  public carrierTouchpoints$ = this.carrierTouchpointService.carrierTouchpoints$;
  public pinnedCarrierTouchpoints$ = this.carrierTouchpoints$.pipe(
    map((touchpoints) => touchpoints?.filter((touchpoint) => touchpoint.pinned)),
  );
  public touchpointForm = this.fb.group({
    companyID: new FormControl<string>(null, Validators.required),
    carrierTouchpointType: new FormControl<CarrierTouchpointType>('phone', Validators.required),
    notes: new FormControl<string>(''),
  });

  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public carrierTags$ = this.carrierTouchpointService.carrierTags$;
  public tagSearchControl = new FormControl('');
  public filteredCarrierTags$: Observable<CarrierTag[]> = new FusedObservable(
    this.carrierTouchpointService.allCarrierTags$.pipe(
      combineLatestWith(this.carrierTags$),
      map(([allTags, appliedTags]) =>
        allTags.filter((tag) => !appliedTags.find((appliedTag) => appliedTag.carrierTagId === tag.id)),
      ),
    ),
    this.tagSearchControl.valueChanges.pipe(
      startWith(''),
      filter((search) => typeof search === 'string'),
    ),
    ['name'],
  ).fused$;

  public assetsForm = this.fb.group({
    truckCount: new FormControl<number>(null),
    intermodalChassisCount: new FormControl<number>(null),
    ownsYard: new FormControl<boolean>(null),
    ownsLift: new FormControl<boolean>(null),
    ownsWarehouse: new FormControl<boolean>(null),
  });

  @ViewChild('notesInput') private notesInput: MatInput;
  @ViewChild('carrierSelect') private carrierSelect: MatSelect;

  private prompts = [
    'Try the F.O.R.D method: Family, Occupation, Recreation and Dreams',
    'What makes this company or contact unique?',
    'What kind of drivers does this carrier hire?',
    'What languages do they speak?',
    'Does this carrier have special equipment?',
    'Would this carrier be good for an upcoming project?',
  ];
  public prompt$ = interval(3000).pipe(
    takeUntil(this.destroy$$),
    startWith(0),
    map((i) => this.prompts.at(i % this.prompts.length)),
  );

  constructor(
    private carrierListService: CarrierListService,
    private carrierTouchpointService: CarrierTouchpointService,
    private fb: FormBuilder,
    private constantsService: ConstantsService,
    private snackbar: SnackBarService,
    private cd: ChangeDetectorRef,
    private matDialog: MatDialog,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    data: { carrierId?: string; carrierTouchpointType?: CarrierTouchpointType },
  ) {
    super();
    this.carrierListService.carriers$.pipe(takeUntil(this.destroy$$)).subscribe((carriers) => {
      if (this.carrierControl.value?.id) {
        this.carrierControl.setValue(carriers.find((carrier) => carrier.id === this.carrierControl.value?.id));
      }
    });
    this.carrierControl.valueChanges.pipe(takeUntil(this.destroy$$)).subscribe((carrier) => {
      this.carrierTouchpointService.setCarrierID(carrier.id);
      this.touchpointForm.controls.companyID.setValue(carrier.id);
      this.assetsForm.patchValue({
        truckCount: carrier.truckCount,
        intermodalChassisCount: carrier.intermodalChassisCount,
        ownsYard: carrier.ownsYard,
        ownsLift: carrier.ownsLift,
        ownsWarehouse: carrier.ownsWarehouse,
      });
      this.assetsForm.markAsPristine();
    });
    this.carrierLanesOfInterest$.pipe(takeUntil(this.destroy$$)).subscribe((lanes) => {
      this.loiForm.controls.lanesOfInterest.clear();
      lanes.forEach((lane) =>
        this.loiForm.controls.lanesOfInterest.push(
          this.fb.group({
            id: new FormControl(lane.id),
            originCity: new FormControl(lane.originCity),
            originState: new FormControl(lane.originState),
            originLngLat: new FormControl(lane.originLngLat, Validators.required),
            destinationCity: new FormControl(lane.destinationCity),
            destinationState: new FormControl(lane.destinationState),
            destinationLngLat: new FormControl(lane.destinationLngLat, Validators.required),
            desiredRateCents: new FormControl(lane.desiredRateCents),
            trailerTypes: new FormControl(lane.trailerTypes),
            confirmedCarrier: new FormControl(lane.confirmedCarrier),
          }),
        ),
      );
      this.loiForm.markAsPristine();
      this.cd.markForCheck();
    });
    if (data?.carrierId) {
      this.setInitialCarrierId(data.carrierId);
    }
    if (data?.carrierTouchpointType) {
      this.touchpointForm.get('carrierTouchpointType').setValue(data.carrierTouchpointType);
    }
  }

  public ngAfterViewInit() {
    setTimeout(() => this.carrierSelect.open(), 10);
  }

  public setOrigin(laneGroup: LaneOfInterestForm, origin: HereLookupResult) {
    laneGroup.controls.originCity.setValue(origin?.address?.city);
    laneGroup.controls.originState.setValue(origin?.address?.state);
    laneGroup.controls.originLngLat.setValue({
      longitude: origin?.position?.lng,
      latitude: origin?.position?.lat,
    });
  }

  public setDestination(laneGroup: LaneOfInterestForm, destination: HereLookupResult) {
    laneGroup.controls.destinationCity.setValue(destination?.address?.city);
    laneGroup.controls.destinationState.setValue(destination?.address?.state);
    laneGroup.controls.destinationLngLat.setValue({
      longitude: destination?.position?.lng,
      latitude: destination?.position?.lat,
    });
  }

  public async setInitialCarrierId(carrierId: string) {
    const carriers = await lastValueFrom(
      this.filteredCarriers$.pipe(
        filter((c) => !!c?.length),
        take(1),
      ),
    );
    const carrier = carriers.find((c) => c.id === carrierId);
    if (carrier) {
      this.carrierControl.setValue(carrier);
      this.carrierSelected = true;
      this.cd.markForCheck();
    }
  }

  public removeLane(i: number) {
    this.loiForm.markAsDirty();
    this.loiForm.controls.lanesOfInterest.removeAt(i);
  }

  @HostListener('document:keydown.a', ['$event'])
  private addLaneKeyboardShortcut(event: KeyboardEvent) {
    if (!['BODY', 'MAT-DIALOG-CONTAINER'].includes((event.target as HTMLElement).tagName)) {
      return;
    }
    event.preventDefault();
    this.addLane();
  }

  public addLane() {
    this.loiForm.markAsDirty();
    this.loiForm.controls.lanesOfInterest.push(
      this.fb.group({
        id: new FormControl<string>(null),
        originCity: new FormControl<string>(null),
        originState: new FormControl<string>(null),
        originLngLat: new FormControl<LongLat>(null, Validators.required),
        destinationCity: new FormControl<string>(null),
        destinationState: new FormControl<string>(null),
        destinationLngLat: new FormControl<LongLat>(null, Validators.required),
        desiredRateCents: new FormControl<number>(null),
        trailerTypes: new FormControl<TrailerType[]>(['dry_van']),
        confirmedCarrier: new FormControl(false),
      }),
    );
  }

  @HostListener('document:keydown.s', ['$event'])
  private updateLanesShortcut(event: KeyboardEvent) {
    if (!['BODY', 'MAT-DIALOG-CONTAINER'].includes((event.target as HTMLElement).tagName)) {
      return;
    }
    event.preventDefault();
    this.updateLanes();
  }

  public async updateLanes() {
    this.loiForm.markAllAsTouched();
    if (this.loiForm.valid) {
      if (this.isActive) {
        return;
      }
      try {
        this.networkActive$$.next(true);
        const success = await this.carrierTouchpointService.updateCarrierLanesOfInterest(
          this.carrierControl.value.id,
          this.loiForm.value.lanesOfInterest as LaneOfInterest[],
        );
        if (success) {
          this.snackbar.showMessage('Lanes updated');
        }
      } finally {
        this.networkActive$$.next(false);
      }
    }
  }

  public async saveTouchpoint() {
    this.touchpointForm.markAllAsTouched();
    if (this.touchpointForm.valid) {
      if (this.isActive) {
        return;
      }
      try {
        this.networkActive$$.next(true);
        const success = await this.carrierTouchpointService.createCarrierTouchpoint(
          this.touchpointForm.value as CarrierTouchpoint,
        );
        if (success) {
          this.touchpointForm.controls.notes.setValue(null);
          this.snackbar.showMessage('Touchpoint saved');
        }
      } finally {
        this.networkActive$$.next(false);
      }
    }
  }

  public async toggleTouchpointPin(touchpoint: CarrierTouchpoint) {
    if (this.isActive) {
      return;
    }
    try {
      this.networkActive$$.next(true);
      touchpoint.pinned = !touchpoint.pinned;
      const success = await this.carrierTouchpointService.updateCarrierTouchpoint(touchpoint);
      if (success) {
        this.touchpointForm.controls.notes.setValue(null);
        this.snackbar.showMessage('Touchpoint updated');
      }
    } finally {
      this.networkActive$$.next(false);
    }
  }

  @HostListener('document:keydown.n', ['$event'])
  private focusOnNotesShortcut(event: KeyboardEvent) {
    if (!['BODY', 'MAT-DIALOG-CONTAINER'].includes((event.target as HTMLElement).tagName)) {
      return;
    }
    event.preventDefault();
    this.notesInput.focus();
  }

  public promptReassignCarrierPointOfContact(carrier: CarrierListItem) {
    this.matDialog.open(AssignCarrierPointOfContactComponent, {
      data: { carrier },
    });
  }

  public promptReassignCarrierRepresentative(carrier: CarrierListItem) {
    this.matDialog.open(AssignCarrierRepresentativeComponent, {
      data: {
        id: carrier.id,
        carrierRepresentativeId: carrier.carrierRepresentativeId,
      },
    });
  }

  public async addTag(event: MatAutocompleteSelectedEvent, tags: CompanyCarrierTag[], tagInput: HTMLInputElement) {
    if (this.isActive) {
      return;
    }
    try {
      this.networkActive$$.next(true);
      const success = await this.carrierTouchpointService.updateCarrierTags([
        ...tags.map((tag) => tag.carrierTagId),
        event.option.value.id,
      ]);
      if (success) {
        tagInput.value = '';
        this.tagSearchControl.setValue('');
        this.snackbar.showMessage('Tags updated');
      }
    } finally {
      this.networkActive$$.next(false);
    }
  }

  public async removeTag(tagToRemove: CompanyCarrierTag, tags: CompanyCarrierTag[]) {
    if (this.isActive) {
      return;
    }
    try {
      this.networkActive$$.next(true);
      const success = await this.carrierTouchpointService.updateCarrierTags(
        tags.filter((tag) => tag.carrierTagId !== tagToRemove.carrierTagId).map((tag) => tag.carrierTagId),
      );
      if (success) {
        this.tagSearchControl.setValue('');
        this.snackbar.showMessage('Tags updated');
      }
    } finally {
      this.networkActive$$.next(false);
    }
  }

  public async updateAssets() {
    this.assetsForm.markAllAsTouched();
    if (this.assetsForm.valid) {
      if (this.isActive) {
        return;
      }
      try {
        this.networkActive$$.next(true);
        const success = await this.carrierListService.updateCarrierAssets(
          this.carrierControl.value.id,
          this.assetsForm.value as CarrierAssets,
        );
        if (success) {
          this.snackbar.showMessage('Assets updated');
        }
      } finally {
        this.networkActive$$.next(false);
      }
    }
  }

  public onEnterCarrierSelect() {
    if (this.carrierControl?.value?.id) {
      this.carrierSelected = true;
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stopsRemaining',
  standalone: false,
})
export class StopsRemainingPipe implements PipeTransform {
  public transform<T extends { completedAt?: string }>(value: T[]): number {
    return value.filter((value1) => !value1.completedAt).length;
  }
}

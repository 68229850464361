<div mat-dialog-title>Request New Charge</div>
<div mat-dialog-content>
  <form [formGroup]="tourChargeAddForm" (ngSubmit)="addCharge()">
    <mat-form-field class="w-full">
      <mat-label>Charge Type</mat-label>
      <mat-select formControlName="allowedChargeTypeId">
        @for (ct of chargeTypes$ | async; track ct.id) {
        <mat-option [value]="ct.id">{{ ct.displayName }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Price</mat-label>
      <input matInput tdCurrencyInput formControlName="requestedCents" autocomplete="off" />
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Notes (include dates, etc if they will be helpful validating the charge)</mat-label>
      <input matInput formControlName="carrierNotes" autocomplete="off" />
    </mat-form-field>
    @if (requiresChassis$ | async) {
    <div>
      <div>Please confirm the chassis number</div>
      <mat-form-field class="w-full">
        <mat-label>Chassis Number</mat-label>
        <input matInput formControlName="chassisNumber" autocomplete="off" />
      </mat-form-field>
    </div>
    } @if (networkActive$ | async) {
    <div class="py-1">
      <mat-progress-bar mode="indeterminate" class="w-full"></mat-progress-bar>
    </div>
    } @else {
    <div class="flex justify-between">
      <button mat-button mat-dialog-close type="button">Nevermind</button>
      <button mat-stroked-button color="primary" type="submit">Add Charge</button>
    </div>
    }
  </form>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { isFuture, isPast } from 'date-fns';
import { stringOrDateToDate } from '../utilities/dateTime';

@Pipe({
  name: 'isFuture',
  standalone: false,
})
export class IsFuturePipe implements PipeTransform {
  public transform(value: string | Date): boolean {
    if (!value) {
      return false;
    }
    return isFuture(stringOrDateToDate(value));
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { isDate } from 'date-fns';
import { preciseDiffToHuman } from '../utilities/dateTime';

@Pipe({
  name: 'ago',
  standalone: false,
})
export class AgoPipe implements PipeTransform {
  public transform(value: Date | string | number, maxParts = 1, suffix = 'ago', useShort = false): string {
    if (!value) {
      return '—';
    }
    let asDate = value;
    if (!isDate(value)) {
      asDate = new Date(value);
    }
    return preciseDiffToHuman(new Date(), asDate as Date, maxParts, suffix, useShort);
  }
}

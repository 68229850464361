import { Pipe, PipeTransform } from '@angular/core';
import { City, ConstantsService } from '../services/constants.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'city',
  standalone: false,
})
export class CityPipe implements PipeTransform {
  private citiesMap$: Observable<Record<number, City>>;

  constructor(private constantsService: ConstantsService) {
    this.citiesMap$ = this.constantsService.allCitiesMap$;
  }

  public transform(value: number): Observable<string> {
    return this.citiesMap$.pipe(
      map((cm) => {
        return cm[value]?.name || '';
      }),
    );
  }
}

import { ChangeDetectionStrategy, Component, inject, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { FileUploadType, LohiLoad, ProductLoad, ProductLoadUpload } from '../../global-types';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { acceptableFileTypes, shareReplayComponentConfig } from '../../constants';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LightboxComponent } from '../lightbox/lightbox.component';
import {
  LohiLoadAllowedChargeType,
  TourBillingDetails,
  TourLoadCharge,
  TourLoadLineItemDisputes,
  TourLoadPendingCharge,
  VortoTakeRate,
} from '../../../modules/carrier/carrier-loads/carrier-loads.service';
import { SnackBarService } from '../../services/snackbar.service';
import { isFuture } from 'date-fns';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { NetworkableDestroyableComponent } from '../networkable-destroyable.component';
import { LoadBillingService } from '../../services/load-billing.service';
import { ConfirmationModalService } from '../confirmation-modal/confirmation-modal.service';
import { AuthService } from '../../services/auth.service';
import { ConfirmApproveForInvoicingComponent } from './confirm-approve-for-invoicing/confirm-approve-for-invoicing.component';
import { LoadBillingRequestChargeComponent } from './load-billing-request-charge/load-billing-request-charge.component';
import { LoadBillingPromptDeleteUploadComponent } from './load-billing-prompt-delete-upload/load-billing-prompt-delete-upload.component';
import { LoadBillingChargeDisputeComponent } from './load-billing-charge-dispute/load-billing-charge-dispute.component';
import { FeatureFlagService } from '../../services/feature-flag.service';

const actualQuantityCache: Map<ProductLoad, number | undefined> = new Map();

interface EditFormValueBase {
  truckId: number;
  trailerId: number;
  bolNumber: string;
  ticketNumber?: string;
  returnTrailer?: string;
}

interface EditFormNotBox extends EditFormValueBase {
  actualLoadWeight: number;
}

interface EditFormBox extends EditFormValueBase {
  assetId: number;
  assetName: string;
  assetActualLoadWeight: number;
}
@Component({
  selector: 'td-load-billing',
  templateUrl: './load-billing.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LoadBillingComponent extends NetworkableDestroyableComponent implements OnDestroy {
  @Input() public lohiLoad$: Observable<LohiLoad>;
  @Input() public canApprove = true;
  @ViewChild('uploadFile') private uploadFileModal: TemplateRef<any>;
  @ViewChild('editLoadDetails') private editFormModal: TemplateRef<any>;
  @ViewChild('addTourChargeModal') private addTourChargeModal: TemplateRef<any>;
  public tourChargeAddForm: UntypedFormGroup;
  public chargeTypes$: Observable<LohiLoadAllowedChargeType[]>; // TODO: remove this one the feature flag "chargesUpdatesAreRequests" is active
  private editRef: MatDialogRef<TemplateRef<any>>;
  private isAwaitingEditDialogForm = false;
  public tourChargeRef: MatDialogRef<any>;
  public canEditCharges$: Observable<boolean>;
  private filesToUpload$$ = new BehaviorSubject<File[]>([]);
  public filesToUpload$: Observable<string[]> = this.filesToUpload$$.pipe(map((v) => v.map((f) => f.name)));

  private imageType$$ = new BehaviorSubject<{
    type: FileUploadType;
    name: string;
  }>(null);
  public imageType$: Observable<{
    type: FileUploadType;
    name: string;
  }> = this.imageType$$.pipe(shareReplay(shareReplayComponentConfig));

  public tourLoadCharges$: Observable<TourLoadCharge[]> = this.lbs.tourLoadCharges$;
  protected tourLoadChargesForDispute$$ = new BehaviorSubject<TourLoadCharge[]>([]);
  public tourLoadPendingCharges$: Observable<TourLoadPendingCharge[]> = this.lbs.tourLoadPendingCharges$;
  public chargeAndPendingChargeTotal$: Observable<number> = combineLatest([
    this.tourLoadCharges$,
    this.tourLoadPendingCharges$,
  ]).pipe(
    map(([charges, pendingCharges]) => {
      return (
        (charges || [])
          .map(
            (c) =>
              c.pendingUpdate?.requestedCarrierQuantity * c.pendingUpdate?.requestedPerUnitCentsCarrier || c.totalCents,
          )
          .reduce((a, b) => a + b, 0) +
        (pendingCharges || [])
          .filter((c) => c.status === null)
          .map((c) => c.carrierTotalCents)
          .reduce((a, b) => a + b, 0)
      );
    }),
  );
  public hasPendingCharges$: Observable<boolean> = this.tourLoadPendingCharges$.pipe(
    map((pendingCharges) => (pendingCharges || []).filter((pc) => !pc.status)),
    map((pendingCharges) => pendingCharges?.length > 0),
  );
  public hasPendingChargeUpdates$: Observable<boolean> = this.tourLoadCharges$.pipe(
    map((charges) => (charges || []).filter((c) => c.pendingUpdate?.status === null)),
    map((charges) => charges?.length > 0),
  );
  public hasPendingChargeChanges$ = combineLatest([this.hasPendingCharges$, this.hasPendingChargeUpdates$]).pipe(
    map(([hasPendingCharges, hasPendingChargeUpdates]) => hasPendingCharges || hasPendingChargeUpdates),
  );
  public tourBillingDetails$: Observable<TourBillingDetails>;
  public tourBillingCanUndoApproval$: Observable<boolean>;
  public needsUploads$: Observable<boolean>;
  public productLoad$: Observable<ProductLoad>;
  public bolUploads$: Observable<ProductLoadUpload[]>;
  public ticketUploads$: Observable<ProductLoadUpload[]>;
  public otherUploads$: Observable<ProductLoadUpload[]>;
  public editDetailsForm: UntypedFormGroup;

  public rateconToken$ = this.authService.carrierToken$;
  public vortoTakeRate$: Observable<VortoTakeRate>;

  constructor(
    private lbs: LoadBillingService,
    private matDialog: MatDialog,
    private snackbar: SnackBarService,
    private fb: UntypedFormBuilder,
    private confirmService: ConfirmationModalService,
    private authService: AuthService,
  ) {
    super();
    this.vortoTakeRate$ = this.lbs.vortoTakeRate$;
    this.chargeTypes$ = this.lbs.chargeTypes$;
    this.productLoad$ = this.lbs.productLoad$;
    this.tourBillingDetails$ = this.lbs.tourLoadBillingDetails$.pipe(filter((v) => !!v));
    this.bolUploads$ = this.productLoad$.pipe(
      map((value) => value?.uploads?.filter((value1) => value1.category === 'bol_image')),
    );
    this.ticketUploads$ = this.productLoad$.pipe(
      map((value) => value?.uploads?.filter((value1) => value1.category === 'ticket_image')),
    );
    this.otherUploads$ = this.productLoad$.pipe(
      map((value) => value?.uploads?.filter((value1) => value1.category === 'other_image')),
    );
    this.tourChargeAddForm = new UntypedFormGroup({
      chargeType: new UntypedFormControl(null, [Validators.required, Validators.minLength(4)]),
      price: new UntypedFormControl(0, [Validators.required, Validators.min(0)]),
    });
    this.tourBillingCanUndoApproval$ = this.tourBillingDetails$.pipe(
      map((details) => {
        return details && details.canUndo && isFuture(new Date(details.undoExpiresAt));
      }),
    );
    this.setupMissingCharges();
    this.setupCanEditCharges();
  }

  public async approveLoad(loadId: string) {
    const takeRate = await lastValueFrom(this.vortoTakeRate$.pipe(take(1)));
    const confirmed = await lastValueFrom(
      this.matDialog
        .open(ConfirmApproveForInvoicingComponent, {
          data: {
            loadId,
            payoutCents: takeRate.vortoTakeCents,
          },
        })
        .afterClosed(),
    );
    if (!confirmed) {
      return;
    }
    this.networkActive$$.next(true);
    await this.lbs.approveTourLoad(loadId);
    this.networkActive$$.next(false);
  }

  public async unapproveTourLoad() {
    this.networkActive$$.next(true);
    const lohiLoad = await lastValueFrom(this.lohiLoad$.pipe(take(1)));
    await this.lbs.unapproveTourLoad(lohiLoad.id);
    this.networkActive$$.next(false);
  }

  public async submitFile() {
    for (const file of this.filesToUpload$$.value) {
      if (!file.type.startsWith('image/') && !acceptableFileTypes.includes(file.type)) {
        this.snackbar.showError('File type not allowed');
        return;
      }
      const lohiLoad = await lastValueFrom(this.lohiLoad$.pipe(take(1)));
      const loadFile = await this.lbs.uploadFile(lohiLoad.id, file, this.imageType$$.value.type);
      if (!loadFile) {
        return;
      }
    }
    this.matDialog.closeAll();
    this.filesToUpload$$.next([]);
  }

  private setupMissingCharges() {
    this.needsUploads$ = this.tourLoadCharges$.pipe(
      map((charges) => {
        const customCharges = charges.filter((c) => c.chargeType === 'custom');
        let requiresUpload = false;
        for (const charge of customCharges) {
          if (charge.requiresUpload && !charge.uploads?.length) {
            requiresUpload = true;
            break;
          }
        }
        return requiresUpload;
      }),
      shareReplay(shareReplayComponentConfig),
    );
  }

  public async disputeCharges() {
    const load = await firstValueFrom(this.lohiLoad$);
    this.matDialog.open(LoadBillingChargeDisputeComponent, {
      data: load,
    });
  }

  private setupCanEditCharges() {
    this.canEditCharges$ = this.tourBillingDetails$.pipe(
      map((details) => {
        return !details.adminApprovedAt && !details.approvedAt;
      }),
      shareReplay(shareReplayComponentConfig),
    );
  }

  public async setNextFile(files: NgxFileDropEntry[]) {
    const uploadedFiles = [];
    for (const file of files) {
      if (file.fileEntry.isFile) {
        const fileEntry = file.fileEntry as FileSystemFileEntry;
        fileEntry.file((value) => {
          uploadedFiles.push(value);
        });
      }
    }
    this.filesToUpload$$.next(uploadedFiles);
  }

  public async startEditingDetails() {
    this.editDetailsForm = null;
    if (this.editRef || this.isAwaitingEditDialogForm) {
      this.editRef?.close();
      this.editRef = null;
    }

    this.isAwaitingEditDialogForm = true;
    const editableLoadDetails = await lastValueFrom(this.lbs.getEditableLoadInfo$());
    const formData: any = {
      truckId: [editableLoadDetails.truckAsset?.truckId, editableLoadDetails.truckEnabled ? [Validators.required] : []],
      trailerId: [
        editableLoadDetails.trailerAsset?.trailerId,
        editableLoadDetails.trailerEnabled ? [Validators.required] : [],
      ],
      bolNumber: [editableLoadDetails.bolNumber, [Validators.required]],
      actualLoadWeight: [editableLoadDetails.actualQuantity, [Validators.required]],
      ticketNumber: [editableLoadDetails.ticketNumber],
      returnTrailer: [editableLoadDetails.returnTrailer],
      truckEnabled: [editableLoadDetails.truckEnabled],
      trailerEnabled: [editableLoadDetails.trailerEnabled],
      ticketNumberEnabled: [editableLoadDetails.ticketNumberEnabled],
      returnTrailerEnabled: [editableLoadDetails.returnTrailerEnabled],
      abbreviation: [editableLoadDetails.unit.abbreviation],
    };
    if (editableLoadDetails.assets?.length) {
      delete formData.actualLoadWeight;
      formData.assetId = [editableLoadDetails.assets[0].id, [Validators.required]];
      formData.assetName = [editableLoadDetails.assets[0].name, [Validators.required]];
      formData.assetActualLoadWeight = [editableLoadDetails.assets[0].actualLoadWeight, [Validators.required]];
    }
    this.editDetailsForm = this.fb.group(formData);
    this.editRef = this.matDialog.open(this.editFormModal, {
      panelClass: ['w-full', 'md:w-1/2', 'lg:w-1/3'],
    });
    this.isAwaitingEditDialogForm = false;
  }

  public getLoadQuantity(currentLoad: ProductLoad): number | undefined {
    if (actualQuantityCache.has(currentLoad)) {
      return actualQuantityCache.get(currentLoad);
    }
    let actualQuantity: number;
    for (const stop of currentLoad.stops || []) {
      for (const task of stop.tasks) {
        if (task.actualQuantity) {
          actualQuantity = task.actualQuantity;
          break;
        }
      }
      if (actualQuantity) {
        break;
      }
    }
    actualQuantityCache.set(currentLoad, actualQuantity);

    return actualQuantity;
  }

  public async promptDeleteUpload(upload: ProductLoadUpload) {
    const load = await lastValueFrom(this.lohiLoad$.pipe(take(1)));
    this.matDialog.open(LoadBillingPromptDeleteUploadComponent, {
      data: {
        loadId: load.id,
        upload,
      },
    });
  }

  public async startAddCharge() {
    if (this.tourChargeRef) {
      this.tourChargeRef.close();
    }
    this.tourChargeRef = this.matDialog.open(this.addTourChargeModal, {
      panelClass: ['w-full', 'lg:w-1/3'],
    });
  }

  public async startAddChargeRequest() {
    const load = await lastValueFrom(this.lohiLoad$.pipe(take(1)));
    if (this.tourChargeRef) {
      this.tourChargeRef.close();
    }
    this.tourChargeRef = this.matDialog.open(LoadBillingRequestChargeComponent, {
      data: {
        load,
      },
      panelClass: ['w-full', 'lg:w-1/3'],
    });
  }

  public async promptUploadFile(chargeType: FileUploadType, chargeName: string) {
    this.imageType$$.next({ type: chargeType, name: chargeName });
    this.matDialog.open(this.uploadFileModal, {
      panelClass: ['w-full', 'lg:w-1/3'],
      closeOnNavigation: true,
    });
  }

  public override ngOnDestroy() {
    super.ngOnDestroy();
    this.matDialog.closeAll();
  }

  // TODO: remove this once the feature flag "chargesUpdatesAreRequests" is active
  public async addCharge() {
    if (!this.tourChargeAddForm || this.networkActive$$.value) {
      return;
    }
    try {
      this.networkActive$$.next(true);
      const load = await lastValueFrom(this.lohiLoad$.pipe(take(1)));
      const val: { chargeType: LohiLoadAllowedChargeType; price: number } = this.tourChargeAddForm.value;
      const success = await this.lbs.addTourCharge(load.id, val.chargeType, val.price);
      if (success) {
        this.tourChargeRef.close();
        this.tourChargeRef = null;
        this.tourChargeAddForm.reset();
      }
    } finally {
      this.networkActive$$.next(false);
    }
  }
}

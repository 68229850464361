"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseTimeUnits = exports.TIME_UNITS_PATTERN = exports.parseYear = exports.YEAR_PATTERN = exports.parseNumberPattern = exports.NUMBER_PATTERN = exports.TIME_UNIT_DICTIONARY = exports.INTEGER_WORD_DICTIONARY = exports.MONTH_DICTIONARY = exports.WEEKDAY_DICTIONARY = void 0;
const pattern_1 = require("../../utils/pattern");
const years_1 = require("../../calculation/years");
exports.WEEKDAY_DICTIONARY = {
  "sonntag": 0,
  "so": 0,
  "montag": 1,
  "mo": 1,
  "dienstag": 2,
  "di": 2,
  "mittwoch": 3,
  "mi": 3,
  "donnerstag": 4,
  "do": 4,
  "freitag": 5,
  "fr": 5,
  "samstag": 6,
  "sa": 6
};
exports.MONTH_DICTIONARY = {
  "januar": 1,
  "jänner": 1,
  "janner": 1,
  "jan": 1,
  "jan.": 1,
  "februar": 2,
  "feber": 2,
  "feb": 2,
  "feb.": 2,
  "märz": 3,
  "maerz": 3,
  "mär": 3,
  "mär.": 3,
  "mrz": 3,
  "mrz.": 3,
  "april": 4,
  "apr": 4,
  "apr.": 4,
  "mai": 5,
  "juni": 6,
  "jun": 6,
  "jun.": 6,
  "juli": 7,
  "jul": 7,
  "jul.": 7,
  "august": 8,
  "aug": 8,
  "aug.": 8,
  "september": 9,
  "sep": 9,
  "sep.": 9,
  "sept": 9,
  "sept.": 9,
  "oktober": 10,
  "okt": 10,
  "okt.": 10,
  "november": 11,
  "nov": 11,
  "nov.": 11,
  "dezember": 12,
  "dez": 12,
  "dez.": 12
};
exports.INTEGER_WORD_DICTIONARY = {
  "eins": 1,
  "eine": 1,
  "einem": 1,
  "einen": 1,
  "einer": 1,
  "zwei": 2,
  "drei": 3,
  "vier": 4,
  "fünf": 5,
  "fuenf": 5,
  "sechs": 6,
  "sieben": 7,
  "acht": 8,
  "neun": 9,
  "zehn": 10,
  "elf": 11,
  "zwölf": 12,
  "zwoelf": 12
};
exports.TIME_UNIT_DICTIONARY = {
  sek: "second",
  sekunde: "second",
  sekunden: "second",
  min: "minute",
  minute: "minute",
  minuten: "minute",
  h: "hour",
  std: "hour",
  stunde: "hour",
  stunden: "hour",
  tag: "d",
  tage: "d",
  tagen: "d",
  woche: "week",
  wochen: "week",
  monat: "month",
  monate: "month",
  monaten: "month",
  monats: "month",
  quartal: "quarter",
  quartals: "quarter",
  quartale: "quarter",
  quartalen: "quarter",
  a: "year",
  j: "year",
  jr: "year",
  jahr: "year",
  jahre: "year",
  jahren: "year",
  jahres: "year"
};
exports.NUMBER_PATTERN = `(?:${pattern_1.matchAnyPattern(exports.INTEGER_WORD_DICTIONARY)}|[0-9]+|[0-9]+\\.[0-9]+|half(?:\\s*an?)?|an?\\b(?:\\s*few)?|few|several|a?\\s*couple\\s*(?:of)?)`;
function parseNumberPattern(match) {
  const num = match.toLowerCase();
  if (exports.INTEGER_WORD_DICTIONARY[num] !== undefined) {
    return exports.INTEGER_WORD_DICTIONARY[num];
  } else if (num === "a" || num === "an") {
    return 1;
  } else if (num.match(/few/)) {
    return 3;
  } else if (num.match(/half/)) {
    return 0.5;
  } else if (num.match(/couple/)) {
    return 2;
  } else if (num.match(/several/)) {
    return 7;
  }
  return parseFloat(num);
}
exports.parseNumberPattern = parseNumberPattern;
exports.YEAR_PATTERN = `(?:[0-9]{1,4}(?:\\s*[vn]\\.?\\s*(?:C(?:hr)?|(?:u\\.?|d\\.?(?:\\s*g\\.?)?)?\\s*Z)\\.?|\\s*(?:u\\.?|d\\.?(?:\\s*g\\.)?)\\s*Z\\.?)?)`;
function parseYear(match) {
  if (/v/i.test(match)) {
    return -parseInt(match.replace(/[^0-9]+/gi, ""));
  }
  if (/n/i.test(match)) {
    return parseInt(match.replace(/[^0-9]+/gi, ""));
  }
  if (/z/i.test(match)) {
    return parseInt(match.replace(/[^0-9]+/gi, ""));
  }
  const rawYearNumber = parseInt(match);
  return years_1.findMostLikelyADYear(rawYearNumber);
}
exports.parseYear = parseYear;
const SINGLE_TIME_UNIT_PATTERN = `(${exports.NUMBER_PATTERN})\\s{0,5}(${pattern_1.matchAnyPattern(exports.TIME_UNIT_DICTIONARY)})\\s{0,5}`;
const SINGLE_TIME_UNIT_REGEX = new RegExp(SINGLE_TIME_UNIT_PATTERN, "i");
exports.TIME_UNITS_PATTERN = pattern_1.repeatedTimeunitPattern("", SINGLE_TIME_UNIT_PATTERN);
function parseTimeUnits(timeunitText) {
  const fragments = {};
  let remainingText = timeunitText;
  let match = SINGLE_TIME_UNIT_REGEX.exec(remainingText);
  while (match) {
    collectDateTimeFragment(fragments, match);
    remainingText = remainingText.substring(match[0].length);
    match = SINGLE_TIME_UNIT_REGEX.exec(remainingText);
  }
  return fragments;
}
exports.parseTimeUnits = parseTimeUnits;
function collectDateTimeFragment(fragments, match) {
  const num = parseNumberPattern(match[1]);
  const unit = exports.TIME_UNIT_DICTIONARY[match[2].toLowerCase()];
  fragments[unit] = num;
}

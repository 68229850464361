import { Pipe, PipeTransform } from '@angular/core';
import { TierType } from '../../modules/internal/dashboard/interfaces';

const tierTypeToTextColor = {
  platinum: 'text-white',
  black: 'text-white',
  gold: 'text-black',
  silver: 'text-white',
  probation: 'text-white',
};

@Pipe({
  name: 'tierTypeTextColor',
  standalone: false,
})
export class TierTypeTextColorPipe implements PipeTransform {
  public transform(value: TierType): string {
    if (!value) {
      return '';
    }

    return tierTypeToTextColor[(value as string).toLowerCase()];
  }
}

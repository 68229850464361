import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, combineLatestWith, filter, lastValueFrom, Observable, of, take, takeUntil } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { FusedObservable } from '../../../shared/utilities/fusedObservable';
import { AuthService, hasDispatcherPermissions, LoHiUserInfo } from '../../../shared/services/auth.service';
import { CarrierTouchpointComponent } from '../carrier-touchpoint/carrier-touchpoint.component';
import { CompanySwitcherSharedComponent } from '../company-switcher-shared/company-switcher-shared.component';
import { NetworkableDestroyableComponent } from '../networkable-destroyable.component';
import { DashboardService, Driver } from '../../../modules/internal/dashboard/dashboard.service';
import { HistoryEntry, HistoryService } from '../../services/history.service';
import { Router } from '@angular/router';
import { shareReplayComponentConfig } from '../../constants';
import { SearchService, SearchResult, TrailerSearchResult } from '../../services/search.service';
import { UpdateWorkPhoneComponent } from '../update-work-phone/update-work-phone.component';

@Component({
  selector: 'td-command-pallet',
  templateUrl: './command-pallet.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CommandPalletComponent extends NetworkableDestroyableComponent {
  @ViewChild('searchInput') public searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('driversLink') public driversSection: ElementRef<HTMLDivElement>;
  @ViewChild('loadsLink') public loadsSection: ElementRef<HTMLDivElement>;
  @ViewChild('trailersLink') public trailersSection: ElementRef<HTMLDivElement>;
  @ViewChild('commandsLink') public commandsSection: ElementRef<HTMLDivElement>;
  @ViewChildren('.result') public results: QueryList<ElementRef<HTMLElement>>;

  public searchBox = new FormControl<string>(null);
  private allCommands: Command[];
  public filteredCommands$: Observable<Command[]>;
  public loadSearchResults$: Observable<SearchResult[]>;
  private allDrivers$: Observable<Driver[]>;
  public filteredDrivers$: Observable<Driver[]>;
  public active$: Observable<boolean>;
  public filteredHistory$: Observable<HistoryEntry[]>;
  public allResults$: Observable<(Command | SearchResult | Driver | HistoryEntry | TrailerSearchResult)[]>;
  public firstResult$: Observable<Command | SearchResult | Driver | HistoryEntry | TrailerSearchResult>;

  private maxLoadsToShow$$ = new BehaviorSubject<number>(5);
  public maxLoadsToShow$ = this.maxLoadsToShow$$.pipe(shareReplay(shareReplayComponentConfig));
  public maxLoadSearchResults$: Observable<{
    results: SearchResult[];
    showMore: boolean;
  }>;
  private trailerSearchResults$: Observable<TrailerSearchResult[]>;
  private maxTrailersToShow$$ = new BehaviorSubject<number>(5);
  public maxTrailersToShow$ = this.maxLoadsToShow$$.pipe(shareReplay(shareReplayComponentConfig));
  public maxTrailersSearchResults$: Observable<{
    results: TrailerSearchResult[];
    showMore: boolean;
  }>;

  constructor(
    private authService: AuthService,
    private loadSearchService: SearchService,
    private matDialog: MatDialog,
    private dashboardService: DashboardService,
    private history: HistoryService,
    public ref: MatDialogRef<CommandPalletComponent>,
    private router: Router,
  ) {
    super();
    this.searchBox.valueChanges.pipe(takeUntil(this.destroy$$)).subscribe({
      next: (value) => this.loadSearchService.search(value),
    });
    this.setupCommands();
    this.loadSearchResults$ = this.loadSearchService.searchResults$;
    this.maxLoadSearchResults$ = this.loadSearchResults$.pipe(
      combineLatestWith(this.maxLoadsToShow$),
      map(([results, max]) => {
        return {
          results: results.slice(0, max),
          showMore: results.length > max,
        };
      }),
    );

    this.trailerSearchResults$ = this.loadSearchService.trailerSearchResults$;
    this.maxTrailersSearchResults$ = this.trailerSearchResults$.pipe(
      combineLatestWith(this.maxTrailersToShow$),
      map(([results, max]) => {
        return {
          results: results.slice(0, max),
          showMore: results.length > max,
        };
      }),
    );

    this.allDrivers$ = this.dashboardService.allDrivers$;
    this.filteredDrivers$ = new FusedObservable(
      this.allDrivers$,
      this.searchBox.valueChanges,
      ['userName', 'userPhone', 'userEmail', 'carrierName', 'carrierMcNumber', 'carrierDOTNumber'],
      false,
    ).fused$.pipe(
      map((v) => {
        if (this.searchBox.value) {
          return v.slice(0, 5);
        }
        return [];
      }),
    );

    this.ref.afterClosed().subscribe({
      next: () => {
        this.loadSearchService.search(null);
      },
    });
  }

  private async setupCommands() {
    const userInfo = await lastValueFrom(
      this.authService.userInfo$.pipe(
        filter((b) => !!b),
        take(1),
      ),
    );

    this.allCommands = [
      {
        displayName: 'Record Touchpoint',
        shortcut: 'Shift + T',
        key: 't',
        ctrlKey: false,
        shiftKey: true,
        activate: () => this.matDialog.open(CarrierTouchpointComponent),
        show: (userInfo: LoHiUserInfo) => true,
      },
      {
        displayName: 'Switch Company',
        shortcut: 'Ctrl + C',
        key: 'c',
        ctrlKey: true,
        shiftKey: false,
        activate: () => {
          this.matDialog.open(CompanySwitcherSharedComponent, {
            panelClass: ['w-full', 'lg:w-1/2'],
          });
        },
        show: (userInfo: LoHiUserInfo) => userInfo.permissions.includes('internal'),
      },
      {
        displayName: 'Update Work Phone',
        activate: () => {
          this.matDialog.open(UpdateWorkPhoneComponent, {
            autoFocus: false,
          });
        },
        show: (userInfo: LoHiUserInfo) => userInfo.permissions.includes('internal'),
      },
    ].filter((command) => command.show(userInfo));

    this.filteredCommands$ = new FusedObservable(
      of(this.allCommands),
      this.searchBox.valueChanges.pipe(startWith(this.searchBox.value)),
      ['displayName', 'shortcut'],
    ).fused$;

    this.filteredHistory$ = new FusedObservable(this.history.history$, this.searchBox.valueChanges, ['name']).fused$;

    this.active$ = this.loadSearchService.loading$;

    this.allResults$ = this.filteredCommands$.pipe(
      combineLatestWith(
        this.filteredDrivers$.pipe(startWith([])),
        this.loadSearchResults$.pipe(startWith([])),
        this.filteredHistory$.pipe(startWith([])),
        this.trailerSearchResults$.pipe(startWith([])),
      ),
      map(([commands, drivers, loads, history, trailers]) => {
        return [...loads, ...drivers, ...commands, ...history, ...trailers];
      }),
      shareReplay(shareReplayComponentConfig),
    );

    this.firstResult$ = this.allResults$.pipe(
      map((results) => results[0]),
      shareReplay(shareReplayComponentConfig),
    );
  }

  @HostListener('document:paste', ['$event'])
  public onPaste(event: ClipboardEvent) {
    if ((event.target as HTMLElement).tagName === 'INPUT') {
      return;
    }
    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();
    const text = event.clipboardData.getData('text');
    if (text) {
      this.searchInput?.nativeElement.focus();
      this.searchBox.setValue(text);
    }
  }

  @HostListener('document:keydown', ['$event'])
  public async onKeyDown(event: KeyboardEvent) {
    if (event.key.toLowerCase() === 'tab') {
      return;
    }

    if ((event.target as HTMLElement).tagName === 'INPUT') {
      if (event.key.toLowerCase() === 'enter') {
        this.handleEnter();
        return;
      }
      return;
    }

    if (event.key.toLowerCase() === 'enter') {
      if (this.searchBox.pristine) {
        this.handleEnter();
        return;
      }
      return;
    }

    // paste event
    if (event.ctrlKey && event.key === 'v') {
      return;
    }

    // arrow key events
    if (event.key.startsWith('Arrow')) {
      return;
    }

    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();

    for (const command of this.allCommands) {
      if (
        command.key?.toLowerCase() === event.key.toLowerCase() &&
        event.ctrlKey === command.ctrlKey &&
        event.shiftKey === command.shiftKey
      ) {
        this.ref.close();
        command.activate();
        return;
      }
    }

    if (event.key === 'Escape') {
      this.ref.close();
      return;
    }

    if (letters.includes(event.key.toLowerCase())) {
      this.searchInput.nativeElement.focus();
      this.searchBox.setValue(event.key);
    }
  }

  private async handleEnter() {
    const allResults = await lastValueFrom(this.allResults$.pipe(take(1)));
    if (allResults.length > 0) {
      const topResult = allResults[0];
      this.ref.close();
      if ((topResult as Command)?.activate) {
        (topResult as Command).activate();
        this.ref.close(topResult);
      } else if ((topResult as SearchResult)?.internalId) {
        this.router.navigate(['/loads', (topResult as SearchResult).internalId]);
        this.ref.close(topResult);
      } else if ((topResult as TrailerSearchResult)?.id) {
        this.router.navigate(['/drop_trailer/trailer', (topResult as TrailerSearchResult).id]);
        this.ref.close(topResult);
      } else if ((topResult as Driver)?.userId) {
        this.router.navigate(['/dashboard', 'driver', (topResult as Driver).userId]);
        this.ref.close(topResult);
      } else if ((topResult as HistoryEntry)?.url) {
        this.router.navigate((topResult as HistoryEntry).url);
        this.ref.close(topResult);
      }
    }
  }

  public scrollToDrivers() {
    this.driversSection?.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  public scrollToLoads() {
    this.loadsSection?.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  public scrollToTrailers() {
    this.trailersSection?.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  public scrollToCommands() {
    this.commandsSection?.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  public showMoreLoads() {
    this.maxLoadsToShow$$.next(this.maxLoadsToShow$$.value + 5);
  }

  public showMoreTrailers() {
    this.maxTrailersToShow$$.next(this.maxTrailersToShow$$.value + 5);
  }
}

const letters = 'abcdefghijklmnopqrstuvwxyz'.split('');

interface Command {
  displayName: string;
  shortcut?: string;
  key?: string;
  ctrlKey?: boolean;
  shiftKey?: boolean;
  activate: () => void;
  show: (LoHiUserInfo) => boolean;
}

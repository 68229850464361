import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Directive({
  selector: '[tdScrollIntoViewAnchor]',
  exportAs: 'tdScrollIntoViewAnchor',
  standalone: false,
})
export class ScrollIntoViewAnchorDirective implements AfterViewInit {
  public active = false;

  @Input() public tdScrollIntoViewAnchor: string = null;
  private height$$ = new BehaviorSubject<number>(null);

  public get height$(): Observable<number> {
    return this.height$$.asObservable();
  }

  constructor(private el: ElementRef<HTMLElement>, private router: Router) {
    if (!el) {
      throw new Error('This directive must be attached to an HTML Element');
    }
  }

  public ngAfterViewInit() {
    this.setupResizeObserver();
  }

  public scrollIntoView(instant = false): void {
    // @ts-ignore
    this.el?.nativeElement?.scrollIntoView({ behavior: instant ? 'instant' : 'smooth' });
  }

  public getBoundingClientRect(): DOMRect {
    return this.el?.nativeElement?.getBoundingClientRect();
  }

  public setActive(active: boolean): void {
    this.active = active;
    if (active && this.tdScrollIntoViewAnchor) {
      this.router.navigate([], { fragment: this.tdScrollIntoViewAnchor });
    }
  }

  private setupResizeObserver() {
    const ne = this.el.nativeElement;
    this.height$$.next(ne.offsetHeight);
    const ro = new ResizeObserver((entries) => {
      if (entries?.length) {
        this.height$$.next(entries[0].contentRect.height);
      }
    });
    ro.observe(ne);
  }
}

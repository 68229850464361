import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { AuthService, hasDispatcherPermissions, LoHiUserInfo } from '../services/auth.service';
import { filter, lastValueFrom, Observable, switchMap, take, timer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InternalService } from '../services/internal.service';
import { SidenavService, SidenavType } from '../services/sidenav.service';
import { NetworkableDestroyableComponent } from '../components/networkable-destroyable.component';
import { FeatureFlagService } from '../services/feature-flag.service';
import LogRocket from 'logrocket';
import { CommandPalletComponent } from '../components/command-pallet/command-pallet.component';
import { SearchService } from '../services/search.service';

@Component({
  selector: 'td-side-nav',
  templateUrl: './side-nav.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SideNavComponent extends NetworkableDestroyableComponent {
  public userInfo$: Observable<LoHiUserInfo>;
  public token$: Observable<string>;
  private ref: MatDialogRef<any>;
  public isInternal$: Observable<boolean>;
  public sidenavType$: Observable<SidenavType>;
  public loadFinderToken$: Observable<string>;
  public canRunVPF$: Observable<boolean>;

  constructor(
    private authService: AuthService,
    private internalService: InternalService,
    private loadSearchService: SearchService,
    private matDialog: MatDialog,
    private sidenavService: SidenavService,
    private featureFlagService: FeatureFlagService,
  ) {
    super();
    this.loadFinderToken$ = this.authService.carrierToken$;
    this.sidenavType$ = this.sidenavService.sidenavType$;
    this.userInfo$ = this.authService.userInfo$;
    this.token$ = timer(0, 600000).pipe(switchMap(() => this.authService.customToken$));
    this.isInternal$ = this.internalService.isInternalShared$;
    this.canRunVPF$ = this.featureFlagService.isFlagActive$('canRunVPF');
  }

  public logout() {
    this.authService.logout();
  }

  @HostListener('document:keydown.control.k', ['$event'])
  public commandPallet(event: KeyboardEvent) {
    this.handleCommandPallet(event);
  }

  @HostListener('document:keydown.meta.k', ['$event'])
  public macCommandPallet(event: KeyboardEvent) {
    this.handleCommandPallet(event);
  }

  private async handleCommandPallet(event: KeyboardEvent) {
    event.preventDefault();
    LogRocket.track('commandPalletShortcut');
    const isInternal = await lastValueFrom(this.isInternal$.pipe(take(1)));
    if (!isInternal) {
      return;
    }
    this.openCommandPallet();
  }

  public openCommandPallet() {
    if (this.ref?.componentInstance instanceof CommandPalletComponent) {
      return;
    }
    this.ref?.close();
    this.ref = this.matDialog.open(CommandPalletComponent, {
      autoFocus: false,
      panelClass: ['w-full', 'md:w-1/2', 'lg:w-1/3', 'no-padding-dialog'],
    });
  }

  @HostListener('document:keydown.control.shift.enter', ['$event'])
  public async driverSearch(event: KeyboardEvent) {
    this.handleCommandPallet(event);
  }

  @HostListener('document:keydown.control.enter', ['$event'])
  public async onKeyDown(event: KeyboardEvent) {
    this.handleCommandPallet(event);
  }

  public setSidenavType(type: SidenavType, $event: MouseEvent) {
    $event.stopPropagation();
    this.sidenavService.setSidenavType(type);
  }
}

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const constants_1 = require("../constants");
const results_1 = require("../../../results");
const AbstractParserWithWordBoundary_1 = require("../../../common/parsers/AbstractParserWithWordBoundary");
const timeunits_1 = require("../../../utils/timeunits");
const PATTERN = new RegExp(`(${constants_1.TIME_UNITS_PATTERN})\\s{0,5}назад(?=(?:\\W|$))`, constants_1.REGEX_PARTS.flags);
class RUTimeUnitAgoFormatParser extends AbstractParserWithWordBoundary_1.AbstractParserWithWordBoundaryChecking {
  patternLeftBoundary() {
    return constants_1.REGEX_PARTS.leftBoundary;
  }
  innerPattern() {
    return PATTERN;
  }
  innerExtract(context, match) {
    const timeUnits = constants_1.parseTimeUnits(match[1]);
    const outputTimeUnits = timeunits_1.reverseTimeUnits(timeUnits);
    return results_1.ParsingComponents.createRelativeFromReference(context.reference, outputTimeUnits);
  }
}
exports.default = RUTimeUnitAgoFormatParser;

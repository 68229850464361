<div class="flex flex-row items-center justify-between py-2">
  <button color="primary" mat-raised-button (click)="disputeDeadhead($event)">Dispute Deadhead Charges</button>
  @if (disputeResponse) {
  <div>
    <div class="flex flex-col text-lg text-orange-500 ml-1">
      @if (deadHeadPayout > 0) { Deadhead will be added to the payout. Deadhead payout is
      {{ deadHeadPayout | currency }}
      } @else { Deadhead will not be added to the payout. Because you either did not exceed the minimum deadhead miles
      or you did not use the application. }
      <ng-template #payout>
        Deadhead will not be added to the payout. Because you either did not exceed the minimum deadhead miles or you
        did not use the application.
      </ng-template>
    </div>
  </div>
  }
</div>

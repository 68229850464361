@if (!selectSearch) {
<mat-form-field class="w-full" subscriptSizing="dynamic">
  <mat-icon matPrefix>search</mat-icon>
  <mat-label>Search</mat-label>
  <input matInput autocomplete="off" [formControl]="searchControl" />
</mat-form-field>
} @if (selectSearch) {
<mat-option>
  <ngx-mat-select-search
    placeholderLabel="Search"
    noEntriesFoundLabel=""
    [formControl]="searchControl"
  ></ngx-mat-select-search>
</mat-option>
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { LohiLoad, LohiLoadStop } from '../../../../global-types';
import { LoadBillingService } from '../../../../services/load-billing.service';
import { MatSelectionList } from '@angular/material/list';
import { FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'td-load-billiing-detention-dispute',
  templateUrl: './load-billiing-detention-dispute.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LoadBilliingDetentionDisputeComponent {
  @Input() public load: LohiLoad;
  @Input() public oldRate: number;
  public responseGenerated: boolean = false;
  public generatedResponses: string[] = [];
  public stopsControl = new FormControl();

  constructor(private lbs: LoadBillingService, private cdr: ChangeDetectorRef, private fb: FormBuilder) {}
  public async disputeDetention(event: Event) {
    const selectedStops = this.stopsControl.value;
    const resp = await this.lbs.runDisputeDetention(this.load.id, this.getStopIDS(selectedStops), this.oldRate);
    const generatedResponse: string[] = [];
    if (resp['data'] === null) {
      this.generatedResponses.push('No stops qualify for detention and no extra charges were added to your load.');
      this.responseGenerated = true;
      this.cdr.detectChanges();
      return;
    }
    for (const stop of Object.values(resp['data']['stops'])) {
      if (stop['EligibleMinutes'] === 0) {
        continue;
      }
      const textResponse = `You were at ${stop['FacilityName']} facility for ${stop['TotalMinutes']} minutes.
        You have ${stop['EligibleMinutes']} of qualifying detention. $${
        stop['DetentionPay'] / 100
      } has been added to your load.`;
      generatedResponse.push(textResponse);
    }
    this.generatedResponses = generatedResponse;
    this.responseGenerated = true;
    this.cdr.detectChanges();
  }

  public getStopIDS(stops: LohiLoadStop[]): number[] {
    return stops.map((stop) => stop.id);
  }
}

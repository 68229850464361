import { Pipe, PipeTransform } from '@angular/core';
import { toReadableFileUploadType } from '../utilities/toReadableFileUploadType';

@Pipe({
  name: 'fileUploadType',
  standalone: false,
})
export class FileUploadTypePipe implements PipeTransform {
  public transform(value: string): string {
    return toReadableFileUploadType(value);
  }
}

import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MappableComponent, SATELLITE, STREETS } from '../mappable/mappable.component';
import * as mapboxgl from 'mapbox-gl';
import { Marker } from 'mapbox-gl';
import { LongLat, MappableStop, XYPoint } from '../../global-types';

@Component({
  selector: 'td-stop-map',
  templateUrl: './stop-map.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class StopMapComponent extends MappableComponent implements OnChanges, AfterViewInit {
  private driverMarker: mapboxgl.Marker;

  constructor() {
    super();
  }

  @Input() public stops: MappableStop[] = [];
  @Input() public driverLnglat: LongLat | XYPoint;
  @Input() public streetStyle = true;

  private mapInit = false;

  public override;

  private async addMarkers() {
    if (!this.stops) {
      return;
    }
    this.clearMarkers();
    for (const stop of this.stops) {
      const el = document.createElement('div');
      if (this.isSameLocationAsPreviousMarker(stop)) {
        el.className =
          'rounded-full text-white text-2xl text-center flex flex-row items-center justify-center border border-white ml-3';
        el.style.width = '30px';
        el.style.height = '30px';
        el.innerText = `${stop.sequence}`;
        el.style.backgroundColor = stop.color || '#448AFF';
      } else {
        el.className =
          'rounded-full text-white text-2xl text-center flex flex-row items-center justify-center border border-white';
        el.style.width = '30px';
        el.style.height = '30px';
        el.innerText = `${stop.sequence}`;
        el.style.backgroundColor = stop.color || '#448AFF';
      }
      const marker = new mapboxgl.Marker(el);
      marker.setLngLat([stop.lngLat.x, stop.lngLat.y]);
      if (stop.cityState) {
        marker.setPopup(new mapboxgl.Popup().setHTML(`<div class="text-black">${stop.cityState}</div>`));
      }
      this.addMarker(marker);
    }
    if (this.driverLnglat) {
      this.addDriverMarker(this.driverLnglat);
    }
    setTimeout(() => this.fitMarkers());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.mapInit) {
      return;
    }
    this.addMarkers();
  }

  public override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.mapInit = true;
    this.addMarkers();
    this.map.setStyle(this.streetStyle ? STREETS : SATELLITE);
  }

  private addDriverMarker(lnglat: LongLat | XYPoint) {
    if (!lnglat) {
      return;
    }
    const el = document.createElement('div');
    el.className =
      'rounded-full text-white text-2xl text-center flex flex-row items-center justify-center border border-white';
    el.style.width = '30px';
    el.style.height = '30px';
    el.innerHTML = `<span class="material-icons">local_shipping</span>`;
    el.style.backgroundColor = '#448AFF';
    this.driverMarker = new Marker(el);
    this.driverMarker.setLngLat([
      (lnglat as XYPoint)?.x || (lnglat as LongLat)?.longitude,
      (lnglat as XYPoint)?.y || (lnglat as LongLat)?.latitude,
    ]);
    this.addMarker(this.driverMarker);
  }

  private isSameLocationAsPreviousMarker(stop: MappableStop): boolean {
    for (let s of this.stops) {
      if (stop.lngLat.x === s.lngLat.x && stop.lngLat.y === s.lngLat.y && stop.sequence > s.sequence) {
        return true;
      }
    }
    return false;
  }

  public toggleStyle() {
    if (this.streetStyle) {
      this.map.setStyle(SATELLITE);
      this.streetStyle = false;
    } else {
      this.map.setStyle(STREETS);
      this.streetStyle = true;
    }
  }
}

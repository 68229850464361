import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { NetworkActiveComponent } from '../networkActive.component';
import { DashboardService, DriverComments, DriverDetail } from 'src/app/modules/internal/dashboard/dashboard.service';
import { AuthService } from '../../services/auth.service';
import { SnackBarService } from '../../services/snackbar.service';

@Component({
  selector: 'td-driver-comments-section',
  templateUrl: './driver-comments-section.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DriverCommentsSectionComponent extends NetworkActiveComponent {
  @Input() public onlyInput = false;
  public profile$: Observable<DriverDetail>;
  public comments$: Observable<DriverComments>;
  public commentForm: UntypedFormGroup;

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    private fb: UntypedFormBuilder,
    private snackbar: SnackBarService,
  ) {
    super();
    this.profile$ = this.dashboardService.driverProfile$;
    this.comments$ = this.dashboardService.driverComments$;

    this.commentForm = this.fb.group({
      comment: [null, [Validators.required]],
    });
  }

  public async submitComment() {
    if (this.commentForm.invalid) {
      return;
    }

    const val: { comment: string } = this.commentForm.value;

    const driver = await lastValueFrom(this.profile$.pipe(take(1)));

    const success = await this.dashboardService.addDriverComment(val.comment, driver.userId);

    if (success) {
      this.commentForm.reset();
      this.snackbar.showMessage('Comment Added');
    } else {
      this.snackbar.showError('Failed to Add Comment');
    }
  }
}

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
class OverlapRemovalRefiner {
  refine(context, results) {
    if (results.length < 2) {
      return results;
    }
    const filteredResults = [];
    let prevResult = results[0];
    for (let i = 1; i < results.length; i++) {
      const result = results[i];
      if (result.index < prevResult.index + prevResult.text.length) {
        if (result.text.length > prevResult.text.length) {
          prevResult = result;
        }
      } else {
        filteredResults.push(prevResult);
        prevResult = result;
      }
    }
    if (prevResult != null) {
      filteredResults.push(prevResult);
    }
    return filteredResults;
  }
}
exports.default = OverlapRemovalRefiner;

import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

// @ts-ignore
@Component({
  template: '',
  standalone: false,
})
export abstract class DestroyableComponent implements OnDestroy {
  protected destroy$$ = new Subject<void>();

  protected destroy() {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  public ngOnDestroy(): void {
    this.destroy();
  }
}

import { DatePipe, formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { add, isDate, isToday, isTomorrow } from 'date-fns';

@Pipe({
  name: 'standardDateOnly',
  standalone: false,
})
export class StandardDateOnlyPipe extends DatePipe implements PipeTransform {
  // @ts-ignore
  public transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
    if (!value) {
      return '—';
    }

    if (typeof value === 'object' && value !== null) {
      const { year, month, day } = value;
      if (year && month && day) {
        const tempDate = new Date(year, month - 1, day, 0, 0, 0, 0);
        value = add(tempDate, { minutes: tempDate.getTimezoneOffset() });
      }
    }
    return super.transform(value, format ?? standardDateFormat(value));
  }
}

export function standardDateFormat(value: any): string {
  const asDate = new Date(value);
  let formattingText = `M/dd/yyyy`;
  if (isToday(asDate)) {
    formattingText = `'Today'`;
  } else if (isTomorrow(asDate)) {
    formattingText = `'Tomorrow'`;
  }
  return formattingText;
}

export function formatDateOnlyStandard(value: any): string {
  if (!isDate(value)) {
    value = new Date(value);
  }
  const formatStr = standardDateFormat(value);
  return formatDate(value, formatStr, 'en-US');
}

<div class="overflow-auto h-full flex flex-col">
  <div class="font-bold mb-1 md:mb-3 mx-3">Carrier Portal</div>
  <div class="flex flex-col flex-1">
    @if (isCpgUfg$ | async) {
    <div class="card p-1 md:p-3 mx-3 first:mb-2 first:md:mb-3">
      <div
        class="w-full leading-loose flex items-center p-1 md:p-0 hover:text-blue-500 color-transition cursor-pointer"
        (click)="openCarrierRankings()"
      >
        Want to become a dedicated carrier?<span class="text-blue-500">&nbsp;Learn More</span>
      </div>
    </div>
    }
    <div class="card-with-shadow-white-no-radius p-1 md:p-3 mx-3">
      <div class="font-bold leading-loose text-lg p-1 md:p-0">Financial</div>
      <div class="flex flex-col">
        <ng-container *tdFeatureFlag="'newerCpgDashboard'; else olderDashboard">
          <a
            [routerLink]="['/carrier_kpis/performance']"
            class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
            >Profit & Driver Opportunities</a
          >
        </ng-container>
        <ng-template #olderDashboard>
          <a
            [routerLink]="['/carrier_kpis/cpg']"
            class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
            *tdFeatureFlag="'newCpgDashboard'; else oldDashboard"
            >Dashboard</a
          >
          <ng-template #oldDashboard>
            <a
              [routerLink]="[
                '/carrier_kpis/other',
                { outlets: { topkpi: 'per_driver_revenue', page: 'improvements' } }
              ]"
              class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
              >Dashboard</a
            >
          </ng-template>
        </ng-template>
        <a
          [routerLink]="(isCpg$ | async) ? ['/carrier_completed_loads/v2'] : ['/carrier_completed_loads']"
          class="w-full leading-loose flex items-center p-1 md:p-0 hover:text-blue-500 color-transition"
          >Payments @if (completedActionNeededCount$ | async; as count) {
          <td-number-badge [count]="count"></td-number-badge>
          }
        </a>
        <a [routerLink]="['/settlements']" class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          >Settlements</a
        >
        @if (isCpg$ | async) {
        <a
          [routerLink]="['/rates']"
          class="w-full leading-loose flex items-center p-1 md:p-0 hover:text-blue-500 color-transition"
        >
          Rates
        </a>
        }
      </div>
    </div>
    <div class="card-with-shadow-white-no-radius mt-2 md:mt-3 p-1 md:p-3 mx-3">
      <div class="font-bold leading-loose text-lg p-1 md:p-0">My Fleet</div>
      <div class="flex flex-col">
        @if (isCpg$ | async) {
        <a
          [routerLink]="['/carrier_hitches/dabs']"
          class="w-full leading-loose flex items-center p-1 md:p-0 hover:text-blue-500 color-transition"
        >
          Hitches
        </a>
        }
        <a
          [routerLink]="['/carrier_pending_loads']"
          class="w-full leading-loose flex items-center p-1 md:p-0 hover:text-blue-500 color-transition"
        >
          Pending Loads @if (pendingActionNeededCount$ | async; as count) {
          <td-number-badge [count]="count"></td-number-badge>
          }
        </a>
        @if (isCpgUfg$ | async) {
        <a
          [routerLink]="['/cpg_ufg']"
          class="w-full leading-loose flex items-center p-1 md:p-0 hover:text-blue-500 color-transition"
        >
          Spot Loads
        </a>
        }
        <a
          [routerLink]="['/carrier_loads/active_loads']"
          class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          >Active Loads</a
        >
        <a
          [routerLink]="['/carrier_assets']"
          class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          >Assets</a
        >
        <a
          [routerLink]="(isCpg$ | async) ? ['/carrier_users/v2'] : ['/carrier_users/table']"
          class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          >Drivers</a
        >
        @if (isImportExport$ | async) {
        <a
          [routerLink]="['/carrier_leaderboard']"
          class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          >Leaderboard</a
        >
        }
      </div>
    </div>
    <div class="flex-1"></div>
    <div class="flex items-center flex-col mt-1 mx-3">
      <a [routerLink]="['/carrier_settings']" class="w-full leading-loose p-1 md:p-0"> Settings </a>
    </div>
  </div>
</div>

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AbstractParserWithWordBoundaryChecking = void 0;
class AbstractParserWithWordBoundaryChecking {
  constructor() {
    this.cachedInnerPattern = null;
    this.cachedPattern = null;
  }
  patternLeftBoundary() {
    return `(\\W|^)`;
  }
  pattern(context) {
    const innerPattern = this.innerPattern(context);
    if (innerPattern == this.cachedInnerPattern) {
      return this.cachedPattern;
    }
    this.cachedPattern = new RegExp(`${this.patternLeftBoundary()}${innerPattern.source}`, innerPattern.flags);
    this.cachedInnerPattern = innerPattern;
    return this.cachedPattern;
  }
  extract(context, match) {
    var _a;
    const header = (_a = match[1]) !== null && _a !== void 0 ? _a : "";
    match.index = match.index + header.length;
    match[0] = match[0].substring(header.length);
    for (let i = 2; i < match.length; i++) {
      match[i - 1] = match[i];
    }
    return this.innerExtract(context, match);
  }
}
exports.AbstractParserWithWordBoundaryChecking = AbstractParserWithWordBoundaryChecking;

@if ((networkActiveCharge$ | async) || {}; as networkActiveCharge) { @for (charge of pendingCharges; track charge.id) {
<div [ngClass]="{ 'last:border-0': hideLastBorder }" class="border-b">
  @if (charge.status === 'denied' && charge.decisionSupportingText) {
  <div class="border border-red-400 rounded-lg p-3 mt-3">
    <div>
      Denied by <span class="font-bold">{{ charge.decisionBy }}</span>
    </div>
    <div class="italic">{{ charge.decisionSupportingText }}</div>
  </div>
  }
  <div class="flex flex-row items-center justify-between py-2 line" [class.line-through]="charge.status === 'denied'">
    <div class="flex items-center">
      <span class="font-semibold">{{ charge.chargeTypeName }}</span>
      @if (!charge.status) {
      <div class="ml-2 text-orange-600 font-semibold">Pending 5F Approval</div>
      }
      <div class="relative flex items-center">
        @if (canEditCharges === true && charge.status !== 'denied') {
        <td-double-confirm
          buttonType="flat"
          buttonColor="warn"
          confirmText="Confirm"
          (confirm)="deletePendingCharge(charge)"
          class="ml-2 absolute"
          style="transform: scale(0.75)"
        >
          <button mat-mini-fab color="warn">
            <mat-icon class="text-white">delete_outline</mat-icon>
          </button>
        </td-double-confirm>
        }
      </div>
    </div>
    <div class="text-right">{{ charge.carrierTotalCents | currencyInPennies }}</div>
  </div>
  @if (networkActiveCharge[charge.id]) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  } @else {
  <div class="h-[4px]"></div>
  } @if (charge.chargeType === 'custom') {
  <div
    class="flex-1 flex flex-col border rounded-lg border-gray-400 dark:border-gray-700 p-3 bg-gray-200 dark:bg-gray-900 m-1 ml-2 lg:mt-1 lg:m-3 lg:ml-6"
  >
    <div class="flex justify-between">
      <div class="font-semibold text-lg">
        Uploads @if (charge.requiresUpload && !charge.uploads?.length && charge.status !== 'denied') {
        <span class="flex text-red-500">(Required)</span>
        }
      </div>
      @if (charge.status !== 'denied') {
      <div class="flex items-center px-2">
        @if (canEditCharges === true) {
        <label class="text-teal-500 p-1 w-full" matTooltip="Upload Image for Charge">
          <input class="hidden" (change)="onTourChargeEditFileChange(charge, $event)" type="file" />
          <div class="bg-blue-500 text-white rounded-full w-3 h-3 flex items-center justify-center">
            <mat-icon>add</mat-icon>
          </div>
        </label>
        }
      </div>
      }
    </div>
    <div class="flex flex-row flex-wrap">
      @for (upload of charge.uploads; track upload.id) {
      <div class="mr-4 relative max-w-1/5 lg:max-w-1/12">
        @if (canEditCharges === true && charge.status !== 'denied') {
        <td-double-confirm
          buttonType="flat"
          buttonColor="warn"
          confirmText="Confirm"
          (confirm)="deleteCustomChargeFile(charge, upload.id)"
          style="position: absolute; transform: scale(0.75); right: -20px"
        >
          <button mat-mini-fab color="warn">
            <mat-icon class="text-white">delete_outline</mat-icon>
          </button>
        </td-double-confirm>
        }
        <div class="whitespace-normal break-words">
          <a [href]="upload.url" target="_blank">
            {{ upload.fileName }}
          </a>
        </div>
      </div>
      }
    </div>
  </div>
  }
</div>
} }

@if ((networkActiveCharge$ | async) || {}; as networkActiveCharge) { @for (charge of charges; track charge.id) {
<div class="border-b">
  <div class="flex flex-row items-center justify-between py-2">
    <div class="flex items-center">
      @if (charge.chargeType !== 'custom') {
      <mat-icon inline>lock</mat-icon>
      }
      <span class="font-semibold">{{ charge.chargeTypeNameIdName || charge.name }}</span>
      @if (charge.pendingUpdate && charge.pendingUpdate.status === null) {
      <span class="ml-2 text-orange-600 font-semibold">Update Pending 5F Approval</span>
      }
      <ng-container *tdFeatureFlag="'chargesUpdatesAreRequests'; else directlyEditCharge">
        @if (charge.chargeType === 'custom' && canEditCharges === true) {
        <button mat-icon-button class="ml-2" (click)="startRequestUpdateCustomCharge(charge)">
          <mat-icon> edit</mat-icon>
        </button>
        }
      </ng-container>
      <ng-template #directlyEditCharge>
        @if (charge.chargeType === 'custom' && canEditCharges === true) {
        <button mat-icon-button class="ml-2" (click)="startEditCustomCharge(charge)">
          <mat-icon> edit</mat-icon>
        </button>
        }
      </ng-template>
    </div>
    <div class="flex flex-col" style="min-width: fit-content">
      <div style="text-align: end" [class.text-red-500]="charge.totalCents < 0">
        {{ charge.totalCents | currencyInPennies }}
      </div>
      @if (charge.chargeType === 'line_haul' && productLoad?.hasTonuCharge === true && canEditCharges === true) {
      <div class="cursor-pointer font-semibold text-blue-400 py-2" (click)="startDeleteLinehaulCharge()">
        Truck Ordered and Not Used - Delete Linehaul Charge
      </div>
      }
    </div>
  </div>
  @if (charge.pendingUpdate; as pendingUpdate) {
  <div>
    @if (!pendingUpdate.status) {
    <div>
      <span class="font-bold">{{ pendingUpdate.createdByName }}</span> requested to update the charge to
      <span class="font-bold">{{ pendingUpdate.requestedPerUnitCentsCarrier | currencyInPennies }}</span>
    </div>
    } @else { @if (pendingUpdate.status === 'denied' && pendingUpdate.decisionSupportingText) {
    <div class="border border-red-400 rounded-lg p-3 mt-3">
      <span class="font-bold">{{ pendingUpdate.createdByName }}'s</span> request to update the charge to
      <span class="font-bold">{{ pendingUpdate.requestedPerUnitCentsCarrier | currencyInPennies }}</span>
      was denied by <span class="font-bold">{{ pendingUpdate.decisionMadeByName }}</span>
      <div class="italic">{{ pendingUpdate.decisionSupportingText }}</div>
    </div>
    } }
  </div>
  } @if (networkActiveCharge[charge.id]) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  } @else {
  <div class="h-[4px]"></div>
  } @if (charge.chargeType === 'custom') {
  <div
    class="flex-1 flex flex-col border rounded-lg border-gray-400 dark:border-gray-700 p-3 bg-gray-200 dark:bg-gray-900 m-1 ml-2 lg:mt-1 lg:m-3 lg:ml-6"
  >
    <div class="flex justify-between">
      <div class="font-semibold text-lg">
        Uploads @if (charge.requiresUpload && !charge.uploads?.length) {
        <span class="flex text-red-500">(Required)</span>
        }
      </div>
      <div class="flex items-center px-2">
        @if (canEditCharges === true) {
        <label class="text-teal-500 p-1 w-full" matTooltip="Upload Image for Charge">
          <input class="hidden" (change)="onTourChargeEditFileChange(charge, $event)" type="file" />
          <div class="bg-blue-500 text-white rounded-full w-3 h-3 flex items-center justify-center">
            <mat-icon>add</mat-icon>
          </div>
        </label>
        }
      </div>
    </div>
    <div class="flex flex-row flex-wrap">
      @for (upload of charge.uploads; track upload.id) {
      <div class="mr-4 relative max-w-1/5 lg:max-w-1/12">
        @if (canEditCharges === true) {
        <td-double-confirm
          buttonType="flat"
          buttonColor="warn"
          confirmText="Confirm"
          (confirm)="deleteCustomChargeFile(charge, upload.id)"
          style="position: absolute; transform: scale(0.75); right: -20px"
        >
          <button mat-mini-fab color="warn">
            <mat-icon class="text-white">delete_outline</mat-icon>
          </button>
        </td-double-confirm>
        }
        <div class="whitespace-normal break-words">
          <a [href]="upload.url" target="_blank">
            {{ upload.fileName }}
          </a>
        </div>
      </div>
      }
    </div>
  </div>
  }
</div>
} }

<!--TODO: remove everything below this line once all charges are request and we no longer need feature flag-->
<ng-template #editTourChargeModal>
  <div mat-dialog-title>
    <div class="flex justify-between items-center">
      <div>Update Charge</div>
      <button mat-mini-fab color="warn" (click)="updateConfirmDeleteCharge(true)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content>
    <form [formGroup]="tourChargeEditForm" (ngSubmit)="editTourCharge()">
      <mat-form-field class="w-full">
        <mat-label>Name</mat-label>
        <mat-select formControlName="name" [compareWith]="chargeTypes$ | compareWith: 'id'">
          @for (ct of chargeTypes$ | async; track ct.id) {
          <mat-option [value]="ct">{{ ct.displayName }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field class="w-full">
        <mat-label>Price</mat-label>
        <input tdCurrencyInput matInput formControlName="price" autocomplete="off" />
      </mat-form-field>
      @if (networkActive$ | async) {
      <div class="py-1">
        <mat-progress-bar mode="indeterminate" class="w-full"></mat-progress-bar>
      </div>
      } @else { @if (confirmDeleteCharge$ | async) {
      <div class="flex flex-col border-red-500 border-2 text-xl p-2">
        <div>Are you sure you want to delete this charge?</div>
        <div class="flex justify-between">
          <button mat-button (click)="updateConfirmDeleteCharge(false)" type="button">No</button>
          <button
            mat-raised-button
            color="warn"
            (click)="deleteCustomCharge(tourChargeEditForm.get('id').value)"
            type="button"
          >
            Yes
          </button>
        </div>
      </div>
      } @else {
      <div class="flex justify-between">
        <button mat-button mat-dialog-close type="button">Nevermind</button>
        <button mat-stroked-button color="primary" type="submit">Update Charge</button>
      </div>
      } }
    </form>
  </div>
</ng-template>

<ng-template #confirmDeleteLinehaulCharge>
  <div mat-dialog-title>Are you sure?</div>
  <div mat-dialog-content>
    <div class="flex flex-col border-red-500 border-2 text-xl p-2">
      <div>Are you sure you want to delete this charge?</div>
      <div mat-dialog-actions class="flex justify-between">
        <button mat-stroked-button mat-dialog-close>No</button>
        <button mat-raised-button color="warn" (click)="deleteLinehaulCharge()">Delete Linehaul Charge</button>
      </div>
    </div>
  </div>
</ng-template>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { LohiLoad } from '../../../../global-types';
import { LoadBillingService } from '../../../../services/load-billing.service';

@Component({
  selector: 'td-load-billiing-stop-charges-dispute',
  templateUrl: './load-billiing-stop-charges-dispute.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LoadBilliingStopChargesDisputeComponent {
  @Input() public load: LohiLoad;
  public loadType: string;
  public additionalStopPrompt: string;
  constructor(private lbs: LoadBillingService, private cdr: ChangeDetectorRef) {}

  public async disputeStopCharges(event: Event) {
    const resp = await this.lbs.runDisputeStopCharges(this.load.id);
    if (resp['allowed'] === null) {
      this.additionalStopPrompt = `Stop charge was not added because ${resp['note']}`;
    } else if (resp['allowed'] === false) {
      this.additionalStopPrompt = `Stop charge was not added because the ${resp['note']}`;
    } else {
      this.additionalStopPrompt = `Stop charge was added for ${resp['charges'] / 100}`;
    }
    this.cdr.detectChanges();
  }
}

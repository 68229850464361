import { Pipe, PipeTransform } from '@angular/core';
import { ConstantsService, PreferenceListItem } from '../services/constants.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'trailerAttributes',
  standalone: false,
})
export class TrailerAttributesPipe implements PipeTransform {
  private trailerMap$: Observable<Record<string, Record<number, PreferenceListItem>>>;

  constructor(private constantsService: ConstantsService) {
    this.trailerMap$ = this.constantsService.trailerTypeAttributesMap$;
  }

  public transform(choices: number[], trailerType: string): Observable<string[]> {
    return this.trailerMap$.pipe(
      map((trailerMap) => {
        const trailerChoices = trailerMap[trailerType];
        if (!trailerChoices) {
          return [];
        }
        return choices?.map((c) => trailerChoices[c]?.label || null).filter((c) => !!c);
      }),
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { MarketHeatType } from '../global-types';

const heats: Record<MarketHeatType, string> = {
  cold: '🥶 Cold Market',
  hot: '🔥 Hot Market',
  neutral: 'Neutral Market',
};

@Pipe({
  name: 'marketHeatType',
  standalone: false,
})
export class MarketHeatTypePipe implements PipeTransform {
  public transform(value: MarketHeatType): string {
    return heats[value];
  }
}

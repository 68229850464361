import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'td-money-number-line',
  templateUrl: './money-number-line.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MoneyNumberLineComponent {
  @Input() public lowerBoundCents: number;
  @Input() public lowerBoundRPMCents: number;
  @Input() public lowerBoundTitle: string;

  @Input() public middleBoundCents: number;
  @Input() public middleBoundRPMCents: number;
  @Input() public middleBoundTitle: string;

  @Input() public upperBoundCents: number;
  @Input() public upperBoundRPMCents: number;
  @Input() public upperBoundTitle: string;
  @Input() public value: number;
  @Input() public valueRPM: number;
  @Input() public customerPriceCents: number;
  @Input() public showDollarValueDifferences: boolean | null; //instead of margin and percents

  @Input() public showMargin = true;
  @Input() public evaluateLoadDesign = false;

  constructor() {}
}

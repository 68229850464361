<div matDialogTitle>
  Are you sure you want to delete this <span class="font-bold">{{ upload.category | fileUploadType }}</span> upload? @if
  (networkActive$$ | async) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  } @else {
  <div class="h-[4px]"></div>
  }
</div>
<div matDialogContent>
  @if (this.upload.passedQA) {
  <div class="text-lg mb -3 font-bold"><span class="text-red-500">Warning:</span> This load has already passed QA.</div>
  }
  <!--  <div *ngIf="upload.fileName | isImage; else notImage" class="my-3 py-3 border-y border-gray-400 dark:border-gray-700">-->
  <!--    <img alt="image" [src]="upload.shareableUrl" class="max-w-25" />-->
  <!--    <div>-->
  <!--      {{ upload.fileName }}-->
  <!--    </div>-->
  <!--  </div>-->
  <!--  <ng-template #notImage>-->
  <div>
    {{ upload.fileName }}
  </div>
  <!--  </ng-template>-->
</div>
<div matDialogActions>
  <button mat-button matDialogClose>Nevermind</button>
  <button mat-flat-button color="warn" (click)="removeUpload()">Delete @if (upload.passedQA) { Approved } File</button>
</div>

<form class="grid grid-cols-2 gap-x-2 relative" [formGroup]="formGroup" (ngSubmit)="doSave()">
  <div class="py-1 flex flex-col">
    <mat-label>Hazmat Endorsement</mat-label>
    <mat-slide-toggle formControlName="hasHazmatEndorsement"></mat-slide-toggle>
  </div>
  <mat-form-field class="py-1">
    <mat-label>Drop Trailer</mat-label>
    <mat-select formControlName="dropTrailerChoice">
      @for (choice of dropTrailerChoices$ | async; track choice.id) {
      <mat-option [value]="choice.id">{{ choice.label }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <div class="py-1 flex flex-col">
    <mat-label>Tanker Endorsement</mat-label>
    <mat-slide-toggle formControlName="hasTankerEndorsement"></mat-slide-toggle>
  </div>
  <mat-form-field class="py-1">
    <mat-label>Driver Loading/Unloading or Driver Tailgate</mat-label>
    <mat-select formControlName="driverTouchChoice">
      @for (choice of driverTouchChoices$ | async; track choice.id) {
      <mat-option [value]="choice.id">{{ choice.label }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field class="py-1">
    <mat-label>Multi-Stop Deliveries</mat-label>
    <mat-select formControlName="multistopChoice">
      @for (choice of multiStopChoices$ | async; track choice.id) {
      <mat-option [value]="choice.id">{{ choice.label }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <div class="flex justify-end w-full col-span-2">
    <button mat-flat-button color="primary" type="submit">Save</button>
  </div>
  @if (networkActive) {
  <div class="bg-white opacity-50 absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
    <mat-spinner></mat-spinner>
  </div>
  }
</form>

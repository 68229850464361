import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LohiLoad } from '../../../global-types';
import { LoadBillingService } from '../../../services/load-billing.service';
import {
  LohiLoadAllowedChargeType,
  TourLoadCharge,
} from '../../../../modules/carrier/carrier-loads/carrier-loads.service';
import { ConfirmationModalService } from '../../confirmation-modal/confirmation-modal.service';
import { Observable, takeUntil } from 'rxjs';
import { NetworkableDestroyableComponent } from '../../networkable-destroyable.component';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'td-load-billing-request-charge-update',
  templateUrl: './load-billing-request-charge-update.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LoadBillingRequestChargeUpdateComponent extends NetworkableDestroyableComponent {
  private readonly loadID: string;
  private readonly chargeID: number;
  private readonly currentCents: number;
  public tourChargeEditForm = new FormGroup({
    requestedCents: new FormControl<number | null>(null, [Validators.required]),
    carrierNotes: new FormControl<string | null>(null, [Validators.required]),
  });
  public chargeTypes$: Observable<LohiLoadAllowedChargeType[]>;
  public isSamePrice$: Observable<boolean>;

  constructor(
    private matDialogRef: MatDialogRef<LoadBillingRequestChargeUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { load: LohiLoad; charge: TourLoadCharge },
    public loadBillingService: LoadBillingService,
    public confirmations: ConfirmationModalService,
  ) {
    super();
    this.loadID = data?.load?.id;
    this.chargeID = data?.charge?.id;
    const hasActivePendingUpdate = !!!data?.charge?.pendingUpdate?.status;
    this.currentCents = hasActivePendingUpdate
      ? data.charge?.pendingUpdate?.requestedPerUnitCentsCarrier
      : data?.charge?.totalCents;
    this.isSamePrice$ = this.tourChargeEditForm.controls.requestedCents.valueChanges.pipe(
      startWith(this.currentCents),
      map((cents) => cents === this.currentCents),
    );
    this.tourChargeEditForm.patchValue({
      requestedCents: this.currentCents,
    });
    this.chargeTypes$ = this.loadBillingService.chargeTypes$;
  }

  public confirmedDeleteCharge() {
    this.confirmations
      .open(`Are you sure you want to remove this charge?`, null, 'Remove Charge')
      .afterClosed()
      .pipe(takeUntil(this.destroy$$))
      .subscribe(async (result: boolean) => {
        if (result) {
          const success = await this.loadBillingService.deleteTourCharge(this.data.load.id, this.data.charge.id);
          if (success) {
            this.matDialogRef.close();
          }
        }
      });
  }

  public async requestTourChargeUpdate() {
    if (!this.tourChargeEditForm.valid || this.networkActive$$.value) {
      return;
    }
    try {
      this.networkActive$$.next(true);

      const val = this.tourChargeEditForm.getRawValue();
      const success = await this.loadBillingService.requestUpdateCharge(
        this.loadID,
        this.chargeID,
        val.requestedCents,
        val.carrierNotes,
      );
      if (success) {
        this.matDialogRef.close();
      }
    } finally {
      this.networkActive$$.next(false);
    }
  }
}

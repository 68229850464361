import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { FusedObservable } from '../../utilities/fusedObservable';
import { shareReplayComponentConfig } from '../../constants';
import { shareReplay, startWith } from 'rxjs/operators';
import Fuse from 'fuse.js';
import FuseOptionKey = Fuse.FuseOptionKey;

@Component({
  selector: 'td-fused-search-element',
  templateUrl: './fused-search-element.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FusedSearchElementComponent<T> {
  @HostBinding('class.contents') public contents = true;
  public searchControl = new FormControl('');

  @Input() public selectSearch = false;
  @Input() public sort = true;
  // keys should always be set before the toSearch$ observable in the component
  @Input() public keys: Fuse.IFuseOptions<T> | FuseOptionKey<T>[] = [];

  private searchResults$$ = new BehaviorSubject<T[]>([]);
  private sub: Subscription;
  @Input() public set toSearch$(searchable$: Observable<T[]>) {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = new FusedObservable(
      searchable$,
      this.searchControl.valueChanges.pipe(startWith(this.searchControl.value)),
      this.keys,
    ).fused$.subscribe((value) => {
      this.searchResults$$.next(value);
    });
  }

  public get searchResults$(): Observable<T[]> {
    return this.searchResults$$.pipe(shareReplay(shareReplayComponentConfig));
  }

  constructor() {}
}

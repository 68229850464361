import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'td-shipline-image',
  templateUrl: './shipline-image.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ShiplineImageComponent {
  @Input() public shippingLineName: string | null = null;

  constructor() {}
}

import { ChangeDetectionStrategy, Component, Optional, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest, lastValueFrom, Observable, skip } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { SnackBarService } from '../../services/snackbar.service';
import { distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { FusedObservable } from '../../utilities/fusedObservable';
import { DestroyableComponent } from '../destroyable.component';
import { CompanySwapperService } from '../../services/company-swapper.service';
import { MatSelect } from '@angular/material/select';
import { MatDialogRef } from '@angular/material/dialog';

const MAX_COMPANIES_TO_SHOW = 50;

@Component({
  selector: 'td-company-switcher-shared',
  templateUrl: './company-switcher-shared.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CompanySwitcherSharedComponent extends DestroyableComponent {
  @ViewChild('companySelect') private companySelect: MatSelect;
  @ViewChild('searchInput') private searchInputEl: HTMLInputElement;
  public companySearch = new FormControl<string>(null);
  public companyPicker = new FormControl<string>(null);
  public companies$: Observable<{ id: string; name: string; billingSubsidiary: string }[]>;

  constructor(
    private companySwapper: CompanySwapperService,
    private authService: AuthService,
    private snackBar: SnackBarService,
    @Optional() private dialogRef: MatDialogRef<CompanySwitcherSharedComponent>,
  ) {
    super();
    this.doSetup();
    this.initializeSwitcherForm();
  }

  public ngAfterViewInit() {
    if (this.dialogRef) {
      setTimeout(() => {
        this.companySelect.open();
      }, 200); // let everything render
    }
  }

  private doSetup() {
    const myCompany$: Observable<{ id: string; name: string; billingSubsidiary: string }> = combineLatest([
      this.companySwapper.companies$,
      this.authService.userInfo$,
    ]).pipe(
      map(([companies, userInfo]) => {
        return (companies || []).find((c) => c.id === userInfo.companyId);
      }),
    );
    const searchedCompanies$ = new FusedObservable<{ id: string; name: string; billingSubsidiary: string }>(
      this.companySwapper.companies$,
      this.companySearch.valueChanges,
      ['id', 'name'],
    ).fused$.pipe(
      map((data) => {
        return data.slice(0, Math.min(data.length, MAX_COMPANIES_TO_SHOW));
      }),
    );
    this.companies$ = combineLatest([searchedCompanies$, myCompany$]).pipe(
      map(([searchedCompanies, myCompany]) => {
        let results = [];
        if (searchedCompanies) {
          results = searchedCompanies;
        }
        if (myCompany) {
          results = [
            {
              ...myCompany,
            },
            ...results,
          ];
        }
        return results;
      }),
    );
  }

  private async initializeSwitcherForm() {
    this.companyPicker.valueChanges
      .pipe(
        takeUntil(this.destroy$$),
        distinctUntilChanged(),
        filter((companyId) => !!companyId),
        skip(1), // because we are going to change it on the user info listener below
      )
      .subscribe({
        next: (companyId) => {
          this.swapCompany(companyId);
        },
      });
    this.authService.userInfo$.pipe(takeUntil(this.destroy$$)).subscribe({
      next: (userInfo) => {
        if (userInfo) {
          this.companyPicker.setValue(userInfo.companyId, { emitEvent: true });
        }
      },
    });
  }

  public async onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
    if (event.key !== ` `) {
      this.companySelect._handleKeydown(event);
    }
    if (!event.ctrlKey) {
      return;
    }
    if (event.key >= '1' && event.key <= '9') {
      const options = this.companySelect.options.toArray();
      const index = parseInt(event.key, 10);
      if (index < options.length) {
        const option = options[index];
        const companyId = option.value;
        this.companyPicker.setValue(companyId);
      }
    }
  }

  private async swapCompany(companyId: string) {
    try {
      this.companySelect.close();
      const userInfo = await lastValueFrom(
        this.authService.userInfo$.pipe(
          filter((u) => !!u),
          take(1),
        ),
      );
      if (!userInfo || userInfo.companyId === companyId) {
        return;
      }
      await this.companySwapper.swapCompany(companyId);
      this.snackBar.showMessage('Company updated');
      this.authService.loadUserInfo();
      if (this.dialogRef) {
        this.dialogRef.close();
      }
    } catch (e) {}
  }
}

<div class="flex flex-row items-center justify-between py-2">
  <button color="primary" mat-raised-button class="ml-2" (click)="disputeStopCharges($event)">
    Dispute Stop Charges
  </button>
  @if (additionalStopPrompt) {
  <div class="flex flex-col text-lg text-orange-500 ml-1">
    {{ additionalStopPrompt }}
  </div>
  }
</div>

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const constants_1 = require("../constants");
const years_1 = require("../../../calculation/years");
const pattern_1 = require("../../../utils/pattern");
const constants_2 = require("../constants");
const AbstractParserWithWordBoundary_1 = require("../../../common/parsers/AbstractParserWithWordBoundary");
const PATTERN = new RegExp(`(${pattern_1.matchAnyPattern(constants_1.MONTH_DICTIONARY)})` + `\\s*` + `(?:` + `[,-]?\\s*(${constants_2.YEAR_PATTERN})?` + ")?" + "(?=[^\\s\\w]|\\s+[^0-9]|\\s+$|$)", "i");
const MONTH_NAME_GROUP = 1;
const YEAR_GROUP = 2;
class NLMonthNameParser extends AbstractParserWithWordBoundary_1.AbstractParserWithWordBoundaryChecking {
  innerPattern() {
    return PATTERN;
  }
  innerExtract(context, match) {
    const components = context.createParsingComponents();
    components.imply("day", 1);
    const monthName = match[MONTH_NAME_GROUP];
    const month = constants_1.MONTH_DICTIONARY[monthName.toLowerCase()];
    components.assign("month", month);
    if (match[YEAR_GROUP]) {
      const year = constants_2.parseYear(match[YEAR_GROUP]);
      components.assign("year", year);
    } else {
      const year = years_1.findYearClosestToRef(context.refDate, 1, month);
      components.imply("year", year);
    }
    return components;
  }
}
exports.default = NLMonthNameParser;

<div mat-dialog-title>Become a Dedicated Driver</div>
<div mat-dialog-content>
  <div class="flex flex-col gap-y-3">
    <span class="font-normal text-base">Dedicated drivers earn up to 50% more per day.</span>
    @for (ranking of carrierRankings$ | async; track ranking.rank) {
    <div>
      <span class="font-medium"
        >You are currently
        <span [class.text-blue-500]="ranking.hasRanking">{{
          ranking.hasRanking ? (ranking.rank | ordinal) : 'unranked'
        }}</span>
        on the dedicated driver waitlist for {{ ranking.dispatchPool }}.</span
      >
      To improve your rank, book and complete more spot loads per day.
    </div>
    <div class="bg-green-400/20 p-2">
      <div class="font-semibold">
        Avg. Loads Completed:
        <span class="text-green-500"
          >{{ ranking.averageCompletedLoads | number: '1.0-0' }} loads/day (past 7 days)</span
        >
      </div>
      @if (ranking.loadsToGetFirst) {
      <div>Reach #1 by completing {{ ranking.loadsToGetFirst | number }} loads/day for 7 days.</div>
      }
    </div>
    }
    <div class="flex flex-col gap-y-1">
      <div class="text-xl font-semibold">Why become a dedicated driver?</div>
      <div class="flex flex-row gap-x-2 items-center">
        <div class="min-w-4">
          <img src="assets/icons/cpg-ufg/earn-more.svg" />
        </div>
        <div><span class="font-semibold">Earn more:</span> Make over $600 per day, depending on your region.</div>
      </div>
      <div class="flex flex-row gap-x-2 items-center">
        <div class="min-w-4">
          <img src="assets/icons/cpg-ufg/no-manual-dispatch.svg" />
        </div>
        <div>
          <span class="font-semibold">No manual dispatch:</span> We'll plan your loads and schedule for you—no need to
          book loads or figure out what's next.
        </div>
      </div>
      <div class="flex flex-row gap-x-2 items-center">
        <div class="min-w-4">
          <img src="assets/icons/cpg-ufg/consistent-work.svg" />
        </div>
        <div>
          <span class="font-semibold">Consistent work:</span> Get guaranteed loads every day for multiple days of work
          (shifts).
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <a [routerLink]="['/cpg_ufg']" mat-dialog-close mat-flat-button color="accent" class="w-full"
    >Start Booking Spot Loads</a
  >
</div>

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const constants_1 = require("../constants");
const pattern_1 = require("../../../utils/pattern");
const AbstractParserWithWordBoundary_1 = require("../../../common/parsers/AbstractParserWithWordBoundary");
const weekdays_1 = require("../../../common/calculation/weekdays");
const PATTERN = new RegExp("(?:(?:\\,|\\(|\\（)\\s*)?" + "(?:(este|esta|passado|pr[oó]ximo)\\s*)?" + `(${pattern_1.matchAnyPattern(constants_1.WEEKDAY_DICTIONARY)})` + "(?:\\s*(?:\\,|\\)|\\）))?" + "(?:\\s*(este|esta|passado|pr[óo]ximo)\\s*semana)?" + "(?=\\W|\\d|$)", "i");
const PREFIX_GROUP = 1;
const WEEKDAY_GROUP = 2;
const POSTFIX_GROUP = 3;
class PTWeekdayParser extends AbstractParserWithWordBoundary_1.AbstractParserWithWordBoundaryChecking {
  innerPattern() {
    return PATTERN;
  }
  innerExtract(context, match) {
    const dayOfWeek = match[WEEKDAY_GROUP].toLowerCase();
    const weekday = constants_1.WEEKDAY_DICTIONARY[dayOfWeek];
    if (weekday === undefined) {
      return null;
    }
    const prefix = match[PREFIX_GROUP];
    const postfix = match[POSTFIX_GROUP];
    let norm = prefix || postfix || "";
    norm = norm.toLowerCase();
    let modifier = null;
    if (norm == "passado") {
      modifier = "this";
    } else if (norm == "próximo" || norm == "proximo") {
      modifier = "next";
    } else if (norm == "este") {
      modifier = "this";
    }
    return weekdays_1.createParsingComponentsAtWeekday(context.reference, weekday, modifier);
  }
}
exports.default = PTWeekdayParser;

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { CarrierDocumentUploadComponent } from 'src/app/modules/internal/carrier-list/carrier-detail/carrier-document-upload/carrier-document-upload.component';
import { FileUploadType, LohiLoad } from '../../../../global-types';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { acceptableFileTypes, shareReplayComponentConfig } from '../../../../constants';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '../../../../services/snackbar.service';
import { LoadBillingService } from '../../../../services/load-billing.service';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { MatSelect } from '@angular/material/select';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'td-load-billiing-lumper-dispute',
  templateUrl: './load-billiing-lumper-dispute.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LoadBilliingLumperDisputeComponent {
  // public checkFacilityLumper
  // @Input() public lohiLoad$: Observable<LohiLoad>;
  @Input() public load: LohiLoad;
  @Input() public oldRate: number;
  public lumperIsAllowed: boolean = false;
  private filesToUpload$$ = new BehaviorSubject<File[]>([]);
  public filesToUpload$: Observable<string[]> = this.filesToUpload$$.pipe(map((v) => v.map((f) => f.name)));
  public displayDispute: boolean = true;
  public lumperPrompt: string;
  @ViewChild('uploadFile') private uploadFileModal: TemplateRef<any>;
  @ViewChild(MatSelect) public selection: MatSelect;
  private imageType$$ = new BehaviorSubject<{
    type: FileUploadType;
    name: string;
  }>(null);
  public imageType$: Observable<{
    type: FileUploadType;
    name: string;
  }> = this.imageType$$.pipe(shareReplay(shareReplayComponentConfig));
  public facilityControl = new FormControl();
  constructor(
    private snackbar: SnackBarService,
    private lbs: LoadBillingService,
    private matDialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) {}
  public async disputeLumper(event: Event) {
    const selectedFacility = this.facilityControl.value;
    this.lumperPrompt = '';
    const resp = await this.lbs.runDisputeLumper(this.load.id, selectedFacility, this.oldRate);
    if (resp['allowed']) {
      this.lumperPrompt = resp['note'];
      this.displayDispute = false;
      this.cdr.detectChanges();
    } else {
      this.lumperPrompt = resp['note'];
      this.displayDispute = true;
      this.cdr.detectChanges();
    }
  }
  public async promptUploadFile(chargeType: FileUploadType, chargeName: string) {
    this.imageType$$.next({ type: chargeType, name: chargeName });
    this.matDialog.open(this.uploadFileModal, {
      panelClass: ['w-full', 'lg:w-1/3'],
      closeOnNavigation: true,
    });
  }
  public async submitFile() {
    for (const file of this.filesToUpload$$.value) {
      if (!file.type.startsWith('image/') && !acceptableFileTypes.includes(file.type)) {
        this.snackbar.showError('File type not allowed');
        return;
      }
      const lohiLoad = this.load;
      const loadFile = await this.lbs.uploadFile(lohiLoad.id, file, this.imageType$$.value.type);
      if (!loadFile) {
        return;
      }
    }
    this.matDialog.closeAll();
    this.filesToUpload$$.next([]);
  }
  public async setNextFile(files: NgxFileDropEntry[]) {
    const uploadedFiles = [];
    for (const file of files) {
      if (file.fileEntry.isFile) {
        const fileEntry = file.fileEntry as FileSystemFileEntry;
        fileEntry.file((value) => {
          uploadedFiles.push(value);
        });
      }
    }
    this.filesToUpload$$.next(uploadedFiles);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plusMore',
  standalone: false,
})
export class PlusMorePipe implements PipeTransform {
  public transform(value: any[], count = 1): string {
    if (!Array.isArray(value)) {
      return value;
    }
    let result = value.slice(0, count).join(', ');
    if (count < value.length) {
      result = `${result}, +${value.length - count} more`;
    }
    return result;
  }
}

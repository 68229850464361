import { Pipe, PipeTransform } from '@angular/core';
import { stringOrDateToDate } from '../utilities/dateTime';
import { differenceInMinutes } from 'date-fns';

@Pipe({
  name: 'differenceInMinutes',
  standalone: false,
})
export class DifferenceInMinutesPipe implements PipeTransform {
  /**
   * Returns the difference in minutes between the piped date and the provided date.
   * If the piped date is before the provided date, the return value will be negative.
   * If no date is provided, the provided date will be the current date.
   */
  public transform(value1: string | Date, value2: string | Date = new Date()): number {
    if (!value1 || !value2) {
      return 0;
    }
    const baseDate = stringOrDateToDate(value1);
    const dateToCompareIsAfter = stringOrDateToDate(value2);
    return differenceInMinutes(baseDate, dateToCompareIsAfter);
  }
}

"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const AbstractParserWithWordBoundary_1 = require("../../../common/parsers/AbstractParserWithWordBoundary");
const references = __importStar(require("../../../common/casualReferences"));
const constants_1 = require("../constants");
const PATTERN = new RegExp(`(?:с|со)?\\s*(сегодня|вчера|завтра|послезавтра|позавчера)${constants_1.REGEX_PARTS.rightBoundary}`, constants_1.REGEX_PARTS.flags);
class RUCasualDateParser extends AbstractParserWithWordBoundary_1.AbstractParserWithWordBoundaryChecking {
  patternLeftBoundary() {
    return constants_1.REGEX_PARTS.leftBoundary;
  }
  innerPattern(context) {
    return PATTERN;
  }
  innerExtract(context, match) {
    const lowerText = match[1].toLowerCase();
    const component = context.createParsingComponents();
    switch (lowerText) {
      case "сегодня":
        return references.today(context.reference);
      case "вчера":
        return references.yesterday(context.reference);
      case "завтра":
        return references.tomorrow(context.reference);
      case "послезавтра":
        return references.theDayAfter(context.reference, 2);
      case "позавчера":
        return references.theDayBefore(context.reference, 2);
    }
    return component;
  }
}
exports.default = RUCasualDateParser;

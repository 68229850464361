import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConstantsService, State } from '../services/constants.service';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'states',
  standalone: false,
})
export class StatesPipe implements PipeTransform {
  private statesMap$: Observable<Record<number, State>>;

  constructor(private constantsService: ConstantsService) {
    this.statesMap$ = this.constantsService.allStatesMap$;
  }

  public transform(value: number[]): Observable<string[]> {
    if (!Array.isArray(value)) {
      return of(['']);
    }
    return this.statesMap$.pipe(
      map((sm) => {
        const response: string[] = [];
        value.forEach((v) => {
          response.push(sm[v]?.abbreviation || null);
        });
        return response.filter((v) => !!v);
      }),
    );
  }
}

@if (lohiLoad$ | async; as currentLoad) {
<div class="flex flex-col gap-y-3">
  <div class="card-with-shadow-white-no-radius flex flex-col flex-grow p-2 gap-2">
    <div class="flex flex-row justify-between items-center flex-wrap gap-2">
      <div class="font-semibold text-lg">Status</div>
      @if (tourBillingDetails$ | async; as tourBillingDetails) {
      <div>
        @if (currentLoad.status === 'completed') {
        <div>
          @if ( !!(productLoad$ | async) && !tourBillingDetails.undoExpiresAt && !(productLoad$ |
          async)?.billingInfo?.needsAdditionalInfo?.length && !(productLoad$ |
          async)?.billingInfo?.needsAdditionalUpload?.length ) {
          <button mat-raised-button color="primary" (click)="approveLoad(currentLoad.id)">Approve For Invoicing</button>
          }
        </div>
        } @if (tourBillingDetails$ | async; as tourBillingDetails) { @if (tourBillingDetails.undoExpiresAt | countdown |
        async; as undoExpireTime) { @if (tourBillingDetails.canUndo && undoExpireTime !== '–') {
        <div>
          <button mat-raised-button color="warn" (click)="unapproveTourLoad()">
            Undo Approval | Expires: {{ undoExpireTime }}
          </button>
        </div>
        } @else { @if (tourBillingDetails.approvedAt) {
        <div>
          Approved By <span class="font-bold">{{ tourBillingDetails.approvedBy }}</span> at
          {{ tourBillingDetails.approvedAt | standardDate }}
        </div>
        } @if (tourBillingDetails.adminApprovedAt) {
        <div>
          Approved By <span class="font-bold">{{ tourBillingDetails.adminApprovedByName }}</span> at
          {{ tourBillingDetails.adminApprovedAt | standardDate }}
        </div>
        } }
        <ng-template #approvedBy>
          @if (tourBillingDetails.approvedAt) {
          <div>
            Approved By <span class="font-bold">{{ tourBillingDetails.approvedBy }}</span> at
            {{ tourBillingDetails.approvedAt | standardDate }}
          </div>
          } @if (tourBillingDetails.adminApprovedAt) {
          <div>
            Approved By <span class="font-bold">{{ tourBillingDetails.adminApprovedByName }}</span> at
            {{ tourBillingDetails.adminApprovedAt | standardDate }}
          </div>
          }
        </ng-template>
        } @if (tourBillingDetails.settlementId) {
        <a mat-button [routerLink]="['/settlements/details', tourBillingDetails.settlementId]">View Settlement</a>
        } }
      </div>
      }
      <div class="flex flex-col gap-1 items-end">
        @if ( (productLoad$ | async)?.billingInfo?.needsAdditionalInfo?.length || (productLoad$ |
        async)?.billingInfo?.needsAdditionalUpload?.length ) {
        <div class="bg-red-500 text-white rounded-full w-[fit-content] whitespace-nowrap p-1">
          Needs
          {{
            (productLoad$ | async)?.billingInfo?.needsAdditionalInfo
              | push: (productLoad$ | async)?.billingInfo.needsAdditionalUpload
              | joinWithAnd
          }}
        </div>
        }
      </div>
    </div>
    @if (networkActive$ | async) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </div>
  <div class="card-with-shadow-white-no-radius flex flex-col flex-grow p-2 gap-2">
    <div class="font-semibold text-lg flex flex-row justify-between flex-wrap gap-2">
      <div>Load Documents</div>
      <div class="flex flex-row gap-2 flex-wrap">
        <a
          mat-button
          target="_blank"
          [routerLink]="['/ratecon', 'load', currentLoad.id, 'details']"
          [queryParams]="{
            RATECON_TOKEN_ID: rateconToken$ | async
          }"
          >View Rate Con</a
        >
        @if ((canEditCharges$ | async) === true) {
        <div>
          <button mat-flat-button color="accent" [mat-menu-trigger-for]="uploadType">Upload Documents</button>
          <mat-menu #uploadType>
            <button mat-menu-item (click)="promptUploadFile('bol_image', 'BOL')">Upload POD</button>
            <button mat-menu-item (click)="promptUploadFile('rate_con', 'Ratecon')">Upload Ratecon</button>
            <button mat-menu-item (click)="promptUploadFile('in_gate', 'In-gate')">Upload In-gate</button>
            <button mat-menu-item (click)="promptUploadFile('out_gate', 'Out-gate')">Upload Out-gate</button>
            <button mat-menu-item (click)="promptUploadFile('other_image', 'Other')">Upload Other Documents</button>
          </mat-menu>
        </div>
        }
      </div>
    </div>
    @if (productLoad$ | async; as productLoad) { @if (productLoad.uploads.length === 0) {
    <div class="text-gray-600 text-sm">No documents uploaded</div>
    } @if (productLoad.uploads | filter: false:'passedQA'; as rejectedUploads) { @if (rejectedUploads.length > 0) {
    <div class="grid grid-flow-col auto-cols-max gap-x-2 mb-2">
      <div>
        <div class="text-sm text-gray-600">Status</div>
        <div class="mt-1">Rejected</div>
      </div>
      <div>
        <div class="text-sm text-gray-600">Action</div>
        <div class="mt-1 max-w-35">One or more documents were rejected, please upload new documents.</div>
      </div>
      <div>
        <div class="text-sm text-gray-600">Categories</div>
        <div class="mt-1">{{ rejectedUploads | map: 'category' | distinct | fileUploadTypes | joinWithAnd }}</div>
      </div>
      <div>
        <div class="text-sm text-gray-600">Notes</div>
        <div class="mt-1 max-w-lg">
          {{ rejectedUploads | map: 'qaRejectedReason' | distinct | joinWithAnd }}
        </div>
      </div>
    </div>
    } }
    <div class="grid grid-cols-2 md:grid-cols-4 items-center">
      <div class="text-sm text-gray-600">Type</div>
      <div class="text-sm text-gray-600">Upload Date</div>
      <div class="text-sm text-gray-600">Upload By</div>
      <div></div>
    </div>
    @for (file of productLoad.uploads; track file.id) {
    <div class="grid grid-cols-2 md:grid-cols-4 items-center odd:bg-gray-100 dark:odd:bg-slate-900 p-1">
      <div [class.line-through]="file.passedQA === false">
        {{ file.category | fileUploadType }}
      </div>
      <div [class.line-through]="file.passedQA === false">
        {{ file.uploadedTimestamp | standardDate }}
      </div>
      <div [class.line-through]="file.passedQA === false">
        {{ file.uploadedBy }}
      </div>
      <div class="flex flex-row justify-end gap-3">
        @if ((canEditCharges$ | async) === true) {
        <button mat-flat-button color="warn" (click)="promptDeleteUpload(file)">Delete</button>
        }
        <a mat-flat-button [href]="file.shareableUrl" target="_blank" color="primary">View</a>
      </div>
      @if (file.passedQA === false) {
      <div class="col-span-2 md:col-span-4">
        This document was rejected due to <span class="font-bold">{{ file.qaRejectedReason }}</span>
      </div>
      }
    </div>
    } }
  </div>
  <div class="card-with-shadow-white-no-radius flex flex-col flex-grow p-2 gap-2">
    <div class="grid grid-cols-2 gap-2">
      <div class="font-semibold text-lg col-span-2 flex flex-row justify-between">
        <div>Load Details</div>
        @if ((canEditCharges$ | async) === true) {
        <div class="cursor-pointer text-blue-400" (click)="startEditingDetails()">Edit</div>
        }
      </div>
      @if (productLoad$ | async; as productLoad) {
      <div>
        <div class="text-sm text-gray-600">Truck</div>
        {{ productLoad?.truckName | dashIfNothing }}
      </div>
      <div>
        <div class="text-sm text-gray-600">Trailer</div>
        {{ productLoad?.trailerName | dashIfNothing }}
      </div>
      <div>
        <div class="text-sm text-gray-600">Quantity</div>
        <div>
          {{ getLoadQuantity(productLoad) | number | dashIfNothing }}
          @if (productLoad.stops[1]?.tasks[0].actualQuantity || productLoad.stops[0]?.tasks[0].actualQuantity) {
          {{ productLoad.stops[1]?.tasks[0].product.unit.abbreviation }}
          }
        </div>
      </div>
      <div>
        <div class="text-sm text-gray-600">Linehaul Miles</div>
        {{ currentLoad?.tripMileage | number | dashIfNothing }}
      </div>
      <div>
        <div class="text-sm text-gray-600">Deadhead Miles</div>
        {{ currentLoad?.deadheadMiles | number | dashIfNothing }}
      </div>
      }
    </div>
  </div>
  <div class="card-with-shadow-white-no-radius flex flex-col flex-grow p-2 gap-2">
    <div class="flex flex-row justify-between items-center">
      <div class="font-semibold text-lg">Charges</div>
      <ng-container *tdFeatureFlag="'billingDisputeInquiryAlphaTesters'" class="flex-col-reverse">
        @if ((canEditCharges$ | async) === true) {
        <button color="primary" mat-raised-button class="ml-2" (click)="disputeCharges()">Dispute Charges</button>
        }
      </ng-container>
      <ng-container *tdFeatureFlag="'chargesUpdatesAreRequests'; else directlyAddCharge">
        @if ((canEditCharges$ | async) === true) {
        <button mat-mini-fab color="primary" (click)="startAddChargeRequest()">
          <mat-icon>add</mat-icon>
        </button>
        }
      </ng-container>
      <ng-template #directlyAddCharge>
        @if ((canEditCharges$ | async) === true) {
        <button mat-mini-fab color="primary" (click)="startAddCharge()">
          <mat-icon>add</mat-icon>
        </button>
        }
      </ng-template>
    </div>
    <td-load-billing-charge-list
      [load]="lohiLoad$ | async"
      [charges]="tourLoadCharges$ | async"
      [canEditCharges]="canEditCharges$ | async"
      [productLoad]="productLoad$ | async"
    ></td-load-billing-charge-list>
    <td-load-billing-pending-charge-list
      [load]="lohiLoad$ | async"
      [pendingCharges]="tourLoadPendingCharges$ | async | filter: null:'status'"
      [canEditCharges]="canEditCharges$ | async"
    ></td-load-billing-pending-charge-list>
    <div class="mt-2">
      @if (vortoTakeRate$ | async; as takeRate) {
      <div>
        <div class="flex flex-row items-center justify-between py-2 font-semibold">
          <div>
            Gross Total @if (hasPendingChargeChanges$ | async) {
            <span class="ml-2 text-orange-600 font-semibold"> Pending 5F Approval </span>
            }
          </div>
          <div>
            {{ chargeAndPendingChargeTotal$ | async | currencyInPennies }}
          </div>
        </div>
        <div class="flex flex-row items-center justify-between py-2 font-semibold">
          <div>
            5F Fee @if (hasPendingChargeChanges$ | async) {
            <span class="ml-2 text-orange-600 font-semibold"> Pending 5F Approval </span>
            }
          </div>
          <div class="text-red-500">
            {{ takeRate.vortoTakeCents - (chargeAndPendingChargeTotal$ | async) | currencyInPennies }}
          </div>
        </div>
        <div class="flex flex-row items-center justify-between py-2 font-semibold">
          <div>
            Net Payable to You @if (hasPendingChargeChanges$ | async) {
            <span class="ml-2 text-orange-600 font-semibold"> Pending 5F Approval </span>
            }
          </div>
          <div>
            {{ takeRate.vortoTakeCents | currencyInPennies }}
          </div>
        </div>
      </div>
      }
    </div>
  </div>
  @if (tourLoadPendingCharges$ | async | filter: 'denied':'status'; as deniedCharges) { @if (deniedCharges?.length) {
  <div class="card-with-shadow-white-no-radius flex flex-col flex-grow p-2 gap-2">
    <div class="flex flex-row justify-between items-center">
      <div class="font-semibold text-lg">Denied Charges</div>
    </div>
    <td-load-billing-pending-charge-list
      [load]="lohiLoad$ | async"
      [pendingCharges]="deniedCharges"
      [canEditCharges]="false"
      [hideLastBorder]="true"
    ></td-load-billing-pending-charge-list>
  </div>
  } }
  <div class="card-with-shadow-white-no-radius flex flex-col flex-grow p-2 gap-2">
    <div class="grid grid-cols-2 gap-2">
      <div class="font-semibold text-lg col-span-2">Load Information</div>
      <div>
        <div class="text-sm text-gray-600">Accepted At</div>
        {{ (productLoad$ | async)?.acceptedTimestamp | standardDate }}
      </div>
      <div>
        <div class="text-sm text-gray-600">Completed At</div>
        {{ (productLoad$ | async)?.completedTimestamp | standardDate }}
      </div>
      <div>
        <div class="text-sm text-gray-600">Driver Information</div>
        <div>
          {{ currentLoad?.driverName }}
        </div>
        <a href="tel:{{ currentLoad?.driverPhone }}" class="text-blue-400">
          {{ currentLoad?.driverPhone | phone }}
        </a>
      </div>
    </div>
  </div>
  <ng-template #uploadFile>
    <div mat-dialog-title>Upload {{ (imageType$ | async)?.name }}</div>
    <mat-dialog-content>
      <div>
        <div class="font-bold text-lg mb-2">
          Please do not upload anything other than the {{ (imageType$ | async)?.name }}.
        </div>
        <div class="mb-2">
          If you are uploading a PDF and have bundled
          <span class="font-semibold">multiple different documents into one PDF</span>, please separate them before
          uploading. Otherwise you may be subject to a manual processing fee. Our shipping partners requires these
          documents individually.
        </div>
        <div class="mb-2">
          <span class="font-semibold">Attaching unnecessary documents</span> (such as a ratecon attached to the BOL) may
          also result in a manual processing fee.
        </div>
      </div>
      <ngx-file-drop
        [showBrowseBtn]="true"
        dropZoneLabel="Drop files here"
        contentClassName="flex flex-col w-full h-full items-center"
        dropZoneClassName=""
        (onFileDrop)="setNextFile($event)"
      >
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          @if (filesToUpload$ | async; as file) {
          <div>
            @for (f of file; track f) {
            <div>
              {{ f }}
            </div>
            }
          </div>
          }
          <button mat-flat-button type="button" color="accent" class="w-full mb-1" (click)="openFileSelector()">
            Browse Files
          </button>
        </ng-template>
      </ngx-file-drop>
    </mat-dialog-content>
    <mat-dialog-actions class="flex flex-row justify-between">
      <button mat-flat-button mat-dialog-close color="accent">Close</button>
      <button mat-flat-button color="primary" (click)="submitFile()">
        <span class="text-white">Upload</span>
      </button>
    </mat-dialog-actions>
  </ng-template>
  <!--  TODO: remove this once the feature flag is removed-->
  <ng-template #addTourChargeModal>
    <div mat-dialog-title>Add Charge</div>
    <div mat-dialog-content>
      <form [formGroup]="tourChargeAddForm" (ngSubmit)="addCharge()">
        <mat-form-field class="w-full">
          <mat-label>Name</mat-label>
          <mat-select formControlName="chargeType">
            @for (ct of chargeTypes$ | async; track ct.id) {
            <mat-option [value]="ct">{{ ct.displayName }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w-full">
          <mat-label>Price</mat-label>
          <input matInput tdCurrencyInput formControlName="price" autocomplete="off" />
        </mat-form-field>
        @if (networkActive$ | async) {
        <div class="py-1">
          <mat-progress-bar mode="indeterminate" class="w-full"></mat-progress-bar>
        </div>
        } @else {
        <div class="flex justify-between">
          <button mat-button mat-dialog-close type="button">Nevermind</button>
          <button mat-stroked-button color="primary" type="submit">Add Charge</button>
        </div>
        }
      </form>
    </div>
  </ng-template>
</div>
}

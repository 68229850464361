<div mat-dialog-content>
  <div class="flex flex-col gap-y-2 p-2 py-3 overflow-y-auto md:max-h-[400px]">
    <mat-form-field class="flex-1">
      <mat-icon matPrefix>search</mat-icon>
      <mat-label>Start typing to search for a driver or load</mat-label>
      <input #searchInput matInput autocomplete="off" [formControl]="searchBox" />
      @if (active$ | async) {
      <mat-progress-spinner matSuffix mode="indeterminate" diameter="20"></mat-progress-spinner>
      }
    </mat-form-field>
    @if ({ drivers: filteredDrivers$ | async, loads: maxLoadSearchResults$ | async, trailers: maxTrailersSearchResults$
    | async, commands: filteredCommands$ | async, history: filteredHistory$ | async, all: allResults$ | async }; as
    searchResults) { @if ( searchResults.drivers?.length + searchResults.loads?.results?.length +
    searchResults.commands?.length > 10 ) {
    <div>
      <div class="text-xs font-medium text-gray-600">Jump To</div>
      <div class="flex flex-row gap-2">
        @if (searchResults.loads?.results?.length) {
        <div class="hover:text-blue-500 cursor-pointer" (click)="scrollToLoads()">Loads</div>
        } @if (searchResults.trailers?.results?.length) {
        <div class="hover:text-blue-500 cursor-pointer" (click)="scrollToTrailers()">Trailers</div>
        } @if (searchResults.drivers?.length) {
        <div class="hover:text-blue-500 cursor-pointer" (click)="scrollToDrivers()">Drivers</div>
        } @if (searchResults.commands?.length) {
        <div class="hover:text-blue-500 cursor-pointer" (click)="scrollToCommands()">Commands</div>
        }
      </div>
    </div>
    } @if (searchResults.loads?.results?.length) {
    <div class="flex flex-col gap-y-1" #loadsLink>
      <div class="font-semibold text-xs">Loads</div>
      @for ( load of searchResults.loads?.results; track load.internalId) {
      <a
        [routerLink]="['/loads', load.internalId]"
        (click)="ref.close(load.internalId)"
        class="flex flex-row cursor-pointer justify-between p-2 hover:dark:bg-zinc-700 hover:bg-gray-200 color-transition px-2 py-1 result"
      >
        <div class="max-w-2/3">
          <div class="font-semibold">{{ load.internalId }} / {{ load.referenceNumber }}</div>
          @if (load.shipperId) {
          <div>
            <div class="text-xs text-gray-600">Short ID</div>
            {{ load.shipperId }}
          </div>
          } @if ((firstResult$ | async) === load) {
          <div class="bg-blue-500 text-sm text-white font-medium max-w-min px-1 rounded-sm">Enter</div>
          }
        </div>
        <div class="text-right">
          <div>
            {{ load.status | lohiLoadStatus }}
          </div>
          @if (load.carrierName) {
          <div>
            <div class="text-xs text-gray-600">Carrier</div>
            {{ load.carrierName }}
          </div>
          }
        </div>
      </a>
      }
      <div>
        @if (searchResults.loads?.showMore) {
        <button color="accent" mat-button (click)="showMoreLoads()">Show More</button>
        }
      </div>
    </div>
    } @if (searchResults.trailers?.results?.length) {
    <div class="flex flex-col gap-y-1" #trailersLink>
      <div class="font-semibold text-xs">Trailers</div>
      @for ( trailer of searchResults.trailers?.results; track trailer.id) {
      <a
        [routerLink]="['/drop_trailer/trailer', trailer.id]"
        (click)="ref.close(trailer.id)"
        class="flex flex-row cursor-pointer justify-between p-2 hover:dark:bg-zinc-700 hover:bg-gray-200 color-transition px-2 py-1 result"
      >
        <div class="max-w-2/3">
          <div class="font-semibold">{{ trailer.name }}</div>
          @if (trailer.externalIdentifier) {
          <div class="text-gray-600">
            {{ trailer.externalIdentifier }}
          </div>
          } @if ((firstResult$ | async) === trailer) {
          <div class="bg-blue-500 text-sm text-white font-medium max-w-min px-1 rounded-sm mt-1">Enter</div>
          }
        </div>
      </a>
      }
      <div>
        @if (searchResults.trailers?.showMore) {
        <button color="accent" mat-button (click)="showMoreTrailers()">Show More</button>
        }
      </div>
    </div>
    } @if (searchResults.drivers?.length) {
    <div class="flex flex-col gap-y-1" #driversLink>
      <div class="font-semibold text-xs">Drivers</div>
      @for (driver of searchResults.drivers; track driver.userId) {
      <a
        [routerLink]="['/', 'dashboard', 'driver', driver.userId]"
        (click)="ref.close(driver.userId)"
        class="flex flex-row cursor-pointer justify-between p-2 hover:dark:bg-zinc-700 hover:bg-gray-200 color-transition px-2 py-1"
      >
        <div class="max-w-2/3">
          <div class="font-semibold">{{ driver.userName }}</div>
          @if (driver.userPhone) {
          <div>
            <div class="text-xs text-gray-600">Phone</div>
            {{ driver.userPhone | phone }}
          </div>
          } @if (driver.userEmail) {
          <div>
            <div class="text-xs text-gray-600">Email</div>
            {{ driver.userEmail }}
          </div>
          } @if ((firstResult$ | async) === driver) {
          <div class="bg-blue-500 text-sm text-white font-medium max-w-min px-1 rounded-sm">Enter</div>
          }
        </div>
        <div class="text-right">
          <div>
            {{ driver.carrierName }}
          </div>
          @if (driver.carrierDOTNumber) {
          <div>
            <div class="text-xs text-gray-600">DOT Number</div>
            {{ driver.carrierDOTNumber }}
          </div>
          } @if (driver.carrierMcNumber) {
          <div>
            <div class="text-xs text-gray-600">MC Number</div>
            {{ driver.carrierMcNumber }}
          </div>
          }
        </div>
      </a>
      }
    </div>
    } @if (searchResults.commands?.length) {
    <div class="flex flex-col gap-y-1" #commandsLink>
      <div class="font-semibold text-xs">Commands</div>
      @for (command of searchResults.commands; track command.displayName) {
      <button
        (click)="ref.close(); command.activate()"
        class="flex flex-row justify-between items-center hover:dark:bg-zinc-700 hover:bg-gray-200 color-transition px-2 py-1 result"
      >
        <div>
          {{ command.displayName }}
          @if ((firstResult$ | async) === command) {
          <div class="bg-blue-500 text-sm text-white font-medium max-w-min px-1 rounded-sm">Enter</div>
          }
        </div>
        @if (command.shortcut) {
        <button mat-stroked-button color="primary">
          {{ command.shortcut }}
        </button>
        }
      </button>
      }
    </div>
    } @if (searchResults.history?.length) {
    <div class="flex flex-col gap-y-1" #recentLink>
      <div class="font-semibold text-xs">Recent</div>
      @for (history of searchResults.history; track history.id) {
      <a
        [routerLink]="history.url"
        (click)="ref.close(history)"
        class="flex flex-row gap-2 items-center hover:dark:bg-zinc-700 hover:bg-gray-200 color-transition px-2 py-1 result"
      >
        @switch (history.type) { @case ('load') {
        <mat-icon>local_shipping</mat-icon>
        } @case ('pool') {
        <mat-icon>workspaces</mat-icon>
        } @case ('driver') {
        <mat-icon>person</mat-icon>
        } }
        <div>
          {{ history.name }}
          <div class="text-gray-600 text-xs">
            {{ history.type === 'load' ? 'Load' : history.type === 'pool' ? 'Dispatch Pool' : 'Driver' }}
          </div>
          @if ((firstResult$ | async) === history) {
          <div class="bg-blue-500 text-sm text-white font-medium max-w-min px-1 rounded-sm">Enter</div>
          }
        </div>
      </a>
      }
    </div>
    } }
  </div>
</div>

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const constants_1 = require("../constants");
const results_1 = require("../../../results");
const AbstractParserWithWordBoundary_1 = require("../../../common/parsers/AbstractParserWithWordBoundary");
const timeunits_1 = require("../../../utils/timeunits");
const pattern_1 = require("../../../utils/pattern");
class FRTimeUnitAgoFormatParser extends AbstractParserWithWordBoundary_1.AbstractParserWithWordBoundaryChecking {
  constructor() {
    super();
  }
  innerPattern() {
    return new RegExp(`(?:les?|la|l'|du|des?)\\s*` + `(${constants_1.NUMBER_PATTERN})?` + `(?:\\s*(prochaine?s?|derni[eè]re?s?|pass[ée]e?s?|pr[ée]c[ée]dents?|suivante?s?))?` + `\\s*(${pattern_1.matchAnyPattern(constants_1.TIME_UNIT_DICTIONARY)})` + `(?:\\s*(prochaine?s?|derni[eè]re?s?|pass[ée]e?s?|pr[ée]c[ée]dents?|suivante?s?))?`, "i");
  }
  innerExtract(context, match) {
    const num = match[1] ? constants_1.parseNumberPattern(match[1]) : 1;
    const unit = constants_1.TIME_UNIT_DICTIONARY[match[3].toLowerCase()];
    let timeUnits = {};
    timeUnits[unit] = num;
    let modifier = match[2] || match[4] || "";
    modifier = modifier.toLowerCase();
    if (!modifier) {
      return;
    }
    if (/derni[eè]re?s?/.test(modifier) || /pass[ée]e?s?/.test(modifier) || /pr[ée]c[ée]dents?/.test(modifier)) {
      timeUnits = timeunits_1.reverseTimeUnits(timeUnits);
    }
    return results_1.ParsingComponents.createRelativeFromReference(context.reference, timeUnits);
  }
}
exports.default = FRTimeUnitAgoFormatParser;

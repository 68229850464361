"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const constants_1 = require("../constants");
const pattern_1 = require("../../../utils/pattern");
const AbstractParserWithWordBoundary_1 = require("../../../common/parsers/AbstractParserWithWordBoundary");
const weekdays_1 = require("../../../common/calculation/weekdays");
const PATTERN = new RegExp("(?:(?:\\,|\\(|\\（)\\s*)?" + "(?:a[mn]\\s*?)?" + "(?:(diese[mn]|letzte[mn]|n(?:ä|ae)chste[mn])\\s*)?" + `(${pattern_1.matchAnyPattern(constants_1.WEEKDAY_DICTIONARY)})` + "(?:\\s*(?:\\,|\\)|\\）))?" + "(?:\\s*(diese|letzte|n(?:ä|ae)chste)\\s*woche)?" + "(?=\\W|$)", "i");
const PREFIX_GROUP = 1;
const SUFFIX_GROUP = 3;
const WEEKDAY_GROUP = 2;
class DEWeekdayParser extends AbstractParserWithWordBoundary_1.AbstractParserWithWordBoundaryChecking {
  innerPattern() {
    return PATTERN;
  }
  innerExtract(context, match) {
    const dayOfWeek = match[WEEKDAY_GROUP].toLowerCase();
    const offset = constants_1.WEEKDAY_DICTIONARY[dayOfWeek];
    const prefix = match[PREFIX_GROUP];
    const postfix = match[SUFFIX_GROUP];
    let modifierWord = prefix || postfix;
    modifierWord = modifierWord || "";
    modifierWord = modifierWord.toLowerCase();
    let modifier = null;
    if (modifierWord.match(/letzte/)) {
      modifier = "last";
    } else if (modifierWord.match(/chste/)) {
      modifier = "next";
    } else if (modifierWord.match(/diese/)) {
      modifier = "this";
    }
    return weekdays_1.createParsingComponentsAtWeekday(context.reference, offset, modifier);
  }
}
exports.default = DEWeekdayParser;

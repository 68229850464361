"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const constants_1 = require("../constants");
const results_1 = require("../../../results");
const AbstractParserWithWordBoundary_1 = require("../../../common/parsers/AbstractParserWithWordBoundary");
class NLTimeUnitWithinFormatParser extends AbstractParserWithWordBoundary_1.AbstractParserWithWordBoundaryChecking {
  innerPattern() {
    return new RegExp(`(?:binnen|in|binnen de|voor)\\s*` + "(" + constants_1.TIME_UNITS_PATTERN + ")" + `(?=\\W|$)`, "i");
  }
  innerExtract(context, match) {
    const timeUnits = constants_1.parseTimeUnits(match[1]);
    return results_1.ParsingComponents.createRelativeFromReference(context.reference, timeUnits);
  }
}
exports.default = NLTimeUnitWithinFormatParser;

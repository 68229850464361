<div class="flex flex-row items-center justify-between py-2">
  Which stops did you have detention at?
  <div class="flex-row items-center justify-between">
    <mat-selection-list [formControl]="stopsControl">
      @for (stop of load.stops; track stop.id) {
      <mat-list-option [value]="stop">
        {{ stop.sequence }} - {{ stop.address }} - {{ stop.facilityName }}
      </mat-list-option>
      }
    </mat-selection-list>
  </div>
  <button color="primary" mat-raised-button (click)="disputeDetention($event)">Dispute Detention Charges</button>
</div>
@if (responseGenerated) {
<div class="text-orange-500 text-lg">
  @for (response of generatedResponses; track $index) {
  {{ response }}
  }
</div>
}

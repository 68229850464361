var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
import { purry } from './purry';
export function sort() {
  return purry(_sort, arguments);
}
function _sort(items, cmp) {
  var ret = __spreadArray([], items, true);
  ret.sort(cmp);
  return ret;
}
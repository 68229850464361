import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadRateConPaymentStatus } from '../../global-types';

@Component({
  selector: 'td-load-ratecon-payment-status',
  templateUrl: './load-ratecon-payment-status.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LoadRateconPaymentStatusComponent {
  @Input() public loadRateconPaymentStatus: LoadRateConPaymentStatus;
  @Input() public brokerage: 'candlestick' | 'bainbridge' | 'bainbridge_canada' | 'lohi' | 'Brokerage';

  constructor() {}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distinct',
  standalone: false,
})
export class DistinctPipe implements PipeTransform {
  public transform<T>(items: T[], distinctOnProperty?: keyof T): T[] {
    if (!items) {
      return items;
    }

    if (!distinctOnProperty) {
      return [...new Set(items)];
    }

    const distinctItems = new Set<T>();
    const foundValues = new Set<any>();
    for (const item of items) {
      if (foundValues.has(item[distinctOnProperty])) {
        continue;
      }

      distinctItems.add(item);
      foundValues.add(item[distinctOnProperty]);
    }

    return [...distinctItems];
  }
}

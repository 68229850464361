import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { LohiLoad } from '../../../../global-types';
import { LoadBillingService } from '../../../../services/load-billing.service';
import { MatSelect } from '@angular/material/select';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'td-load-billing-stop-type-dispute',
  templateUrl: './load-billing-stop-type-dispute.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LoadBillingStopTypeDisputeComponent {
  @Input() public load: LohiLoad;
  @Input() public oldRate: number;
  public loadType: string;
  public stopTypePromopt: string;
  public facilityControl = new FormControl();
  constructor(private lbs: LoadBillingService, private cdr: ChangeDetectorRef) {}
  public async disputeStopType(event: Event) {
    const selectedFacility = this.facilityControl.value;
    const resp = await this.lbs.runDisputeStopType(this.load.id, selectedFacility, this.oldRate);
    if (!resp['wasLive']) {
      this.stopTypePromopt = `We could not verify that you live loaded or unloaded as you were only there for ${resp['timeAtStop']} min. Dispute has been rejected.`;
    } else {
      this.stopTypePromopt = `Stop has been changed to live. New rate is $${resp['newRate'] / 100}`;
    }
    this.cdr.detectChanges();
  }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BasicUser } from '../../global-types';

@Component({
  selector: 'td-company-info',
  templateUrl: './company-info.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CompanyInfoComponent {
  @Input() public companyId: string;
  @Input() public companyName: string;
  @Input() public companyMCNumber: string;
  @Input() public companyDOTNumber: string;
  @Input() public companyAdmins: BasicUser[];

  constructor() {}
}

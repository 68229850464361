@if (loadStarted$ | async) { @if (pickupTimezone$ | async; as timezone) {
<div class="flex h-screen overflow-hidden">
  <div style="width: 600px" class="flex h-screen" id="mouseMove" (mousemove)="highlightWaypoint($event)">
    <div style="width: 200px" class="h-screen relative">
      @for (staleWaypoint of staleWaypointRegions$ | async; track staleWaypoint.startPercent) {
      <div
        class="h-2 absolute pointer-events-none flex w-1/12 text-sm bg-red-500 right-0"
        [style.top.%]="staleWaypoint.startPercent"
        [style.height.%]="staleWaypoint.heightPercent"
      ></div>
      } @for (eventGroup of eventsGrouped$ | async; track eventGroup.locationPercent) {
      <div class="absolute" [style.top.%]="eventGroup.locationPercent" [style.right.px]="eventGroup.rightPx">
        <ng-template #tooltipContent>
          @for (shiftEvent of eventGroup.shiftEvents; track shiftEvent.time) {
          <div class="flex items-center even:bg-gray-100 dark:even:bg-gray-900">
            <div class="w-4 flex items-center justify-center">
              <mat-icon class="cursor-default w-4 {{ shiftEvent.iconClass }}" style="transform: scale(0.5)"
                >{{ shiftEvent.iconName }}
              </mat-icon>
            </div>
            <div class="w-8 text-left">{{ shiftEvent.time | inTimezone: timezone:'h:mma' }}</div>
            <div class="flex-1 text-left break-words">{{ shiftEvent.message }}</div>
          </div>
          }
        </ng-template>
        <div
          [tooltip]="tooltipContent"
          [options]="{ contentType: 'template' }"
          placement="right"
          class="cursor-default grid grid-cols-2 grid-rows-2"
        >
          @for (icon of eventGroup.icons; track icon.name) {
          <div class="flex items-center justify-center w-4">
            <mat-icon class="{{ icon.className }}" style="transform: scale(0.7)">{{ icon.name }}</mat-icon>
          </div>
          }
        </div>
      </div>
      } @for (hourMarker of hourMarkers$ | async; track hourMarker.locationPercent) {
      <div
        class="h-2 absolute pointer-events-none flex w-full text-sm"
        style="line-height: 16px"
        style.top="calc({{ hourMarker.locationPercent }}% - 8px)"
      >
        <div class="text-center text-gray-500" style="width: 175px">
          {{ hourMarker.time | inTimezone: timezone }}<br />
        </div>
        <div class="flex-1 flex flex-col">
          <div class="h-1 flex-1 border-b border-gray-300"></div>
          <div class="h-1"></div>
        </div>
      </div>
      } @if (activeWaypoint$ | async; as activeWaypoint) {
      <div
        class="h-8 absolute pointer-events-none flex w-full text-sm"
        style="line-height: 16px"
        style.top="calc({{ activeWaypoint.locationPercent }}% - 32px)"
      >
        <div class="text-center flex flex-col" style="width: 176px">
          <div class="h-2 bg-yellow-500" [class.bg-gray-500]="activeWaypoint.isFuture">
            Last Waypoint {{ activeWaypoint.waypoint.clientCreatedAt | inTimezone: timezone:'h:mma' }}
            @if (!activeWaypoint.isFuture && activeWaypoint.staleWaypointRegion) {
            <span>🚨</span>
            }
          </div>
          <div class="h-4 border-r border-yellow-500">
            @if (activeWaypoint.staleWaypointRegion) {
            <div class="w-full h-full bg-red-500">
              <div>App not used from</div>
              <div>
                {{ activeWaypoint.staleWaypointRegion.startAt | inTimezone: timezone:'h:mma' }} -
                {{ activeWaypoint.staleWaypointRegion.endAt | inTimezone: timezone:'h:mma' }}
              </div>
            </div>
            }
          </div>
          <div class="h-2 bg-yellow-500" [class.bg-gray-500]="activeWaypoint.isFuture">
            Actual Time {{ activeWaypoint.actualTime | inTimezone: timezone:'h:mma' }}
          </div>
        </div>
        <div class="flex-1 flex flex-col">
          <div class="h-4 flex-1 border-b-2 border-yellow-500"></div>
          <div class="h-4"></div>
        </div>
      </div>
      }
    </div>
    <div class="relative flex-1">
      @if (loadBox$ | async; as box) {
      <div
        class="absolute border-2 border-blue-500 pl-1 pr-1 left-0 right-0 font-bold"
        [style.border-bottom-style]="box.endIsFake ? 'dashed' : 'solid'"
        [style.border-top-style]="box.startIsFake ? 'dashed' : 'solid'"
        [style.top.%]="box.startPercent"
        [style.height.%]="box.heightPercent"
      >
        <div class="relative -top-3">{{ box.name }}</div>
        @if (!box.endIsFake) {
        <div class="absolute -bottom-3">
          Completed at {{ box.data.completedAt || box.data.completedAt | inTimezone: timezone:'h:mma' }} by
          {{ box.data.completedByName | dashIfNothing }}
        </div>
        }
      </div>
      } @for (box of stopBoxes$ | async; track box.name) {
      <div
        class="absolute border-2 flex flex-col pl-1 pr-1 overflow-hidden left-3 right-3 bg-white dark:bg-[#2c2c2c]"
        [ngClass]="box.data.type === 'at_yard' ? 'border-orange-600' : 'border-green-500'"
        [style.border-bottom-style]="box.endIsFake ? 'dashed' : 'solid'"
        [style.border-top-style]="box.startIsFake ? 'dashed' : 'solid'"
        [style.top.%]="box.startPercent"
        [style.height.%]="box.heightPercent"
      >
        @if (box.data.type !== 'at_yard') {
        <div class="font-bold">{{ box.name }} ({{ box.data.name }})</div>
        } @if (box.data.type === 'at_yard') {
        <div class="font-bold">{{ box.name }}</div>
        } @if (!box.startIsFake) {
        <div>Arrived at {{ box.data.arrivedAt | inTimezone: timezone:'h:mma' }}</div>
        }
        <div class="absolute bottom-0">
          @if (box.data.manuallyCompleted) {
          <div class="text-red-500">
            🙋Manually Completed @if (!box.endIsFake) { at
            {{ box.data.exitedAt || box.data.completedAt | inTimezone: timezone:'h:mma' }}
            }
          </div>
          } @if (box.data.manuallyCompleted === false) {
          <div>
            🤖 Automatically Completed @if (!box.endIsFake) { at
            {{ box.data.exitedAt || box.data.completedAt | inTimezone: timezone:'h:mma' }}
            }
          </div>
          }
        </div>
      </div>
      } @for (box of eventBoxes$ | async; track box.name) {
      <div
        class="absolute border-2 border-yellow-500 flex flex-col pl-1 pr-1 left-3 right-3"
        [style.border-bottom-style]="box.endIsFake ? 'dashed' : 'solid'"
        [style.border-top-style]="box.startIsFake ? 'dashed' : 'solid'"
        [style.top.%]="box.startPercent"
        [style.height.%]="box.heightPercent"
      >
        <div class="font-bold">{{ box.name }}: {{ box.data.date | inTimezone: timezone:'h:mma' }}</div>
      </div>
      } @for (geofencePause of geofencePauseRegions$ | async; track geofencePause.startPercent) {
      <div
        matTooltip="Geofence Pause"
        class="absolute flex w-2 left-[2px]"
        style="
          background-image: linear-gradient(
            45deg,
            #ffb101 25%,
            #000000 25%,
            #000000 50%,
            #ffb101 50%,
            #ffb101 75%,
            #000000 75%,
            #000000 100%
          );
          background-size: 56.57px 56.57px;
        "
        [style.top.%]="geofencePause.startPercent"
        [style.height.%]="geofencePause.heightPercent"
      ></div>
      }
    </div>
  </div>
  <div class="flex flex-1 bg-blue-500 h-full">
    <td-load-timeline-map
      class="h-full w-full"
      [mapData]="loadHistoryMap$ | async"
      [activeWaypoint]="(activeWaypoint$ | async)?.waypoint"
      [showPolygon]="true"
    ></td-load-timeline-map>
  </div>
</div>
} } @else {
<div class="text-xl">Load Not Started Yet</div>
}

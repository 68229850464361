<div mat-dialog-title>Mark Load As Spot Load?</div>
@if (ufgLoadStatus$ | async; as ufgLoadStatusSummary) {
<div mat-dialog-content>
  @if (ufgLoadStatusSummary.warnings?.length) {
  <div class="border border-red-400 rounded-l p-3">
    @for (warning of ufgLoadStatusSummary.warnings; track warning) {
    <div class="text-lg">
      {{ warning }}
    </div>
    }
  </div>
  } @if (!ufgLoadStatusSummary.canBeMarkedUFG) {
  <div class="text-red-500 text-xl mt-3">This load cannot be marked as a spot load.</div>
  }
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">Nevermind</button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="!ufgLoadStatusSummary.canBeMarkedUFG"
    (click)="markLoadAsSpotLoad()"
  >
    Mark as Spot Load
  </button>
</div>
}

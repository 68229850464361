import { Component, ChangeDetectionStrategy, OnDestroy, Input, HostListener } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, shareReplay, Subject, takeUntil } from 'rxjs';
import { shareReplayComponentConfig } from '../../constants';

interface Sortable {
  sortByHeader$: Observable<string>;
  sortAscending$: Observable<boolean>;
  setSortingHeader(header: any);
}

@Component({
  selector: 'td-sort-indicator',
  templateUrl: './sort-indicator.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SortIndicatorComponent implements OnDestroy {
  private destroy$$ = new Subject<void>();
  private headerName$$ = new BehaviorSubject<string>(null);
  private activeColumn$$ = new BehaviorSubject<boolean>(false);
  public activeColumn$: Observable<boolean> = this.activeColumn$$.pipe(shareReplay(shareReplayComponentConfig));
  private reversed$$ = new BehaviorSubject<boolean>(false);
  public reversed$: Observable<boolean> = this.reversed$$.pipe(shareReplay(shareReplayComponentConfig));

  private sortable: Sortable;

  @Input() public set activeColumn(bool: boolean) {
    this.activeColumn$$.next(bool);
  }
  @Input() public set reversed(bool: boolean) {
    this.reversed$$.next(bool);
  }
  @Input() public set sortingObservable(sortable: Sortable) {
    this.sortable = sortable;
    this.setupSortable();
  }
  @Input() public set headerName(name: string) {
    this.headerName$$.next(name);
  }

  constructor() {}

  public ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  public toggleSort(): void {
    if (!this.sortable) {
      return;
    }
    this.sortable.setSortingHeader(this.headerName$$.value);
  }

  private setupSortable() {
    this.destroy$$.next();

    this.sortable.sortAscending$.pipe(takeUntil(this.destroy$$)).subscribe((bool) => {
      this.reversed = !bool;
    });

    combineLatest([this.sortable.sortByHeader$, this.headerName$$])
      .pipe(takeUntil(this.destroy$$))
      .subscribe(([sortByHeader, headerName]) => {
        if (!headerName) {
          this.activeColumn$$.next(false);
        }
        this.activeColumn$$.next(sortByHeader === headerName);
      });
  }
}

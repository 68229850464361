import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { ConstantsService, PreferenceListItem, TrailerTypeAttributeItem } from '../../../services/constants.service';
import { DriverEquipmentPreferences } from '../../../global-types';
import { NetworkableDestroyableComponent } from '../../networkable-destroyable.component';

@Component({
  selector: 'td-driver-profile-edit-equipment-preferences',
  templateUrl: './driver-profile-edit-equipment-preferences.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DriverProfileEditEquipmentPreferencesComponent extends NetworkableDestroyableComponent {
  public trailerTypes$: Observable<TrailerTypeAttributeItem[]>;
  public trailerTypeAttributes$: Observable<PreferenceListItem[]>;
  public additionalEquipmentTypes$: Observable<PreferenceListItem[]>;
  private isSetup = false;

  @Input() public networkActive = false;

  @Input()
  public set prefs(prefs: DriverEquipmentPreferences) {
    if (!this.isSetup) {
      this.setupForm(prefs);
      this.isSetup = true;
    }
  }

  @Output() public save = new EventEmitter<DriverEquipmentPreferences>();
  public formGroup: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private constantsService: ConstantsService) {
    super();

    this.trailerTypes$ = this.constantsService.trailerTypeAttributes$;
    this.formGroup = this.fb.group({
      tractorMake: [null],
      tractorModel: [null],
      tractorYear: [null],
      tractorNumber: [null],
      tractorVIN: [null],
      trailerMake: [null],
      trailerModel: [null],
      trailerYear: [null],
      trailerNumber: [null],
      trailerType: [null],
      trailerVIN: [null],
      trailerAttributeIDs: [null],
      additionalEquipmentIds: [null],
    });

    this.trailerTypes$ = this.constantsService.trailerTypeAttributes$;
    this.additionalEquipmentTypes$ = this.constantsService.additionalEquipmentTypes$;

    this.trailerTypeAttributes$ = combineLatest([
      this.trailerTypes$,
      this.formGroup.get('trailerType').valueChanges as Observable<string | null>,
    ]).pipe(
      map(([trailerTypes, currentTrailerType]) => {
        if (!trailerTypes || !currentTrailerType) {
          return [];
        }
        return trailerTypes.find((tt) => tt.type === currentTrailerType)?.choices || [];
      }),
    );
  }

  private setupForm(prefs: DriverEquipmentPreferences) {
    setTimeout(() => {
      this.formGroup.setValue(prefs);
    }, 1);
  }

  public doSave() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    const value: DriverEquipmentPreferences = this.formGroup.getRawValue();
    this.save.next(value);
  }
}

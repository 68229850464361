"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const years_1 = require("../../../calculation/years");
const constants_1 = require("../constants");
const constants_2 = require("../constants");
const constants_3 = require("../constants");
const pattern_1 = require("../../../utils/pattern");
const AbstractParserWithWordBoundary_1 = require("../../../common/parsers/AbstractParserWithWordBoundary");
const PATTERN = new RegExp(`(${pattern_1.matchAnyPattern(constants_1.MONTH_DICTIONARY)})` + "(?:-|/|\\s*,?\\s*)" + `(${constants_2.ORDINAL_NUMBER_PATTERN})(?!\\s*(?:am|pm))\\s*` + "(?:" + "(?:to|\\-)\\s*" + `(${constants_2.ORDINAL_NUMBER_PATTERN})\\s*` + ")?" + "(?:" + "(?:-|/|\\s*,?\\s*)" + `(${constants_3.YEAR_PATTERN})` + ")?" + "(?=\\W|$)(?!\\:\\d)", "i");
const MONTH_NAME_GROUP = 1;
const DATE_GROUP = 2;
const DATE_TO_GROUP = 3;
const YEAR_GROUP = 4;
class ENMonthNameMiddleEndianParser extends AbstractParserWithWordBoundary_1.AbstractParserWithWordBoundaryChecking {
  innerPattern() {
    return PATTERN;
  }
  innerExtract(context, match) {
    const month = constants_1.MONTH_DICTIONARY[match[MONTH_NAME_GROUP].toLowerCase()];
    const day = constants_2.parseOrdinalNumberPattern(match[DATE_GROUP]);
    if (day > 31) {
      return null;
    }
    const components = context.createParsingComponents({
      day: day,
      month: month
    });
    if (match[YEAR_GROUP]) {
      const year = constants_3.parseYear(match[YEAR_GROUP]);
      components.assign("year", year);
    } else {
      const year = years_1.findYearClosestToRef(context.refDate, day, month);
      components.imply("year", year);
    }
    if (!match[DATE_TO_GROUP]) {
      return components;
    }
    const endDate = constants_2.parseOrdinalNumberPattern(match[DATE_TO_GROUP]);
    const result = context.createParsingResult(match.index, match[0]);
    result.start = components;
    result.end = components.clone();
    result.end.assign("day", endDate);
    return result;
  }
}
exports.default = ENMonthNameMiddleEndianParser;

<div mat-dialog-title>
  <div>Approve Load for Invoicing</div>
  @if (networkActive$ | async) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  } @else {
  <div class="h-[4px]"></div>
  }
</div>
<div matDialogContent>
  @if (userInfo$ | async; as userInfo) {
  <div [formGroup]="form" class="my-2 flex flex-col gap-2">
    <mat-checkbox formControlName="allChargesUploaded"
      >I {{ userInfo.driverName }}, confirm that all detention, layover, and other charges are uploaded
    </mat-checkbox>
    <mat-checkbox formControlName="noAdditionalCharges"
      >I {{ userInfo.driverName }}, understand that I will not be able to add additional charges to this load after I
      have approved it
    </mat-checkbox>
    <mat-checkbox formControlName="willNotDisputePayout"
      >I {{ userInfo.driverName }}, confirm that the total payout of {{ data.payoutCents | currencyInPennies }} is the
      final amount that I expect from Load {{ data.loadId }} and I will not dispute this amount at a later time
    </mat-checkbox>
  </div>
  } @if (approvePrecheckResponse$ | async; as precheck) { @if (precheck.uploadFine?.hasFine &&
  precheck.uploadFine.hasPass) {
  <div class="border m-3 p-3 rounded-lg">
    <div class="font-bold text-xl text-red-500">This load has a Manual Processing Fee attached to it.</div>
    <div>Good news! You have a free pass that can be used to erase this fee.</div>
    <div class="flex items-center justify-center">
      <button mat-flat-button color="primary" [disabled]="networkActive$ | async" (click)="applyPass()">
        Apply Pass and Erase Fee
      </button>
    </div>
  </div>
  } }
</div>
<div mat-dialog-actions>
  <button mat-flat-button [mat-dialog-close]="false" color="warn">Nevermind</button>
  <button
    mat-flat-button
    [mat-dialog-close]="true"
    color="primary"
    [disabled]="(disabled$ | async) || (networkActive$ | async)"
  >
    Confirm Approval for Invoicing
  </button>
</div>

<div matDialogTitle class="text-lg">✨ EAP Insights</div>
@if (eapSummary$ | async; as summary) {
<div matDialogContent>
  @if (summary.chosenFacilityId !== eapStopFacilityId) {
  <div class="p-3 rounded bg-red-350 text-black">
    This is not the facility we recommended for this EAP. Below is what the AI recommends.
  </div>
  } @if (summary.bestFacility; as bestFac) {
  <div>
    <div>Best Facility</div>
    <div class="font-semibold">
      🌟️ {{ bestFac.facilityName }} 🌟 <span class="capitalize">(🎯 {{ bestFac.targetType }})</span>
    </div>
    <div class="font-semibold mb-2 text-lg">
      Total Trailers {{ bestFac.totalTrailerCount | number }} ({{ bestFac.emptyTrailerCount | number }} Empty)
    </div>
    <div class="flex gap-3 mb-2">
      @for (trailer of bestFac.trailers; track trailer.id) {
      <div class="p-3 border rounded">
        <div>
          <a class="font-semibold text-blue-500" routerLink="/drop_trailer/trailer/{{ trailer.id }}" target="_blank">{{
            trailer.name
          }}</a>
          <div><span class="font-semibold">Status:</span> {{ trailer.assetStatus | trailerStatus }}</div>
          <div><span class="font-semibold">Loaded Status:</span> {{ trailer.loadedStatus }}</div>
          <div><span class="font-semibold">Empty Probability:</span> {{ trailer.probabilityEmpty | percent }}</div>
        </div>
      </div>
      }
    </div>
    @if (bestFac.probResults; as probResults) {
    <div class="flex flex-col gap-2 my-3">
      <div class="font-semibold">Driver Insights</div>
      @if (probResults.detourMiles !== null) {
      <div>
        Detour Miles: <span class="font-semibold">{{ probResults.detourMiles | number }} mile(s)</span>
      </div>
      } @if (probResults.estimatedDurationMinutes !== null) {
      <div>
        ETA To Pickup: <span class="font-semibold">{{ probResults.estimatedDurationMinutes | number }}</span> minutes
      </div>
      }
    </div>
    }
  </div>
  } @else {
  <div>
    There are no recommended facilities for an EAP, this usually means all empties are spoken for and if you claim one
    of them you will <span class="font-semibold text-red-400">ruin autodispatch</span>
  </div>
  }
  <mat-form-field class="w-full">
    <mat-label>View Other Facility Stats</mat-label>
    <mat-select [formControl]="selectedFacilityFC">
      <mat-option>
        <ngx-mat-select-search
          placeholderLabel="Search"
          noEntriesFoundLabel="No match found"
          [formControl]="facilitySearchFC"
        ></ngx-mat-select-search>
      </mat-option>
      @for (fac of filteredFacilities$ | async; track fac.id) {
      <mat-option [value]="fac">
        {{ fac.facilityName }}&nbsp;<span class="capitalize">(🎯 {{ fac.targetType }})</span> - Total Trailers
        {{ fac.totalTrailerCount | number }}
        @if (fac.targetType === 'empty') { (<span [class.text-red-500]="fac.emptyTrailerCount <= fac.targetCount"
          >{{ fac.emptyTrailerCount | number }}
          Empty
        </span>
        / {{ fac.targetCount | number }} Target) } @else { ({{ fac.emptyTrailerCount | number }} Empty) }
      </mat-option>
      }
    </mat-select>
  </mat-form-field>
  @if (selectedFacility; as selectedFac) {
  <div class="mt-3">
    <div>Your Selection</div>
    <div class="font-semibold">
      {{ selectedFac.facilityName }} (<span class="capitalize">🎯 {{ selectedFac.targetType }})</span>
    </div>
    <div class="font-semibold mb-2 text-lg">
      Total Trailers {{ selectedFacility.totalTrailerCount | number }} ({{
        selectedFacility.emptyTrailerCount | number
      }}
      Empty)
    </div>
    @if (selectedFac.id !== summary.bestFacility.id && selectedFac.probResults; as probResults) {
    <div class="flex flex-col gap-2 my-3">
      <div class="font-semibold">Reason For Exclusion</div>
      <div>
        Message: <span class="font-semibold">{{ probResults.probabilityReason }}</span>
      </div>
      <div>
        Details: <span class="font-semibold">{{ probResults.facilityExclusionReason }}</span>
      </div>
    </div>
    }
    <div class="flex gap-3 mb-2">
      @for (trailer of selectedFacility.trailers; track trailer.id) {
      <div class="p-3 border rounded">
        <div>
          <a class="font-semibold text-blue-500" routerLink="/drop_trailer/trailer/{{ trailer.id }}" target="_blank">{{
            trailer.name
          }}</a>
          <div><span class="font-semibold">Status:</span> {{ trailer.assetStatus | trailerStatus }}</div>
          <div><span class="font-semibold">Loaded Status:</span> {{ trailer.loadedStatus }}</div>
          <div><span class="font-semibold">Empty Probability:</span> {{ trailer.probabilityEmpty | percent }}</div>
        </div>
      </div>
      }
    </div>
    @if (selectedFac.probResults; as probResults) {
    <div class="flex flex-col gap-2 my-3">
      <div class="font-semibold">Driver Insights</div>
      @if (probResults.detourMiles !== null) {
      <div>
        Detour Miles: <span class="font-semibold">{{ probResults.detourMiles | number }} mile(s)</span>
      </div>
      } @if (probResults.estimatedDurationMinutes !== null) {
      <div>
        ETA To Pickup: <span class="font-semibold">{{ probResults.estimatedDurationMinutes | number }}</span> minutes
      </div>
      }
    </div>
    }
  </div>
  }
</div>
}
<div matDialogActions>
  <div></div>
  <button mat-flat-button color="primary" matDialogClose>Close</button>
</div>

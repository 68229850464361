import { Pipe, PipeTransform } from '@angular/core';
import { NoSafeUrlPipe } from './no-safe-url.pipe';
import { SafeUrl } from '@angular/platform-browser';
import { AuthService, LoHiUserInfo } from '../services/auth.service';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { InternalService } from '../services/internal.service';

@Pipe({
  name: 'dialpad',
  standalone: false,
})
export class DialpadPipe implements PipeTransform {
  private userInfo$: Observable<LoHiUserInfo>;
  private isInternal$: Observable<boolean>;
  constructor(
    private unsafeURL: NoSafeUrlPipe,
    private authService: AuthService,
    private internalService: InternalService,
  ) {
    this.userInfo$ = this.authService.userInfo$;
    this.isInternal$ = this.internalService.isInternalShared$;
  }

  public transform(phoneNumber: string, metadata: any): Observable<string | SafeUrl> {
    return combineLatest([this.userInfo$, this.isInternal$]).pipe(
      map(([v, isInternal]) => {
        if (!isInternal) {
          return `tel:${phoneNumber}`;
        }
        let obj = {
          ...metadata,
          dispatcherId: v.id,
        };
        return this.unsafeURL.transform(
          phoneNumber + '?customData=' + encodeURIComponent(JSON.stringify(obj)),
          'dialpad://',
        );
      }),
    );
  }
}

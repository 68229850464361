import { Pipe, PipeTransform } from '@angular/core';
import { AscensionLevelName } from '../global-types';

/* eslint-disable @typescript-eslint/naming-convention */
const ascensionLevelToIconSrc = {
  rank_0: 'assets/icons/loyalty/rookie.svg',
  rank_1: 'assets/icons/loyalty/apprentice.svg',
  rank_2: 'assets/icons/loyalty/professional.svg',
  rank_3: 'assets/icons/loyalty/road-warrior.svg',
  rank_4: 'assets/icons/loyalty/trailblazer.svg',
  rank_5: 'assets/icons/loyalty/highway-hero.svg',
  rank_6: 'assets/icons/loyalty/big-rig-baron.svg',
  rank_7: 'assets/icons/loyalty/transport-titan.svg',
  rank_8: 'assets/icons/loyalty/living-legend.svg',
};
/* eslint-enable @typescript-eslint/naming-convention */

@Pipe({
  name: 'ascensionLevelIconSource',
  standalone: false,
})
export class AscensionLevelIconSourcePipe implements PipeTransform {
  public transform(value: AscensionLevelName): string {
    if (!value) {
      return '';
    }

    return ascensionLevelToIconSrc[value];
  }
}

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseTimeUnits = exports.TIME_UNITS_PATTERN = exports.parseYear = exports.YEAR_PATTERN = exports.parseOrdinalNumberPattern = exports.ORDINAL_NUMBER_PATTERN = exports.parseNumberPattern = exports.NUMBER_PATTERN = exports.TIME_UNIT_DICTIONARY = exports.ORDINAL_WORD_DICTIONARY = exports.INTEGER_WORD_DICTIONARY = exports.MONTH_DICTIONARY = exports.WEEKDAY_DICTIONARY = void 0;
const pattern_1 = require("../../utils/pattern");
const years_1 = require("../../calculation/years");
exports.WEEKDAY_DICTIONARY = {
  zondag: 0,
  zon: 0,
  "zon.": 0,
  zo: 0,
  "zo.": 0,
  maandag: 1,
  ma: 1,
  "ma.": 1,
  dinsdag: 2,
  din: 2,
  "din.": 2,
  di: 2,
  "di.": 2,
  woensdag: 3,
  woe: 3,
  "woe.": 3,
  wo: 3,
  "wo.": 3,
  donderdag: 4,
  dond: 4,
  "dond.": 4,
  do: 4,
  "do.": 4,
  vrijdag: 5,
  vrij: 5,
  "vrij.": 5,
  vr: 5,
  "vr.": 5,
  zaterdag: 6,
  zat: 6,
  "zat.": 6,
  "za": 6,
  "za.": 6
};
exports.MONTH_DICTIONARY = {
  januari: 1,
  jan: 1,
  "jan.": 1,
  februari: 2,
  feb: 2,
  "feb.": 2,
  maart: 3,
  mar: 3,
  "mar.": 3,
  april: 4,
  apr: 4,
  "apr.": 4,
  mei: 5,
  juni: 6,
  jun: 6,
  "jun.": 6,
  juli: 7,
  jul: 7,
  "jul.": 7,
  augustus: 8,
  aug: 8,
  "aug.": 8,
  september: 9,
  sep: 9,
  "sep.": 9,
  sept: 9,
  "sept.": 9,
  oktober: 10,
  okt: 10,
  "okt.": 10,
  november: 11,
  nov: 11,
  "nov.": 11,
  december: 12,
  dec: 12,
  "dec.": 12
};
exports.INTEGER_WORD_DICTIONARY = {
  een: 1,
  twee: 2,
  drie: 3,
  vier: 4,
  vijf: 5,
  zes: 6,
  zeven: 7,
  acht: 8,
  negen: 9,
  tien: 10,
  elf: 11,
  twaalf: 12
};
exports.ORDINAL_WORD_DICTIONARY = {
  eerste: 1,
  tweede: 2,
  derde: 3,
  vierde: 4,
  vijfde: 5,
  zesde: 6,
  zevende: 7,
  achtste: 8,
  negende: 9,
  tiende: 10,
  elfde: 11,
  twaalfde: 12,
  dertiende: 13,
  veertiende: 14,
  vijftiende: 15,
  zestiende: 16,
  zeventiende: 17,
  achttiende: 18,
  negentiende: 19,
  twintigste: 20,
  "eenentwintigste": 21,
  "tweeëntwintigste": 22,
  "drieentwintigste": 23,
  "vierentwintigste": 24,
  "vijfentwintigste": 25,
  "zesentwintigste": 26,
  "zevenentwintigste": 27,
  "achtentwintig": 28,
  "negenentwintig": 29,
  "dertigste": 30,
  "eenendertigste": 31
};
exports.TIME_UNIT_DICTIONARY = {
  sec: "second",
  second: "second",
  seconden: "second",
  min: "minute",
  mins: "minute",
  minute: "minute",
  minuut: "minute",
  minuten: "minute",
  minuutje: "minute",
  h: "hour",
  hr: "hour",
  hrs: "hour",
  uur: "hour",
  u: "hour",
  uren: "hour",
  dag: "d",
  dagen: "d",
  week: "week",
  weken: "week",
  maand: "month",
  maanden: "month",
  jaar: "year",
  jr: "year",
  jaren: "year"
};
exports.NUMBER_PATTERN = `(?:${pattern_1.matchAnyPattern(exports.INTEGER_WORD_DICTIONARY)}|[0-9]+|[0-9]+[\\.,][0-9]+|halve?|half|paar)`;
function parseNumberPattern(match) {
  const num = match.toLowerCase();
  if (exports.INTEGER_WORD_DICTIONARY[num] !== undefined) {
    return exports.INTEGER_WORD_DICTIONARY[num];
  } else if (num === "paar") {
    return 2;
  } else if (num === "half" || num.match(/halve?/)) {
    return 0.5;
  }
  return parseFloat(num.replace(",", "."));
}
exports.parseNumberPattern = parseNumberPattern;
exports.ORDINAL_NUMBER_PATTERN = `(?:${pattern_1.matchAnyPattern(exports.ORDINAL_WORD_DICTIONARY)}|[0-9]{1,2}(?:ste|de)?)`;
function parseOrdinalNumberPattern(match) {
  let num = match.toLowerCase();
  if (exports.ORDINAL_WORD_DICTIONARY[num] !== undefined) {
    return exports.ORDINAL_WORD_DICTIONARY[num];
  }
  num = num.replace(/(?:ste|de)$/i, "");
  return parseInt(num);
}
exports.parseOrdinalNumberPattern = parseOrdinalNumberPattern;
exports.YEAR_PATTERN = `(?:[1-9][0-9]{0,3}\\s*(?:voor Christus|na Christus)|[1-2][0-9]{3}|[5-9][0-9])`;
function parseYear(match) {
  if (/voor Christus/i.test(match)) {
    match = match.replace(/voor Christus/i, "");
    return -parseInt(match);
  }
  if (/na Christus/i.test(match)) {
    match = match.replace(/na Christus/i, "");
    return parseInt(match);
  }
  const rawYearNumber = parseInt(match);
  return years_1.findMostLikelyADYear(rawYearNumber);
}
exports.parseYear = parseYear;
const SINGLE_TIME_UNIT_PATTERN = `(${exports.NUMBER_PATTERN})\\s{0,5}(${pattern_1.matchAnyPattern(exports.TIME_UNIT_DICTIONARY)})\\s{0,5}`;
const SINGLE_TIME_UNIT_REGEX = new RegExp(SINGLE_TIME_UNIT_PATTERN, "i");
exports.TIME_UNITS_PATTERN = pattern_1.repeatedTimeunitPattern(`(?:(?:binnen|in)\\s*)?`, SINGLE_TIME_UNIT_PATTERN);
function parseTimeUnits(timeunitText) {
  const fragments = {};
  let remainingText = timeunitText;
  let match = SINGLE_TIME_UNIT_REGEX.exec(remainingText);
  while (match) {
    collectDateTimeFragment(fragments, match);
    remainingText = remainingText.substring(match[0].length);
    match = SINGLE_TIME_UNIT_REGEX.exec(remainingText);
  }
  return fragments;
}
exports.parseTimeUnits = parseTimeUnits;
function collectDateTimeFragment(fragments, match) {
  const num = parseNumberPattern(match[1]);
  const unit = exports.TIME_UNIT_DICTIONARY[match[2].toLowerCase()];
  fragments[unit] = num;
}

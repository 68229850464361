"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBackwardDaysToWeekday = exports.getDaysForwardToWeekday = exports.getDaysToWeekdayClosest = exports.getDaysToWeekday = exports.createParsingComponentsAtWeekday = void 0;
const index_1 = require("../../index");
const results_1 = require("../../results");
const timeunits_1 = require("../../utils/timeunits");
function createParsingComponentsAtWeekday(reference, weekday, modifier) {
  const refDate = reference.getDateWithAdjustedTimezone();
  const daysToWeekday = getDaysToWeekday(refDate, weekday, modifier);
  let components = new results_1.ParsingComponents(reference);
  components = timeunits_1.addImpliedTimeUnits(components, {
    "day": daysToWeekday
  });
  components.assign("weekday", weekday);
  return components;
}
exports.createParsingComponentsAtWeekday = createParsingComponentsAtWeekday;
function getDaysToWeekday(refDate, weekday, modifier) {
  const refWeekday = refDate.getDay();
  switch (modifier) {
    case "this":
      return getDaysForwardToWeekday(refDate, weekday);
    case "last":
      return getBackwardDaysToWeekday(refDate, weekday);
    case "next":
      if (refWeekday == index_1.Weekday.SUNDAY) {
        return weekday == index_1.Weekday.SUNDAY ? 7 : weekday;
      }
      if (refWeekday == index_1.Weekday.SATURDAY) {
        if (weekday == index_1.Weekday.SATURDAY) return 7;
        if (weekday == index_1.Weekday.SUNDAY) return 8;
        return 1 + weekday;
      }
      if (weekday < refWeekday && weekday != index_1.Weekday.SUNDAY) {
        return getDaysForwardToWeekday(refDate, weekday);
      } else {
        return getDaysForwardToWeekday(refDate, weekday) + 7;
      }
  }
  return getDaysToWeekdayClosest(refDate, weekday);
}
exports.getDaysToWeekday = getDaysToWeekday;
function getDaysToWeekdayClosest(refDate, weekday) {
  const backward = getBackwardDaysToWeekday(refDate, weekday);
  const forward = getDaysForwardToWeekday(refDate, weekday);
  return forward < -backward ? forward : backward;
}
exports.getDaysToWeekdayClosest = getDaysToWeekdayClosest;
function getDaysForwardToWeekday(refDate, weekday) {
  const refWeekday = refDate.getDay();
  let forwardCount = weekday - refWeekday;
  if (forwardCount < 0) {
    forwardCount += 7;
  }
  return forwardCount;
}
exports.getDaysForwardToWeekday = getDaysForwardToWeekday;
function getBackwardDaysToWeekday(refDate, weekday) {
  const refWeekday = refDate.getDay();
  let backwardCount = weekday - refWeekday;
  if (backwardCount >= 0) {
    backwardCount -= 7;
  }
  return backwardCount;
}
exports.getBackwardDaysToWeekday = getBackwardDaysToWeekday;

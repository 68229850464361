<div class="p-1 border border-yellow-400 rounded">
  <div class="text-lg">✨ EAP Insights</div>
  @if ((canAddEap$ | async) === false) {
  <div>
    <div class="text-red-400 font-semibold text-center">You do not have permission to modify EAPs</div>
  </div>
  } @if (eapSummary$ | async; as summary) {
  <div>
    @if (selectedIsBest$ | async) {
    <div class="py-2 w-full text-center">✅ You are selecting the best facility for this EAP ✅</div>
    } @else { @if (selectedFacility$ | async; as selectedFacility) {
    <div
      [class.bg-red-350]="
        selectedFacility.isBanned ||
        !selectedFacility.isOpen ||
        selectedFacility.probResults.recentFailedEAP ||
        selectedFacility.totalEmptiesAvailable < 1
      "
    >
      <div class="font-semibold text-center text-black">You are not selecting the recommended EAP facility</div>
      <div class="text-center">{{ selectedFacility.facilityName }}</div>
      @if (selectedFacility.isBanned) {
      <div>🚨 This driver is banned from this facility</div>
      } @if (!selectedFacility.isOpen) {
      <div>⏰ This facility is closed</div>
      } @if (selectedFacility.probResults.recentFailedEAP) {
      <div>🚨 This facility has recently failed an EAP.</div>
      }
      <ng-container *ngTemplateOutlet="theMath; context: { facility: selectedFacility }"></ng-container>
    </div>
    } @else { @if (selectedFacilityId$ | async) {
    <div class="bg-red-350 dark:bg-red-700 rounded p-3 w-full text-center text-xl">
      👎 The currently facility is such a bad choice that we don't even have stats on it 👎
    </div>
    } } } @if (summary.bestFacility; as bestFacility) {
    <div class="text-center w-full">
      @if ((selectedIsBest$ | async) === false) {
      <div class="text-xl font-bold mt-2">Recommended Facility</div>
      <div>
        🌟 {{ bestFacility.facilityName }}
        <mat-icon class="inline cursor-pointer" (click)="copyToClipboard(bestFacility.facilityName)"
          >content_copy
        </mat-icon>
      </div>
      <ng-container *ngTemplateOutlet="theMath; context: { facility: bestFacility }"></ng-container>
      }
    </div>
    } @else {
    <div>
      There are no recommended facilities for an EAP, this usually means all empties are spoken for and if you claim one
      of them you will <span class="font-semibold text-red-400">ruin autodispatch</span>
    </div>
    }
  </div>
  }
</div>
<ng-template #theMath let-facility="facility">
  <div class="grid grid-cols-2 gap-y-1">
    <div class="text-left">Ready Empty Count</div>
    <div class="text-right font-mono">
      {{ facility.readyEmptyCount | number }}
    </div>
    <div class="text-left">Active EAP Count</div>
    <div class="text-right font-mono">- {{ facility.activeEAPCount | number }}</div>
    <div class="text-left">Empties Needed For Collection</div>
    <div class="text-right font-mono">- {{ facility.emptiesNeededForCollectionsCount | number }}</div>
    <div class="text-left">Active EAD Count</div>
    <div class="text-right font-mono">+ {{ facility.activeEADCount | number }}</div>
    <div class="flex items-center">
      @if (facility.totalEmptiesAvailable < 1) {
      <mat-icon color="warn">warning</mat-icon>&nbsp; } Total Empties Available
    </div>
    <div class="text-right font-mono border-t pt-1">
      {{ facility.totalEmptiesAvailable | number }}
    </div>
  </div>
</ng-template>

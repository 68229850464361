<div class="flex flex-row items-center justify-between py-2">
  <button color="primary" mat-raised-button class="ml-2" (click)="disputeLayover($event)">
    Dispute Layover Charges
  </button>
  @if (layoverPrompt) {
  <div class="flex flex-col text-lg text-orange-500 ml-1">
    {{ layoverPrompt }}
  </div>
  }
</div>

export function _reduceLazy(array, lazy, indexed) {
  return array.reduce(function (acc, item, index) {
    var result = indexed ? lazy(item, index, array) : lazy(item);
    if (result.hasMany === true) {
      acc.push.apply(acc, result.next);
    } else if (result.hasNext === true) {
      acc.push(result.next);
    }
    return acc;
  }, []);
}
<mat-form-field class="w-full" (click)="$event.stopPropagation()">
  <mat-label>Switch Company</mat-label>
  <mat-select #companySelect (opened)="searchInput.focus()" [formControl]="companyPicker" class="w-full">
    <mat-select-trigger>
      @if ((companies$ | async)?.[0]; as company) {
      {{ company.name }} ({{ company.id }}) @if (company.billingSubsidiary === 'lohi') { [OA] } @if
      (company.billingSubsidiary === '5F') { [OO] } @if (company.billingSubsidiary === 'none') { [Classic] } }
    </mat-select-trigger>
    <mat-form-field class="w-full p-1">
      <input
        type="text"
        matInput
        [formControl]="companySearch"
        autocomplete="off"
        #searchInput
        (keydown)="onKeyDown($event)"
      />
    </mat-form-field>
    @for (company of companies$ | async; track company.id; let i = $index) {
    <mat-option [value]="company.id"
      >@if (i >= 1 && i <= 9) {
      <span class="font-mono text-white dark:text-slate-900 bg-slate-900 dark:bg-gray-200 rounded-sm mr-1"
        >CTRL+{{ i }}</span
      >
      }
      {{ company.name }} ({{ company.id }})
      <span class="font-bold"
        >[@if (company.billingSubsidiary === 'lohi') { OA } @if (company.billingSubsidiary === '5F') { OO } @if
        (company.billingSubsidiary === 'none') { Classic }]</span
      ></mat-option
    >
    }
  </mat-select>
</mat-form-field>

<div mat-dialog-title>Update Work Phone</div>
<form [formGroup]="form">
  <div mat-dialog-content>
    <div class="flex flex-col lg:flex-row gap-2">
      <mat-form-field class="flex-1">
        <mat-label>Phone Number</mat-label>
        <ngx-mat-input-tel
          [preferredCountries]="['us']"
          [enablePlaceholder]="true"
          [enableSearch]="true"
          format="national"
          name="phoneNumber"
          placeholder=" "
          formControlName="phoneNumber"
          [required]="true"
        ></ngx-mat-input-tel>
      </mat-form-field>
      <mat-form-field class="flex-1">
        <mat-label>Extension</mat-label>
        <input matInput formControlName="phoneNumberExtension" autocomplete="off" />
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <div>
      <button mat-flat-button (click)="updateWorkPhone()" color="accent">Update Work Phone</button>
    </div>
  </div>
</form>

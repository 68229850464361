<mat-form-field class="w-full">
  <mat-icon matPrefix>search</mat-icon>
  <mat-label [ngClass]="addressSearch.touched && invalid ? 'text-red-500' : null">{{ label }}</mat-label>
  <input [formControl]="addressSearch" matInput autocomplete="off" [matAutocomplete]="auto" [required]="required" />
  @if (showHint) {
  <mat-hint>Autofills address and coordinate fields</mat-hint>
  } @if (enableClear && addressSearch.value) {
  <button matSuffix mat-icon-button aria-label="Clear" (click)="clearAddress()">
    <mat-icon>close</mat-icon>
  </button>
  }
</mat-form-field>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectAddress($event)">
  <div class="text-sm pl-2 p-1">Select an address to complete your search</div>
  @for (address of addresses$ | async; track address.id) {
  <mat-option [value]="address" style="height: unset; min-height: 50px; line-height: 24px; font-size: 14px">
    <div class="break-words whitespace-normal">
      {{ address.address.label }}
    </div>
  </mat-option>
  }
</mat-autocomplete>

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const index_1 = require("../../../index");
const AbstractParserWithWordBoundary_1 = require("../../../common/parsers/AbstractParserWithWordBoundary");
class FRCasualTimeParser extends AbstractParserWithWordBoundary_1.AbstractParserWithWordBoundaryChecking {
  innerPattern(context) {
    return /(cet?)?\s*(matin|soir|après-midi|aprem|a midi|à minuit)(?=\W|$)/i;
  }
  innerExtract(context, match) {
    const suffixLower = match[2].toLowerCase();
    const component = context.createParsingComponents();
    switch (suffixLower) {
      case "après-midi":
      case "aprem":
        component.imply("hour", 14);
        component.imply("minute", 0);
        component.imply("meridiem", index_1.Meridiem.PM);
        break;
      case "soir":
        component.imply("hour", 18);
        component.imply("minute", 0);
        component.imply("meridiem", index_1.Meridiem.PM);
        break;
      case "matin":
        component.imply("hour", 8);
        component.imply("minute", 0);
        component.imply("meridiem", index_1.Meridiem.AM);
        break;
      case "a midi":
        component.imply("hour", 12);
        component.imply("minute", 0);
        component.imply("meridiem", index_1.Meridiem.AM);
        break;
      case "à minuit":
        component.imply("hour", 0);
        component.imply("meridiem", index_1.Meridiem.AM);
        break;
    }
    return component;
  }
}
exports.default = FRCasualTimeParser;

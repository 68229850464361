import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ConstantsService, PreferenceListItem } from '../services/constants.service';

@Pipe({
  name: 'additionalEquipment',
  standalone: false,
})
export class AdditionalEquipmentPipe implements PipeTransform {
  private map$: Observable<Record<number, PreferenceListItem>>;

  constructor(private constantsService: ConstantsService) {
    this.map$ = this.constantsService.dropTrailerChoicesMap$;
  }

  public transform(value: number): Observable<string> {
    return this.map$.pipe(
      map((cm) => {
        return cm[value]?.label || '';
      }),
    );
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { MappableComponent, SATELLITE } from '../mappable/mappable.component';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { GeoJSONSource, LngLatBounds } from 'mapbox-gl';
import { Feature, FeatureCollection, Polygon, Position } from 'geojson';
import { XYPoint } from '../../global-types';
import { centroid } from '@turf/centroid';
import { ActionEvent } from './dropdown.component';
import { BehaviorSubject, Observable } from 'rxjs';

interface PPolygon {
  id: string;
  coordinates: { x: number; y: number }[];
}

@Component({
  selector: 'td-polygonal-geofence-facility',
  templateUrl: './polygonal-geofence-facility.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PolygonalGeofenceFacilityComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: PolygonalGeofenceFacilityComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PolygonalGeofenceFacilityComponent extends MappableComponent implements ControlValueAccessor, Validator {
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};
  private _layers = { facility: true, docks: true };
  private _polygons: { facility: PPolygon; docks: PPolygon[] } = { facility: null, docks: [] };
  private _type: 'facility' | 'docks' | null;
  private _activeLayer: 'facility' | 'docks' | null = null;
  private _action: 'create' | 'edit' | null = null;
  private draw: MapboxDraw;
  // eslint-disable-next-line @smarttools/rxjs/finnish
  private _selectedPolygonsSubject = new BehaviorSubject<string[]>([]);
  // eslint-disable-next-line @smarttools/rxjs/finnish
  private _selectedPointsSubject = new BehaviorSubject<Position[]>([]);
  private tempSelectedPolygons$: Observable<string[]>;
  // eslint-disable-next-line @smarttools/rxjs/finnish
  private _facilitySubject = new BehaviorSubject<XYPoint[]>(null);
  // eslint-disable-next-line @smarttools/rxjs/finnish
  private _docksSubject = new BehaviorSubject<XYPoint[][]>(null);
  protected hasSelectedFacility: boolean;

  // eslint-disable-next-line @smarttools/rxjs/finnish
  @Output() public facilityChange = this._facilitySubject.asObservable();
  // eslint-disable-next-line @smarttools/rxjs/finnish
  @Output() public docksChange = this._docksSubject.asObservable();

  @ViewChild('map', { static: true }) public mapElement: ElementRef;
  @ViewChild('dropdown', { static: false }) public dropdownElement: ElementRef;
  @ViewChild('dropdownButton', { static: false }) public dropdownBtnElement: ElementRef;

  public changeVisibility(layer: 'facility' | 'docks') {
    this._layers[layer] = !this._layers[layer];
    let l = this.map.getLayoutProperty(`${layer}-layer`, 'visibility');
    this.map.setLayoutProperty(`${layer}-layer`, 'visibility', this._layers[layer] ? 'visible' : 'none');
  }

  public get layers() {
    return this._layers;
  }

  @Input() public form: FormGroup;

  @Input()
  public set facility(p: XYPoint[]) {
    this._polygons.facility = { id: 'facility', coordinates: p };
    this._facilitySubject.next(this._polygons.facility.coordinates);
  }

  @Input()
  public set docks(p: XYPoint[][]) {
    this._polygons.docks = p && p.map((coords, idx) => ({ id: `dock-${idx}`, coordinates: coords }));
    p && this._docksSubject.next(this._polygons.docks.map((d) => d.coordinates));
  }

  public updateDocks(p: PPolygon[]) {
    this._polygons.docks = p;
    this._docksSubject.next(p.map((d) => d.coordinates));
  }

  public updateFacility(p: PPolygon) {
    this._polygons.facility = p;
    if (p) {
      this._facilitySubject.next(p.coordinates);
      if (this.form && this.form.get('polygon')) {
        this.form.get('polygon').setValue(p.coordinates);
      }
      this.onChange(p.coordinates);
    } else {
      this._facilitySubject.next(null);
      if (this.form && this.form.get('polygon')) {
        this.form.get('polygon').setValue(null);
      }
      this.onChange(null);
    }
    this.onTouched();
  }

  public get facilityValue() {
    return this._polygons.facility;
  }

  public get docksValue() {
    return this._polygons.docks;
  }

  public get createOptions() {
    return [
      { label: 'Create Dock', value: 'docks' },
      ...(this._polygons.facility ? [] : [{ label: 'Create Facility', value: 'facility' }]),
    ];
  }

  public get editOptions() {
    return [
      ...(this._polygons.docks && this._polygons.docks.length > 0 ? [{ label: 'Edit Dock', value: 'docks' }] : []),
      ...(this._polygons.facility ? [{ label: 'Edit Facility', value: 'facility' }] : []),
    ];
  }

  public get deleteOptions() {
    const selectedPointsLength = this._selectedPointsSubject.value?.length || 0;
    const selectedPolygonsLength = this._selectedPolygonsSubject.value?.length || 0;

    return [
      ...(selectedPointsLength > 0 ? [{ label: 'Delete Point', value: 'point' }] : []),
      ...(selectedPolygonsLength > 0 ? [{ label: 'Delete Polygon', value: 'polygon' }] : []),
    ];
  }

  public get action(): 'create' | 'edit' | null {
    return this._action;
  }

  constructor(private cdr: ChangeDetectorRef) {
    super(SATELLITE);
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {},
    });
  }

  public get editing() {
    return this._action == 'edit';
  }

  public triggerPolygon({ type, value }: ActionEvent) {
    this._action = value as 'create' | 'edit';
    value === 'create' && this.triggerCreatePolygon(type as 'facility' | 'docks');
    value === 'edit' && this.triggerEditPolygon(type as 'facility' | 'docks');
  }

  public toggleEdit() {
    const newSourceData: FeatureCollection<Polygon> = {
      type: 'FeatureCollection',
      features: [],
    };

    const d = this.map.getSource(`${this.activeLayer}-layer`) as GeoJSONSource;
    newSourceData.features = this.mapPolygons(
      this.activeLayer === 'facility' ? [this._polygons.facility] : this._polygons.docks,
    );
    d.setData(newSourceData);
    this._action = null;
    this._selectedPolygonsSubject.next([]);
    this.draw.deleteAll();
    this._activeLayer = null;
    this._layers = { facility: true, docks: true };

    this.map.setLayoutProperty('facility-layer', 'visibility', 'visible');
    this.map.setLayoutProperty('docks-layer', 'visibility', 'visible');
  }

  public remove(e: ActionEvent) {
    // The dropdown emits { type: option.value, value: dropdownType }
    // So for "Delete Polygon", type will be "polygon" and value will be "delete"
    if (e.value !== 'delete') {
      return; // Return early if not a delete action
    }

    if (e.type === 'polygon') {
      this.removePolygon();
    } else if (e.type === 'point') {
      this.removePoint();
    }
  }

  public removePoint() {
    if (!this._selectedPointsSubject.value || this._selectedPointsSubject.value.length === 0) {
      return;
    }

    let features = [];
    let updatedPolygon: PPolygon;

    if (this._activeLayer === 'facility') {
      updatedPolygon = this.removePointFromPolygon(this._polygons.facility);

      // Only update if points were actually removed
      if (updatedPolygon !== this._polygons.facility) {
        this._polygons.facility = updatedPolygon;
        features = this.mapPolygons([this._polygons.facility]);
        this.updateFacility(this._polygons.facility);
      }
    } else {
      let polygonsUpdated = false;

      this._polygons.docks = this._polygons.docks.map((p) => {
        if (this._selectedPolygonsSubject.value.includes(p.id)) {
          const updatedPoly = this.removePointFromPolygon(p);
          if (updatedPoly !== p) {
            polygonsUpdated = true;
            return updatedPoly;
          }
        }
        return p;
      });

      if (polygonsUpdated) {
        this.updateDocks(this._polygons.docks);
        features = this.mapPolygons(this._polygons.docks);
      }
    }

    // Update the draw state with the modified features
    if (features.length > 0) {
      this.draw.set({ type: 'FeatureCollection', features: features });
    }

    // Reset point selection
    this._selectedPointsSubject.next([]);

    // Force change detection to update the UI
    this.cdr.detectChanges();
  }

  private removePointFromPolygon(p: PPolygon) {
    // Filter out the selected points
    let filteredCoordinates = p.coordinates.filter(({ x, y }) => {
      let dontRemove = true;
      for (let i = 0; i < this._selectedPointsSubject.value.length; i++) {
        if (this._selectedPointsSubject.value[i][0] === x && this._selectedPointsSubject.value[i][1] === y) {
          dontRemove = false;
          break;
        }
      }
      return dontRemove;
    });

    // Make sure we have at least 3 points for a valid polygon
    if (filteredCoordinates.length < 3) {
      return p; // Return original polygon if we can't remove the point
    }

    // Ensure the polygon is closed (first point equals last point)
    if (
      filteredCoordinates.length > 0 &&
      (filteredCoordinates[0].x !== filteredCoordinates[filteredCoordinates.length - 1].x ||
        filteredCoordinates[0].y !== filteredCoordinates[filteredCoordinates.length - 1].y)
    ) {
      // If the polygon is not closed, close it by duplicating the first point
      filteredCoordinates.push({ ...filteredCoordinates[0] });
    }

    return {
      ...p,
      coordinates: filteredCoordinates,
    };
  }

  public removePolygon() {
    this._action = null;
    if (!this._activeLayer) {
      return;
    }

    const sourceData = this.map.getSource(`${this._activeLayer}-layer`) as GeoJSONSource;
    if (!sourceData) {
      return;
    }

    if (this._activeLayer === 'docks') {
      this.updateDocks(this._polygons.docks.filter((p) => !this._selectedPolygonsSubject.value.includes(p.id)));
      sourceData.setData({
        type: 'FeatureCollection',
        features: this._polygons.docks.flatMap((polys) => this.mapPolygonToFeature(polys) || []),
      });
    } else if (this._activeLayer === 'facility') {
      // Clear the facility - updateFacility will handle form value updates and notifications
      this._polygons.facility = null;
      this.updateFacility(null);

      // Update the map source to show no polygons
      sourceData.setData({
        type: 'FeatureCollection',
        features: [],
      });
    }

    // Reset selections
    this._selectedPolygonsSubject.next([]);
    this.draw.deleteAll();

    // Reset active layer and show all layers
    this._activeLayer = null;
    this._layers = { facility: true, docks: true };
    this.map.setLayoutProperty('facility-layer', 'visibility', 'visible');
    this.map.setLayoutProperty('docks-layer', 'visibility', 'visible');
  }

  public triggerDeletePolygon(type: 'facility' | 'docks') {}

  public triggerCreatePolygon(type: 'facility' | 'docks') {
    this._action = 'create';
    this.enableLayerEdit(type);

    if (type === 'docks') {
      this.setPolygonColor('#FF0000');
    } else {
      this.setPolygonColor('#0080ff');
    }
    this.draw.changeMode('draw_polygon');
  }

  public triggerEditPolygon(type: 'facility' | 'docks') {
    this._action = 'edit';
    this.enableLayerEdit(type);

    this.map.setLayoutProperty(`${type}-layer`, 'visibility', 'none');

    const features: Feature<Polygon>[] = this.mapPolygons(
      type === 'facility' ? [this._polygons.facility] : this._polygons.docks,
    );

    // Clear existing draw features and add new ones
    this.draw.deleteAll();
    this.draw.add({
      type: 'FeatureCollection',
      features: features,
    });

    // Enable selection mode
    this.draw.changeMode('simple_select', { featureIds: features.map((f) => f.id as string) });
    this.setPolygonColor(type === 'facility' ? '#FF0000' : '#0080ff');

    // For better user experience, initially select the polygon but don't override later selections
    const featureIds = features.map((f) => f.id as string);
    if (featureIds.length > 0) {
      // Explicitly set the selected polygons to ensure the delete button appears
      // This is a safeguard in case the draw.selectionchange event isn't triggered
      this._selectedPolygonsSubject.next(featureIds);

      // Force change detection to update the UI
      this.cdr.detectChanges();
    }
  }

  public get activeLayer() {
    return this._activeLayer;
  }

  private enableLayerEdit(type: 'facility' | 'docks' | null) {
    this._type = type;
    this._activeLayer = type;
    this._layers = { facility: type === 'facility', docks: type === 'docks' };
    this.map.setLayoutProperty('facility-layer', 'visibility', this.layers.facility ? 'visible' : 'none');
    this.map.setLayoutProperty('docks-layer', 'visibility', this.layers.docks ? 'visible' : 'none');
  }

  private setPolygonColor(color: string) {
    if (this.map.getLayer('gl-draw-polygon-fill-inactive')) {
      this.map.setPaintProperty('gl-draw-polygon-fill-inactive', 'fill-color', color);
    }
    if (this.map.getLayer('gl-draw-polygon-fill-active')) {
      this.map.setPaintProperty('gl-draw-polygon-fill-active', 'fill-color', color);
    }
  }

  private addPolygon(type: 'facility' | 'docks', polygon: any) {
    if (type === 'facility' && !this._polygons[type]) {
      this._polygons[type] = null;
    } else if (!this._polygons[type]) {
      (this._polygons[type] as PPolygon[]) = [];
    }

    if (type === 'facility') {
      // Create a properly formatted facility polygon with id
      const facilityPolygon: PPolygon = {
        id: 'facility',
        coordinates: polygon as { x: number; y: number }[],
      };
      this.updateFacility(facilityPolygon);
    } else {
      this._polygons.docks.push({
        id: `dock-${this._polygons.docks.length}`,
        coordinates: polygon as { x: number; y: number }[],
      });
      this.updateDocks(this._polygons.docks);
    }
  }

  private mapPolygons(polys: PPolygon[]): Feature<Polygon>[] {
    if (!polys || !Array.isArray(polys)) {
      return [];
    }
    return polys.map((p) => {
      return {
        id: p.id,
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [p.coordinates.map(({ x, y }) => [x, y] as Position)],
        },
        properties: {},
      };
    });
  }
  private mapPolygonToFeature(polygon: PPolygon): GeoJSON.Feature<GeoJSON.Polygon> | null {
    if (!polygon || !polygon.coordinates || polygon.coordinates.length === 0) {
      return null;
    }

    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [polygon.coordinates.map(({ x, y }) => [x, y] as Position)],
      },
      properties: {},
    };
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {}

  public writeValue(obj: any): void {}

  public override onStyleLoad() {
    super.onStyleLoad();

    // Add the draw control to the map
    if (this.draw && !this.map.hasControl(this.draw)) {
      this.map.addControl(this.draw);
    }

    // Add sources and layers
    this.map.addSource('facility-layer', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features:
          this._polygons.facility && this._polygons.facility.coordinates
            ? [
                {
                  id: 'facility',
                  type: 'Feature',
                  properties: { id: 'facility' },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [this._polygons.facility.coordinates.map(({ x, y }) => [x, y] as Position)],
                  },
                },
              ]
            : [],
      },
      promoteId: 'facility',
    });

    this.map.addLayer({
      id: 'facility-layer',
      type: 'fill',
      layout: {
        visibility: 'visible',
      },
      source: 'facility-layer',
      paint: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'fill-color': '#0080ff',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'fill-opacity': 0.5,
      },
    });

    this.map.addSource('docks-layer', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: this.mapPolygons(this._polygons.docks),
      },
    });

    this.map.addLayer({
      id: 'docks-layer',
      type: 'fill',
      source: 'docks-layer',
      layout: {
        visibility: 'visible',
      },
      paint: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'fill-color': '#FF0000',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'fill-opacity': 0.5,
      },
    });

    this.map.on('draw.selectionchange', this.handlePolygonSelect.bind(this));
    this.map.on('draw.create', this.handlePolygonCreate.bind(this));
    this.map.on('draw.update', this.handlePolygonUpdate.bind(this));

    // Add click handler for the map to ensure polygons can be selected
    // even if the draw.selectionchange event doesn't fire
    this.map.on('click', () => {
      // Only force-select all polygons when we're in edit mode AND
      // there are no current selections AND we need the delete button
      if (
        this._action === 'edit' &&
        this._activeLayer &&
        (!this._selectedPolygonsSubject.value || this._selectedPolygonsSubject.value.length === 0) &&
        (!this._selectedPointsSubject.value || this._selectedPointsSubject.value.length === 0)
      ) {
        // Force select all polygons in the active layer
        const features = this.mapPolygons(
          this._activeLayer === 'facility' ? [this._polygons.facility] : this._polygons.docks,
        );
        const featureIds = features.map((f) => f.id as string).filter((id) => !!id); // Only include valid IDs

        if (featureIds.length > 0) {
          // Only update the draw selection if we don't have point selections
          this.draw.changeMode('simple_select', { featureIds });

          // Manually update selectedPolygons
          this._selectedPolygonsSubject.next(featureIds);

          // Force change detection to update the UI
          this.cdr.detectChanges();
        }
      }
    });

    // Only update bounds if we have a facility
    if (
      this._polygons.facility &&
      this._polygons.facility.coordinates &&
      this._polygons.facility.coordinates.length > 0
    ) {
      this.updateBounds();
    }
  }

  public registerOnValidatorChange(fn: () => void): void {
    // do nothing
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value as XYPoint[];
    if (!value) {
      return null;
    }
    if (value.length < 4) {
      return {
        polygon: 'Polygon must have at least 3 points',
      };
    }
    if (value[0].x !== value[value.length - 1].x || value[0].y !== value[value.length - 1].y) {
      return {
        polygon: 'Polygon must be closed',
      };
    }
    return null;
  }

  private updateBounds() {
    if (
      !this._polygons.facility ||
      !this._polygons.facility.coordinates ||
      this._polygons.facility.coordinates.length === 0
    ) {
      return;
    }
    const center = centroid({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [this._polygons.facility.coordinates.map(({ x, y }) => [x, y])],
      },
    });
    this.map.setCenter(center.geometry.coordinates as any);
    const bounds = new LngLatBounds();
    this._polygons.facility.coordinates.forEach(({ x, y }) => {
      bounds.extend([x, y]);
    });
    this.map.fitBounds(bounds, {
      padding: 100,
    });
  }

  // eslint-disable-next-line complexity
  private handlePolygonSelect(e) {
    // Update point selections when present
    if (e.points && e.points.length > 0) {
      const selectedPoints = e.points.map((f) => f.geometry.coordinates);
      this._selectedPointsSubject.next(selectedPoints);

      // When points are selected, we shouldn't auto-select the whole polygon
      // This allows users to edit individual points without affecting the whole polygon
      if (e.features && e.features.length > 0) {
        // Keep track of which polygon these points belong to
        const selectedIds = e.features.map((f) => f.id as string);
        this._selectedPolygonsSubject.next(selectedIds);
      }
    } else if (e.features && e.features.length > 0) {
      // Only polygon features are selected, no points
      const selectedIds = e.features.map((f) => f.id as string);
      this._selectedPolygonsSubject.next(selectedIds);

      // Clear any point selections since we're now selecting whole polygons
      this._selectedPointsSubject.next([]);
    } else if (this._action === 'edit' && this._activeLayer) {
      // If no features and no points in the event, but we're in edit mode,
      // this could mean the user clicked outside any polygon or point

      // In most cases we want to keep the current selection to avoid confusion
      // But if there's no current selection, we can try to select the active layer
      if (
        (!this._selectedPolygonsSubject.value || this._selectedPolygonsSubject.value.length === 0) &&
        (!this._selectedPointsSubject.value || this._selectedPointsSubject.value.length === 0)
      ) {
        // Try to select all features of the active layer
        const features = this.mapPolygons(
          this._activeLayer === 'facility' ? [this._polygons.facility] : this._polygons.docks,
        );
        const featureIds = features.map((f) => f.id as string).filter((id) => !!id);
        if (featureIds.length > 0) {
          this._selectedPolygonsSubject.next(featureIds);
        }
      }
    } else {
      // Clear selections if nothing is selected in the event
      this._selectedPolygonsSubject.next([]);
      this._selectedPointsSubject.next([]);
    }

    // Update active layer based on selection
    const selectedIds = this._selectedPolygonsSubject.value || [];
    if (selectedIds.length > 0 && this._action === 'edit') {
      if (selectedIds.includes(this._polygons.facility?.id)) {
        this._activeLayer = 'facility';
        this.hasSelectedFacility = true;
      } else {
        // Check if any of the selected IDs start with 'dock-'
        const hasDockSelected = selectedIds.some((id) => id.startsWith('dock-'));
        if (hasDockSelected) {
          this._activeLayer = 'docks';
        }
        this.hasSelectedFacility = false;
      }
    } else if (this._action !== 'edit') {
      // Even if not in edit mode, still set hasSelectedFacility
      this.hasSelectedFacility = selectedIds.includes(this._polygons.facility?.id);
    }

    // Force change detection to update the UI when selections change
    this.cdr.detectChanges();
  }

  private handlePolygonUpdate(e?: any) {
    if (this.activeLayer && e && e.features && e.features.length > 0) {
      const id = e.features[0].id as string;
      const coordinates = e.features[0].geometry.coordinates[0];

      if (this._activeLayer === 'facility') {
        this.updateFacility({ id, coordinates: coordinates.map(([x, y]) => ({ x, y })) });
      } else {
        const dockIdx = this._polygons.docks.findIndex((p) => p.id === id);
        if (dockIdx !== -1) {
          this._polygons.docks[dockIdx] = { id, coordinates: coordinates.map(([x, y]) => ({ x, y })) };
          this.updateDocks(this._polygons.docks);
        }
      }
    }
  }

  private handlePolygonCreate(e?: any) {
    if (this.activeLayer && e && e.features && e.features.length > 0) {
      const coordinates = e.features[0].geometry.coordinates[0];
      const newSourceData: FeatureCollection<Polygon> = {
        type: 'FeatureCollection',
        features: [],
      };

      this.addPolygon(
        this._type,
        coordinates.map(([x, y]) => ({ x, y })),
      );

      // Update the appropriate source data based on the active layer
      if (this._type === 'facility') {
        const facilitySource = this.map.getSource('facility-layer') as GeoJSONSource;
        facilitySource.setData({
          type: 'FeatureCollection',
          features: this._polygons.facility ? [this.mapPolygonToFeature(this._polygons.facility)] : [],
        });
      } else {
        const docksSource = this.map.getSource('docks-layer') as GeoJSONSource;
        newSourceData.features = this.mapPolygons(this._polygons.docks);
        docksSource.setData(newSourceData as any);
      }

      this._activeLayer = null;
      this._layers = { facility: true, docks: true };

      this.map.setLayoutProperty('facility-layer', 'visibility', 'visible');
      this.map.setLayoutProperty('docks-layer', 'visibility', 'visible');
      this.draw.deleteAll();
    }
  }

  // Add a getter to check if delete button should be visible
  public get shouldShowDeleteButton(): boolean {
    const hasSelectedPolygons = this._selectedPolygonsSubject.value && this._selectedPolygonsSubject.value.length > 0;
    const hasSelectedPoints = this._selectedPointsSubject.value && this._selectedPointsSubject.value.length > 0;

    return hasSelectedPolygons || hasSelectedPoints;
  }
}

@if (value !== undefined) {
<div class="flex flex-col w-full">
  <div class="w-full flex justify-between text-green-500">
    <div class="w-8 text-center">
      <div class="font-semibold">{{ lowerBoundCents | currencyInPennies: 'USD':'symbol':'1.0-0' }}</div>
      @if (lowerBoundRPMCents) {
      <div class="text-sm">{{ lowerBoundRPMCents | currencyInPennies }}/mi</div>
      } @if (customerPriceCents && !showDollarValueDifferences && showMargin) {
      <div class="text-sm text-gray-600">
        {{ customerPriceCents - lowerBoundCents | currencyInPennies: 'USD':'symbol':'1.0-0' }} ({{
          (customerPriceCents - lowerBoundCents) / customerPriceCents | percent
        }})
      </div>
      <div class="text-sm text-gray-600">margin</div>
      } @if (customerPriceCents && showDollarValueDifferences) {
      <div class="text-sm text-gray-600">
        {{ lowerBoundCents - customerPriceCents | currencyInPennies: 'USD':'symbol':'1.0-0' }}
      </div>
      <div class="text-sm text-gray-600">{{ lowerBoundCents - customerPriceCents > 0 ? 'above' : 'below' }} post</div>
      }
    </div>
    <div class="w-8 text-center">
      <div class="font-semibold">{{ upperBoundCents | currencyInPennies: 'USD':'symbol':'1.0-0' }}</div>
      @if (lowerBoundRPMCents) {
      <div class="text-sm">{{ upperBoundRPMCents | currencyInPennies }}/mi</div>
      } @if (customerPriceCents && !showDollarValueDifferences && showMargin) {
      <div class="text-sm text-gray-600">
        {{ customerPriceCents - upperBoundCents | currencyInPennies: 'USD':'symbol':'1.0-0' }} ({{
          (customerPriceCents - upperBoundCents) / customerPriceCents | percent
        }})
      </div>
      <div class="text-sm text-gray-600">margin</div>
      } @if (customerPriceCents && showDollarValueDifferences) {
      <div class="text-sm text-gray-600">
        {{ upperBoundCents - customerPriceCents | currencyInPennies: 'USD':'symbol':'1.0-0' }}
      </div>
      <div class="text-sm text-gray-600">{{ upperBoundCents - customerPriceCents > 0 ? 'above' : 'below' }} post</div>
      }
    </div>
  </div>
  <div class="h-10 flex flex-row w-full">
    <div class="flex w-4 border-gray-500 border-r my-1"></div>
    <div class="relative flex flex-col flex-1">
      <div class="border-gray-500 border-b flex-1"></div>
      <div class="flex-1"></div>
      @if (middleBoundTitle && middleBoundCents && middleBoundRPMCents) {
      <div
        class="absolute flex flex-col items-center text-green-500"
        style="left: 50%; margin-top: -40px; margin-left: -24px"
      >
        <div class="font-semibold">{{ middleBoundCents | currencyInPennies: 'USD':'symbol':'1.0-0' }}</div>
        @if (middleBoundRPMCents) {
        <div class="text-sm">{{ middleBoundRPMCents | currencyInPennies }}/mi</div>
        } @if (customerPriceCents && !showDollarValueDifferences && showMargin) {
        <div class="text-sm text-gray-600">
          {{ customerPriceCents - middleBoundCents | currencyInPennies: 'USD':'symbol':'1.0-0' }} ({{
            (customerPriceCents - middleBoundCents) / customerPriceCents | percent
          }})
        </div>
        <div class="text-sm text-gray-600">margin</div>
        } @if (customerPriceCents && showDollarValueDifferences) {
        <div class="text-sm text-gray-600">
          {{ middleBoundCents - customerPriceCents | currencyInPennies: 'USD':'symbol':'1.0-0' }}
        </div>
        <div class="text-sm text-gray-600">
          {{ middleBoundCents - customerPriceCents > 0 ? 'above' : 'below' }} post
        </div>
        }
        <div class="h-8 border-r border-gray-500 my-1"></div>
      </div>
      } @if (!evaluateLoadDesign) {
      <div
        class="absolute flex flex-col items-center w-6"
        style="top: 24px; margin-left: -24px"
        [style.left]="((value - lowerBoundCents) / (upperBoundCents - lowerBoundCents)) * 100 + '%'"
      >
        <div class="bg-green-500 h-4 w-4 rounded-full"></div>
        <div class="text-center">
          <div class="text-xl font-semibold text-green-500">
            {{ value | currencyInPennies: 'USD':'symbol':'1.0-0' }}
          </div>
          @if (valueRPM) {
          <div class="text-sm text-green-500">{{ valueRPM | currencyInPennies }}/mi</div>
          } @if (customerPriceCents && !showDollarValueDifferences) {
          <div class="text-sm text-gray-600 flex-row">
            {{ customerPriceCents - value | currencyInPennies: 'USD':'symbol':'1.0-0' }} ({{
              (customerPriceCents - value) / customerPriceCents | percent
            }})
          </div>
          <div class="text-sm text-gray-600">margin</div>
          } @if (customerPriceCents && showDollarValueDifferences) {
          <div class="text-sm text-gray-600 flex-row">
            {{ value - customerPriceCents | currencyInPennies: 'USD':'symbol':'1.0-0' }}
          </div>
          <div class="text-sm text-gray-600 whitespace-nowrap">
            {{ value - customerPriceCents > 0 ? 'above' : 'below' }} post
          </div>
          }
        </div>
      </div>
      } @if (evaluateLoadDesign) {
      <div
        class="absolute flex flex-col items-center w-6"
        style="margin-top: -37px; margin-left: -24px"
        [style.left]="
          (value - lowerBoundCents) / (upperBoundCents - lowerBoundCents) > 1
            ? '100%'
            : ((value - lowerBoundCents) / (upperBoundCents - lowerBoundCents) | percent)
        "
      >
        <div class="text-center bg-zinc-600 shadow rounded-sm text-white p-[4px]">
          <div class="text-xl font-semibold">
            {{ value | currencyInPennies: 'USD':'symbol':'1.0-0' }}
          </div>
          @if (valueRPM) {
          <div class="text-sm">{{ valueRPM | currencyInPennies }}/mi</div>
          } @if (customerPriceCents && !showDollarValueDifferences && showMargin) {
          <div class="text-sm text-gray-600 flex-row">
            {{ customerPriceCents - value | currencyInPennies: 'USD':'symbol':'1.0-0' }} ({{
              (customerPriceCents - value) / customerPriceCents | percent
            }})
          </div>
          <div class="text-sm text-gray-600">margin</div>
          } @if (customerPriceCents && showDollarValueDifferences) {
          <div class="text-sm text-gray-600 flex-row">
            {{ value - customerPriceCents | currencyInPennies: 'USD':'symbol':'1.0-0' }}
          </div>
          <div class="text-sm text-gray-600 whitespace-nowrap">
            {{ value - customerPriceCents > 0 ? 'above' : 'below' }} post
          </div>
          }
        </div>
        <div class="bg-green-500 h-4 w-4 rounded-full mt-1"></div>
        <div class="bg-green-500 px-1 text-white font-semibold mt-1 rounded-sm">Target</div>
      </div>
      }
    </div>
    <div class="flex w-4 border-gray-500 border-l my-1"></div>
  </div>
  <div class="w-full flex justify-between">
    <div class="w-8 text-center">{{ lowerBoundTitle }}</div>
    @if (middleBoundTitle) {
    <div class="w-8 text-center ml-1">{{ middleBoundTitle }}</div>
    }
    <div class="w-8 text-center">{{ upperBoundTitle }}</div>
  </div>
</div>
}

@if (loadRateconPaymentStatus) {
<div class="mx-auto max-w-screen-lg">
  <div class="card-with-shadow-white-no-radius p-2 print:shadow-none">
    <div class="font-semibold text-xl">Payment Processing Status</div>
    <div class="p-2 gap-1 my-1 mx-1">
      <mat-progress-bar
        mode="determinate"
        value="{{
          loadRateconPaymentStatus.isPaymentSettled
            ? 100
            : loadRateconPaymentStatus.isPaymentApproved
            ? 65
            : loadRateconPaymentStatus.isInvoiceApproved
            ? 30
            : 10
        }}"
      >
      </mat-progress-bar>
    </div>
    <div class="grid grid-cols-3 pt-1 text-center">
      <div class="grid grid-rows-3">
        <div>
          @if (loadRateconPaymentStatus.isInvoiceApproved) {
          <mat-icon class="text-green-500">check_circle</mat-icon>
          } @if (!loadRateconPaymentStatus.isInvoiceApproved) {
          <mat-icon color="warn">cancel</mat-icon>
          }
        </div>
        <div class="font-semibold">
          @if (loadRateconPaymentStatus.isInvoiceApproved) { Carrier Approved } @if
          (!loadRateconPaymentStatus.isInvoiceApproved) { Carrier Not Approved Yet }
        </div>
        @if (loadRateconPaymentStatus.isInvoiceApproved) {
        <div>
          {{ loadRateconPaymentStatus.invoiceApprovedByCarriersAt | standardDate }}
        </div>
        }
      </div>
      <div class="grid grid-rows-3">
        <div>
          @if (loadRateconPaymentStatus.isPaymentApproved) {
          <mat-icon class="text-green-500">check_circle</mat-icon>
          } @if (!loadRateconPaymentStatus.isPaymentApproved) {
          <mat-icon color="warn">cancel</mat-icon>
          }
        </div>
        <div class="font-semibold">
          @if (loadRateconPaymentStatus.isPaymentApproved) {
          {{ brokerage === 'lohi' ? 'LoHi' : (brokerage | enum) }} Approved } @if
          (!loadRateconPaymentStatus.isPaymentApproved) {
          {{ brokerage === 'lohi' ? 'LoHi' : (brokerage | enum) }}
          Not Approved Yet }
        </div>
        @if (loadRateconPaymentStatus.isPaymentApproved) {
        <div>
          {{ loadRateconPaymentStatus.paymentApprovedBy5FAt | standardDate }}
        </div>
        }
      </div>
      <div class="grid grid-rows-3">
        <div>
          @if (loadRateconPaymentStatus.isPaymentSettled) {
          <mat-icon class="text-green-500">check_circle</mat-icon>
          } @if (!loadRateconPaymentStatus.isPaymentSettled) {
          <mat-icon color="warn">cancel</mat-icon>
          }
        </div>
        <div class="font-semibold">
          @if (loadRateconPaymentStatus.isPaymentSettled) { Funds Disbursed } @if
          (!loadRateconPaymentStatus.isPaymentSettled) { Funds Not Disbursed Yet }
        </div>
        @if (loadRateconPaymentStatus.isPaymentSettled) {
        <div>
          {{ loadRateconPaymentStatus.paymentSettledAt | standardDate }}
        </div>
        }
      </div>
    </div>
    <ng-container *tdFeatureFlag="'showLoadDisapprovalHistory'">
      <!--    Once we have approved this, there is no real reason to show the history, just want to make factoring companies afraiad-->
      @if (!loadRateconPaymentStatus.paymentApprovedBy5FAt) { @if (loadRateconPaymentStatus.rejectionEvents?.length) {
      <div>
        <div class="font-semibold text-lg">
          <span class="text-red-500">Warning</span> This load has previously been pushed pushed back for the following
          reasons
        </div>
        <div class="flex flex-col pt-1 text-center">
          @for ( event of loadRateconPaymentStatus.rejectionEvents; track event.eventTime) {
          <div class="flex flex-row">
            <div class="mr-3">
              {{ event.eventTime | standardDate }}
            </div>
            <div class="font-semibold">
              {{ event.carrierNotes }}
            </div>
          </div>
          }
          <div></div>
        </div>
      </div>
      } }
    </ng-container>
  </div>
</div>
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NetworkableDestroyableComponent } from '../../networkable-destroyable.component';
import { BehaviorSubject } from 'rxjs';
import { ApprovePrecheckResponse, LoadBillingService } from '../../../services/load-billing.service';
import { SnackBarService } from '../../../services/snackbar.service';

@Component({
  selector: 'td-confirm-approve-for-invoicing',
  templateUrl: './confirm-approve-for-invoicing.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ConfirmApproveForInvoicingComponent extends NetworkableDestroyableComponent {
  public form = this.fb.group({
    allChargesUploaded: [false],
    noAdditionalCharges: [false],
    willNotDisputePayout: [false],
  });

  private approvePrecheckResponse$$ = new BehaviorSubject<ApprovePrecheckResponse>(null);
  public approvePrecheckResponse$ = this.approvePrecheckResponse$$.asObservable();

  public disabled$ = this.form.valueChanges.pipe(
    startWith(this.form.value),
    map((value) => !(value.allChargesUploaded && value.noAdditionalCharges && value.willNotDisputePayout)),
  );

  public userInfo$ = this.authService.userInfo$;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      loadId: string;
      payoutCents: number;
    },
    private matDialogRef: MatDialogRef<ConfirmApproveForInvoicingComponent>,
    private fb: FormBuilder,
    private authService: AuthService,
    private loadBillingService: LoadBillingService,
    private snackbar: SnackBarService,
  ) {
    super();
    this.loadPrecheck();
  }

  private async loadPrecheck() {
    this.networkActive$$.next(true);
    const response = await this.loadBillingService.approvePrecheck(this.data.loadId);
    this.approvePrecheckResponse$$.next(response);
    this.networkActive$$.next(false);
  }

  public async applyPass() {
    this.networkActive$$.next(true);
    const success = await this.loadBillingService.consumeBadUploadPass(this.data.loadId);
    this.networkActive$$.next(false);
    if (success) {
      this.snackbar.showMessage('Pass applied, please review the load again');
      this.matDialogRef.close(false);
    }
  }
}

import * as i0 from '@angular/core';
import { SecurityContext, Component, ViewChild, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/platform-browser';
const _c0 = ["initiator"];
const _c1 = ["*"];
let styleElement;
const GLOBAL_CSS = `
.csvbox-holder {
  z-index: 2147483647;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
}
.csvbox-holder iframe {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
`;
const insertCSS = () => {
  var _a;
  if (styleElement) {
    return;
  }
  styleElement = document.createElement('style');
  styleElement.setAttribute('type', 'text/css');
  (_a = document.querySelector('head')) === null || _a === void 0 ? void 0 : _a.appendChild(styleElement);
  styleElement.textContent = GLOBAL_CSS;
};
const {
  version: appVersion
} = require('./../package.json');
class CSVBoxButtonComponent {
  constructor(sanitizer) {
    this.sanitizer = sanitizer;
    this.isModalShown = false;
    this.uuid = null;
    this.customDomain = null;
    this.dataLocation = null;
    this.language = null;
    this.isIframeLoaded = false;
    this.openModalOnIframeLoad = false;
    this.lazy = false;
    this.iframe = null;
    this.disabled = true;
  }
  ngOnInit() {
    this.uuid = this.generateUuid();
    let domain = this.customDomain ? this.customDomain : "app.csvbox.io";
    if (this.dataLocation) {
      domain = `${this.dataLocation}-${domain}`;
    }
    let iframeUrl = `https://${domain}/embed/${this.licenseKey}`;
    iframeUrl += `?library-version=${appVersion}`;
    iframeUrl += "&framework=angular";
    if (this.dataLocation) {
      iframeUrl += "&preventRedirect";
    }
    if (this.language) {
      iframeUrl += "&language" + this.language;
    }
    if (this.environment) {
      let environment = JSON.stringify(this.environment).replace(/['"]/g, function (match) {
        return '\\' + match;
      });
      iframeUrl += `&env=${environment}`;
    }
    this.safeUrl = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl));
  }
  generateUuid() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }
  ngOnChanges(changes) {
    if (changes["user"] && changes['user'].currentValue != changes['user'].previousValue) {
      this.updateUserVariabe(changes['user'].currentValue);
    }
  }
  updateUserVariabe(data) {
    var _a, _b;
    this.user = data;
    (_b = (_a = this.iframe) === null || _a === void 0 ? void 0 : _a.contentWindow) === null || _b === void 0 ? void 0 : _b.postMessage({
      "customer": data
    }, "*");
  }
  ngAfterContentInit() {
    window.addEventListener("message", event => {
      var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
      if (typeof (event === null || event === void 0 ? void 0 : event.data) == "object") {
        if (((_b = (_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.unique_token) == this.uuid) {
          if (event.data.type && event.data.type == "data-on-submit") {
            let metadata = event.data.data;
            metadata["column_mappings"] = event.data.column_mapping;
            delete metadata["unique_token"];
            (_c = this.onSubmit) === null || _c === void 0 ? void 0 : _c.call(this, metadata);
            (_d = this.isSubmitted) === null || _d === void 0 ? void 0 : _d.call(this, metadata);
            (_e = this.submitted) === null || _e === void 0 ? void 0 : _e.call(this, metadata);
          } else if (event.data.type && event.data.type == "data-push-status") {
            if (event.data.data.import_status == "success") {
              if ((_f = event === null || event === void 0 ? void 0 : event.data) === null || _f === void 0 ? void 0 : _f.row_data) {
                let primary_row_data = event.data.row_data;
                let headers = event.data.headers;
                let rows = [];
                let dynamic_columns_indexes = event.data.dynamicColumnsIndexes;
                let virtual_columns_indexes = event.data.virtualColumnsIndexes || [];
                primary_row_data.forEach(row_data => {
                  let x = {};
                  let dynamic_columns = {};
                  let virtual_data = {};
                  row_data.data.forEach((col, i) => {
                    if (col == undefined) {
                      col = "";
                    }
                    ;
                    if (dynamic_columns_indexes.includes(i)) {
                      dynamic_columns[headers[i]] = col;
                    } else if (virtual_columns_indexes.includes(i)) {
                      virtual_data[headers[i]] = col;
                    } else {
                      x[headers[i]] = col;
                    }
                  });
                  if (row_data === null || row_data === void 0 ? void 0 : row_data.unmapped_data) {
                    x["_unmapped_data"] = row_data.unmapped_data;
                  }
                  if (dynamic_columns && Object.keys(dynamic_columns).length > 0) {
                    x["_dynamic_data"] = dynamic_columns;
                  }
                  if (virtual_data && Object.keys(virtual_data).length > 0) {
                    x["_virtual_data"] = virtual_data;
                  }
                  rows.push(x);
                });
                let metadata = event.data.data;
                metadata["rows"] = rows;
                metadata["column_mappings"] = event.data.column_mapping;
                metadata["raw_columns"] = event.data.raw_columns;
                metadata["ignored_columns"] = event.data.ignored_column_row;
                delete metadata["unique_token"];
                (_g = this.onImport) === null || _g === void 0 ? void 0 : _g.call(this, true, metadata);
                (_h = this.isImported) === null || _h === void 0 ? void 0 : _h.call(this, true, metadata);
                (_j = this.imported) === null || _j === void 0 ? void 0 : _j.call(this, true, metadata);
              } else {
                let metadata = event.data.data;
                delete metadata["unique_token"];
                (_k = this.onImport) === null || _k === void 0 ? void 0 : _k.call(this, true, metadata);
                (_l = this.isImported) === null || _l === void 0 ? void 0 : _l.call(this, true, metadata);
                (_m = this.imported) === null || _m === void 0 ? void 0 : _m.call(this, true, metadata);
              }
            } else {
              let metadata = event.data.data;
              delete metadata["unique_token"];
              (_o = this.onImport) === null || _o === void 0 ? void 0 : _o.call(this, false, metadata);
              (_p = this.isImported) === null || _p === void 0 ? void 0 : _p.call(this, false, metadata);
              (_q = this.imported) === null || _q === void 0 ? void 0 : _q.call(this, false, metadata);
            }
          } else if (event.data.type && event.data.type == "csvbox-modal-hidden") {
            this.holder.style.display = 'none';
            this.isModalShown = false;
            (_r = this.onClose) === null || _r === void 0 ? void 0 : _r.call(this);
            (_s = this.isClosed) === null || _s === void 0 ? void 0 : _s.call(this);
            (_t = this.closed) === null || _t === void 0 ? void 0 : _t.call(this);
          } else if (event.data.type && event.data.type == "csvbox-upload-successful") {
            (_u = this.onImport) === null || _u === void 0 ? void 0 : _u.call(this, true);
            (_v = this.isImported) === null || _v === void 0 ? void 0 : _v.call(this, true);
            (_w = this.imported) === null || _w === void 0 ? void 0 : _w.call(this, true);
          } else if (event.data.type && event.data.type == "csvbox-upload-failed") {
            (_x = this.onImport) === null || _x === void 0 ? void 0 : _x.call(this, false);
            (_y = this.isImported) === null || _y === void 0 ? void 0 : _y.call(this, false);
            (_z = this.imported) === null || _z === void 0 ? void 0 : _z.call(this, false);
          }
        }
      }
    }, false);
    if (this.lazy) {
      this.disabled = false;
    } else {
      this.disabled = true;
      this.initImporter();
    }
  }
  initImporter() {
    var _a;
    (_a = this.loadStarted) === null || _a === void 0 ? void 0 : _a.call(this);
    insertCSS();
    let iframe = document.createElement("iframe");
    this.iframe = iframe;
    iframe.setAttribute("src", this.safeUrl);
    iframe.frameBorder = "0";
    let self = this;
    iframe.onload = function () {
      var _a, _b, _c;
      (_a = self.onReady) === null || _a === void 0 ? void 0 : _a.call(self);
      (_b = self.isReady) === null || _b === void 0 ? void 0 : _b.call(self);
      (_c = self.importerReady) === null || _c === void 0 ? void 0 : _c.call(self);
      self.disabled = false;
      self.isIframeLoaded = true;
      iframe.contentWindow.postMessage({
        "customer": self.user ? self.user : null,
        "columns": self.dynamicColumns ? self.dynamicColumns : null,
        "options": self.options ? self.options : null,
        "unique_token": self.uuid
      }, "*");
      if (self.openModalOnIframeLoad) {
        self.openModal();
      }
    };
    this.holder = document.createElement('div');
    this.holder.classList.add('csvbox-holder');
    this.holder.setAttribute('id', `csvbox-embed-${this.uuid}`);
    this.holder.appendChild(iframe);
    document.body.insertAdjacentElement('beforeend', this.holder);
  }
  openModal() {
    if (this.lazy) {
      if (!this.iframe) {
        this.openModalOnIframeLoad = true;
        this.initImporter();
        return;
      }
    }
    if (!this.isModalShown) {
      if (this.isIframeLoaded) {
        this.isModalShown = true;
        this.holder.style.display = 'block';
        this.iframe.contentWindow.postMessage('openModal', '*');
      } else {
        this.openModalOnIframeLoad = true;
      }
    }
  }
}
CSVBoxButtonComponent.ɵfac = function CSVBoxButtonComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CSVBoxButtonComponent)(i0.ɵɵdirectiveInject(i1.DomSanitizer));
};
CSVBoxButtonComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CSVBoxButtonComponent,
  selectors: [["csvbox-button"]],
  viewQuery: function CSVBoxButtonComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.initiator = _t.first);
    }
  },
  inputs: {
    onImport: "onImport",
    onReady: "onReady",
    onClose: "onClose",
    onSubmit: "onSubmit",
    isImported: "isImported",
    isReady: "isReady",
    isClosed: "isClosed",
    isSubmitted: "isSubmitted",
    importerReady: "importerReady",
    closed: "closed",
    submitted: "submitted",
    imported: "imported",
    loadStarted: "loadStarted",
    user: "user",
    dynamicColumns: "dynamicColumns",
    licenseKey: "licenseKey",
    options: "options",
    uuid: "uuid",
    customDomain: "customDomain",
    dataLocation: "dataLocation",
    language: "language",
    environment: "environment",
    isIframeLoaded: "isIframeLoaded",
    openModalOnIframeLoad: "openModalOnIframeLoad",
    lazy: "lazy",
    disabled: "disabled"
  },
  standalone: false,
  features: [i0.ɵɵNgOnChangesFeature],
  ngContentSelectors: _c1,
  decls: 4,
  vars: 2,
  consts: [["initiator", ""], [3, "click", "disabled"]],
  template: function CSVBoxButtonComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "div")(1, "button", 1, 0);
      i0.ɵɵlistener("click", function CSVBoxButtonComponent_Template_button_click_1_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.openModal());
      });
      i0.ɵɵprojection(3);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("disabled", ctx.disabled);
      i0.ɵɵattribute("data-csvbox-token", ctx.uuid);
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CSVBoxButtonComponent, [{
    type: Component,
    args: [{
      selector: 'csvbox-button',
      template: `
    <div>
      <button [disabled]="disabled" #initiator (click)="openModal()" [attr.data-csvbox-token]="uuid">
        <ng-content></ng-content>
      </button>
    </div>
  `
    }]
  }], function () {
    return [{
      type: i1.DomSanitizer
    }];
  }, {
    initiator: [{
      type: ViewChild,
      args: ['initiator']
    }],
    onImport: [{
      type: Input
    }],
    onReady: [{
      type: Input
    }],
    onClose: [{
      type: Input
    }],
    onSubmit: [{
      type: Input
    }],
    isImported: [{
      type: Input
    }],
    isReady: [{
      type: Input
    }],
    isClosed: [{
      type: Input
    }],
    isSubmitted: [{
      type: Input
    }],
    importerReady: [{
      type: Input
    }],
    closed: [{
      type: Input
    }],
    submitted: [{
      type: Input
    }],
    imported: [{
      type: Input
    }],
    loadStarted: [{
      type: Input
    }],
    user: [{
      type: Input
    }],
    dynamicColumns: [{
      type: Input
    }],
    licenseKey: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    uuid: [{
      type: Input
    }],
    customDomain: [{
      type: Input
    }],
    dataLocation: [{
      type: Input
    }],
    language: [{
      type: Input
    }],
    environment: [{
      type: Input
    }],
    isIframeLoaded: [{
      type: Input
    }],
    openModalOnIframeLoad: [{
      type: Input
    }],
    lazy: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }]
  });
})();
class CSVBoxAngularModule {}
CSVBoxAngularModule.ɵfac = function CSVBoxAngularModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CSVBoxAngularModule)();
};
CSVBoxAngularModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: CSVBoxAngularModule
});
CSVBoxAngularModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CSVBoxAngularModule, [{
    type: NgModule,
    args: [{
      declarations: [CSVBoxButtonComponent],
      imports: [],
      exports: [CSVBoxButtonComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of angular-adapter
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CSVBoxAngularModule, CSVBoxButtonComponent };

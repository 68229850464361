import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { shareReplayComponentConfig } from '../constants';

// @ts-ignore
@Component({
  template: '',
  standalone: false,
})
export abstract class NetworkableDestroyableComponent implements OnDestroy {
  protected networkActive$$ = new BehaviorSubject<boolean>(false);
  public networkActive$: Observable<boolean> = this.networkActive$$.pipe(shareReplay(shareReplayComponentConfig));

  protected destroy$$ = new Subject<void>();

  protected destroy() {
    // ag grid also uses this.destroy$$, so we need to check if it's already closed
    if (this.destroy$$.closed) {
      return;
    }
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  public ngOnDestroy(): void {
    this.destroy();
  }

  public get isActive() {
    return this.networkActive$$.value;
  }

  public set isActive(active: boolean) {
    this.networkActive$$.next(active);
  }
}

<div
  class="p-2 text-lg align-middle"
  [ngClass]="{
    'bg-[#F38876]': rating === 'poor',
    'text-[#952A18]': rating === 'poor',
    'bg-[#D0E1FF]': rating === 'good',
    'text-[#556683]': rating === 'good',
    'bg-[#A1D8C4]': rating === 'great',
    'text-[#3B715E]': rating === 'great',
    'text-gray-600': rating === 'not_ranked'
  }"
>
  @switch (rating) { @case ('poor') { 🙁 } @case ('good') { 🙂 } @case ('great') { 🥳 } @default { - } }
  <ng-content></ng-content>
</div>

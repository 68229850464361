import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'td-driver-calendar-day-night',
  templateUrl: './driver-calendar-day-night.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DriverCalendarDayNightComponent {
  @Input() public dayNightTimes: string[];
  @Input() public heightClass = 'h-7';
  constructor() {}
}

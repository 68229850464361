<div class="flex flex-row items-center justify-between py-2">
  <button color="primary" mat-raised-button class="ml-2" (click)="disputeLineHaul($event)">
    Dispute Linehaul Charges
  </button>
  @if (lineHaulDispute) {
  <div class="flex flex-col text-lg text-orange-500 ml-1">
    This load is a {{ load.trailerDisplayName | lowercase }} {{ loadType | lowercase }} with a linehaul mileage of
    {{ load.tripMileage }} miles. The total payout is {{ lineHaulPayout | currency }}.
  </div>
  }
</div>

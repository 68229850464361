import { Pipe, PipeTransform } from '@angular/core';
import { DrayageStatus } from '../global-types';

const statuses: Record<DrayageStatus, string> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  in_storage: 'In Storage',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  at_delivery: 'At Delivery',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  not_arrived_at_port: 'Not Arrived at Port',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  no_port_arrival_date_set: 'No Port Arrival Date Set',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  available_for_pre_pull: 'Available for Pre-Pull',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ready_for_delivery: 'Ready for Delivery',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ready_for_ingate: 'Ready for Ingate',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  enroute: 'Enroute',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  needs_documentation: 'Needs Documentation',
};

@Pipe({
  name: 'drayageStatus',
  standalone: false,
})
export class DrayageStatusPipe implements PipeTransform {
  public transform(value: DrayageStatus): string {
    return statuses[value];
  }
}

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const constants_1 = require("../constants");
const results_1 = require("../../../results");
const AbstractParserWithWordBoundary_1 = require("../../../common/parsers/AbstractParserWithWordBoundary");
const timeunits_1 = require("../../../utils/timeunits");
const pattern_1 = require("../../../utils/pattern");
class DETimeUnitAgoFormatParser extends AbstractParserWithWordBoundary_1.AbstractParserWithWordBoundaryChecking {
  constructor() {
    super();
  }
  innerPattern() {
    return new RegExp(`(?:\\s*((?:nächste|kommende|folgende|letzte|vergangene|vorige|vor(?:her|an)gegangene)(?:s|n|m|r)?|vor|in)\\s*)?` + `(${constants_1.NUMBER_PATTERN})?` + `(?:\\s*(nächste|kommende|folgende|letzte|vergangene|vorige|vor(?:her|an)gegangene)(?:s|n|m|r)?)?` + `\\s*(${pattern_1.matchAnyPattern(constants_1.TIME_UNIT_DICTIONARY)})`, "i");
  }
  innerExtract(context, match) {
    const num = match[2] ? constants_1.parseNumberPattern(match[2]) : 1;
    const unit = constants_1.TIME_UNIT_DICTIONARY[match[4].toLowerCase()];
    let timeUnits = {};
    timeUnits[unit] = num;
    let modifier = match[1] || match[3] || "";
    modifier = modifier.toLowerCase();
    if (!modifier) {
      return;
    }
    if (/vor/.test(modifier) || /letzte/.test(modifier) || /vergangen/.test(modifier)) {
      timeUnits = timeunits_1.reverseTimeUnits(timeUnits);
    }
    return results_1.ParsingComponents.createRelativeFromReference(context.reference, timeUnits);
  }
}
exports.default = DETimeUnitAgoFormatParser;

import { Pipe, PipeTransform } from '@angular/core';
import { TagColor } from '../global-types';

const tagColors: Record<TagColor, string> = {
  blue: 'bg-blue-500 text-white',
  green: 'bg-green-600 text-white',
  orange: 'bg-orange-600 text-white',
  red: 'bg-red-500 text-white',
  purple: 'bg-indigo-600 text-white',
  pink: 'bg-pink-500 text-white',
  black: 'bg-black text-white',
  white: 'bg-white text-black',
  yellow: 'bg-yellow-500 text-black',
};

const tagTextColors: Record<TagColor, string> = {
  blue: 'text-blue-500',
  green: 'text-green-600',
  orange: 'text-orange-600',
  red: 'text-red-500',
  purple: 'text-indigo-600',
  pink: 'text-pink-500',
  black: 'text-white',
  white: 'text-black',
  yellow: 'text-yellow-500',
};

@Pipe({
  name: 'calendarTagColor',
  standalone: false,
})
export class CalendarTagColorPipe implements PipeTransform {
  public transform(value: TagColor, textInstead = false): unknown {
    return textInstead ? tagTextColors[value] : tagColors[value];
  }
}

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const constants_1 = require("../constants");
const results_1 = require("../../../results");
const AbstractParserWithWordBoundary_1 = require("../../../common/parsers/AbstractParserWithWordBoundary");
const timeunits_1 = require("../../../utils/timeunits");
const PATTERN = new RegExp(`(эти|последние|прошлые|следующие|после|через|\\+|-)\\s*(${constants_1.TIME_UNITS_PATTERN})${constants_1.REGEX_PARTS.rightBoundary}`, constants_1.REGEX_PARTS.flags);
class RUTimeUnitCasualRelativeFormatParser extends AbstractParserWithWordBoundary_1.AbstractParserWithWordBoundaryChecking {
  patternLeftBoundary() {
    return constants_1.REGEX_PARTS.leftBoundary;
  }
  innerPattern() {
    return PATTERN;
  }
  innerExtract(context, match) {
    const prefix = match[1].toLowerCase();
    let timeUnits = constants_1.parseTimeUnits(match[2]);
    switch (prefix) {
      case "последние":
      case "прошлые":
      case "-":
        timeUnits = timeunits_1.reverseTimeUnits(timeUnits);
        break;
    }
    return results_1.ParsingComponents.createRelativeFromReference(context.reference, timeUnits);
  }
}
exports.default = RUTimeUnitCasualRelativeFormatParser;

import { Pipe, PipeTransform } from '@angular/core';
import { FeatureFlagService } from '../services/feature-flag.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'featureFlag',
  standalone: false,
})
export class FeatureFlagPipe implements PipeTransform {
  constructor(private featureFlagService: FeatureFlagService) {}

  public transform(flag: string): Observable<boolean> {
    return this.featureFlagService.isFlagActive$(flag);
  }
}

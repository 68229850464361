@if (comments$ | async; as comments) {
<div class="flex flex-col w-full">
  @if (comments.driverComments?.length > 0 && !onlyInput) {
  <div>
    @for (comment of comments.driverComments; track comment.id) {
    <td-driver-comment [comment]="comment"></td-driver-comment>
    }
  </div>
  }
  <form
    [formGroup]="commentForm"
    (ngSubmit)="submitComment()"
    [ngClass]="onlyInput ? ['flex', 'flex-row', 'gap-2', 'items-center'] : []"
  >
    <mat-form-field class="w-full">
      <mat-label>Add a note</mat-label>
      <textarea matInput rows="1" formControlName="comment"></textarea>
    </mat-form-field>
    <div>
      <button type="submit" mat-raised-button color="accent">Add Note</button>
    </div>
  </form>
</div>
}

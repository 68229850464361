import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyInPennies',
  standalone: false,
})
export class CurrencyInPenniesPipe extends CurrencyPipe implements PipeTransform {
  public override transform(value: any, ...args: any[]): any {
    if (!value && value !== 0) {
      return '—';
    }
    if (!isNaN(value)) {
      return super.transform(value / 100, ...args);
    }
    return super.transform(value, ...args);
  }
}

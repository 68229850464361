@if (dayNightTimes) {
<div class="flex flex-row w-full divide-x divide-transparent">
  @for (time of dayNightTimes; track time) {
  <div
    class="text-center flex-1 odd:bg-white even:bg-gray-100 odd:dark:bg-zinc-900 even:dark:bg-slate-800 overflow-hidden {{
      heightClass
    }}"
  ></div>
  }
</div>
}

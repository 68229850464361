import { Pipe, PipeTransform } from '@angular/core';
import { StopType } from '../global-types';

const stopTypes: Record<StopType, string> = {
  pickup: 'Pickup',
  dropoff: 'Dropoff',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  empty_asset_pickup: 'Empty Asset Pickup',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  empty_asset_dropoff: 'Empty Asset Dropoff',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  loaded_asset_storage_pickup: 'Loaded Asset Storage Pickup',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  loaded_asset_storage_dropoff: 'Loaded Asset Storage Dropoff',
};

@Pipe({
  name: 'stopType',
  standalone: false,
})
export class StopTypePipe implements PipeTransform {
  public transform(value: StopType | StopType[]): string | string[] {
    if (Array.isArray(value)) {
      return value.map((v) => stopTypes[v]);
    }
    return stopTypes[value];
  }
}

<div mat-dialog-title>Create Admin for {{ companyData.name }}</div>
<div mat-dialog-content>
  <div class="grid grid-cols-2 gap-2" [formGroup]="form">
    <mat-form-field>
      <mat-label> Name </mat-label>
      <input matInput autocomplete="off" formControlName="driverName" />
    </mat-form-field>
    <mat-form-field>
      <mat-label> Email </mat-label>
      <input matInput autocomplete="off" formControlName="email" />
    </mat-form-field>
    <mat-form-field>
      <mat-label> Phone </mat-label>
      <ngx-mat-input-tel
        [preferredCountries]="['us']"
        [enablePlaceholder]="true"
        [enableSearch]="true"
        format="national"
        name="phoneNumber"
        placeholder=" "
        formControlName="phoneNumber"
      ></ngx-mat-input-tel>
    </mat-form-field>
    <mat-form-field>
      <mat-label> Password </mat-label>
      <input matInput autocomplete="off" formControlName="password" />
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="accent" [disabled]="form.invalid" (click)="createAdmin()">Create Admin</button>
</div>

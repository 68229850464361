import { Pipe, PipeTransform } from '@angular/core';
import { DismissReasonType, FileUploadType } from '../global-types';

const dismissReasonType: Record<DismissReasonType, string> = {
  rate: 'Rate',
  comments: 'Comments',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  pickup_appt: 'Pick Up Appointment',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  carrier_broker_relationship: 'Carrier Broker Relationship',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '5f_broker_relationship': '5F Broker Relationship',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  no_longer_available: 'No Longer Available',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  pickup_time: 'Pick Up Time',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  unable_contact_broker: 'Unable To Contact Broker',
  other: 'Other',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  broker_will_not_work_with_carrier: 'Broker Will Not Work With Carrier',
};

@Pipe({
  name: 'dismissReasonType',
  standalone: false,
})
export class DismissReasonPipe implements PipeTransform {
  public transform(value: string): string {
    const fileType = dismissReasonType[value];

    if (fileType !== undefined) {
      return fileType;
    }

    return value;
  }
}

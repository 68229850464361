import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MappableComponent, SATELLITE } from '../mappable/mappable.component';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { GeoJSONSource, LngLatBounds } from 'mapbox-gl';
import { Feature, FeatureCollection, Polygon } from '@turf/helpers/dist/js/lib/geojson';
import { XYPoint } from '../../global-types';
import { centroid } from '@turf/centroid';

@Component({
  selector: 'td-polygonal-geofence-map',
  templateUrl: './polygonal-geofence-map.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PolygonalGeofenceMapComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: PolygonalGeofenceMapComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PolygonalGeofenceMapComponent extends MappableComponent implements ControlValueAccessor, Validator {
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};
  private disabled = false;
  private _polygon: XYPoint[] = [];
  private _polygonControl: AbstractControl | null = null;

  @Input()
  public set polygon(control: AbstractControl) {
    if (control) {
      this._polygonControl = control;
      this._polygon = control.value || [];
      this.recalculateMap();
      control.valueChanges.subscribe((value: XYPoint[]) => {
        this._polygon = value;
        this.recalculateMap();
      });
    }
  }

  private _polygons: XYPoint[][] | null = null;
  @Input()
  public set polygons(value: XYPoint[][] | null) {
    this._polygons = value;
    this.recalculateMap();
  }
  public get polygons(): XYPoint[][] | null {
    return this._polygons;
  }

  private draw: MapboxDraw | null = null;

  constructor() {
    super(SATELLITE);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.recalculateMap();
  }

  public writeValue(obj: any): void {
    this._polygon = obj;
    this.recalculateMap();
  }

  public override onStyleLoad() {
    super.onStyleLoad();
    this.initializeDrawControl();
    this.recalculateMap();
  }

  public registerOnValidatorChange(fn: () => void): void {
    // Do nothing
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value as XYPoint[];
    if (!value) {
      return null;
    }
    if (value.length < 4) {
      return {
        polygon: 'Polygon must have at least 3 points',
      };
    }
    if (value[0].x !== value[value.length - 1].x || value[0].y !== value[value.length - 1].y) {
      return {
        polygon: 'Polygon must be closed',
      };
    }
    return null;
  }

  private initializeDrawControl() {
    if (!this.map) {
      return;
    }

    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
    });

    this.map.addControl(this.draw);

    this.map.on('draw.create', (e) => this.handlePolygonChange());
    this.map.on('draw.update', (e) => this.handlePolygonChange());
    this.map.on('draw.delete', (e) => this.handlePolygonChange());
  }

  private recalculateMap() {
    if (!this.map) {
      return;
    }

    this.updateMainPolygon();
    this.updateAdditionalPolygons();
    this.updateBounds();
  }

  private updateMainPolygon() {
    if (!this.map || !this.draw) {
      return;
    }

    this.draw.deleteAll();

    // If there's a polygon, draw it
    if (this._polygon.length > 0) {
      this.draw.set({
        type: 'FeatureCollection',
        features: [
          {
            id: 'main-polygon',
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Polygon',
              coordinates: [this._polygon.map(({ x, y }) => [x, y])],
            },
          },
        ],
      });
    }
  }

  private updateAdditionalPolygons() {
    if (!this.map || !this.polygons || this.polygons.length === 0) {
      if (this.map.getSource('additional-polygons')) {
        this.map.removeLayer('additional-polygons');
        this.map.removeSource('additional-polygons');
      }
      return;
    }

    const features: Feature<Polygon>[] = this.polygons.map((polygon) => ({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [polygon.map(({ x, y }) => [x, y])],
      },
    }));

    const featureCollection: FeatureCollection<Polygon> = {
      type: 'FeatureCollection',
      features,
    };

    const source = this.map.getSource('additional-polygons') as GeoJSONSource;
    if (source) {
      source.setData(featureCollection as any);
    } else {
      this.map.addSource('additional-polygons', {
        type: 'geojson',
        data: featureCollection as any,
      });

      this.map.addLayer({
        id: 'additional-polygons',
        type: 'fill',
        source: 'additional-polygons',
        paint: {
          'fill-color': '#FFA500',
          'fill-opacity': 0.5,
        },
      });
    }
  }

  private updateBounds() {
    if (!this.map || this._polygon.length === 0) {
      return;
    }

    const center = centroid({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [this._polygon.map(({ x, y }) => [x, y])],
      },
    });
    this.map.setCenter(center.geometry.coordinates as any);

    const bounds = new LngLatBounds();
    this._polygon.forEach(({ x, y }) => {
      bounds.extend([x, y]);
    });
    this.map.fitBounds(bounds, {
      padding: 100,
    });
  }

  private handlePolygonChange() {
    if (!this.draw || !this.map || !this._polygonControl) {
      return;
    }

    const data = this.draw.getAll() as FeatureCollection<Polygon>;
    if (data.features.length > 0) {
      const coordinates = data.features[0].geometry.coordinates[0];
      const updatedPolygon = coordinates.map(([x, y]) => ({ x, y }));

      this._polygonControl.setValue(updatedPolygon, { emitEvent: false });
      this.onChange(updatedPolygon); // Notify the parent component of the change
    }
  }
}

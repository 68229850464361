import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { EapSummary } from '../../../modules/internal/loads/load.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'td-eap-summary',
  templateUrl: './eap-summary.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class EapSummaryComponent {
  private clipboard = inject(Clipboard);
  private snackbar = inject(MatSnackBar);
  private eapSummary$$ = new BehaviorSubject<EapSummary>(null);
  public eapSummary$: Observable<EapSummary> = this.eapSummary$$.pipe(shareReplay(1));
  private canAddEap$$ = new BehaviorSubject<boolean>(false);
  public canAddEap$: Observable<boolean> = this.canAddEap$$.pipe(shareReplay(1));
  private selectedFacilityId$$ = new BehaviorSubject<string>(null);
  public selectedFacilityId$: Observable<string> = this.selectedFacilityId$$.pipe(shareReplay(1));
  public selectedIsBest$: Observable<boolean> = combineLatest([this.eapSummary$, this.selectedFacilityId$]).pipe(
    map(([eapSummary, selectedFacilityId]) => {
      if (!eapSummary || !selectedFacilityId) {
        return false;
      }
      return selectedFacilityId === eapSummary.bestFacility?.id;
    }),
  );
  public selectedFacility$ = combineLatest([this.eapSummary$, this.selectedFacilityId$]).pipe(
    map(([eapSummary, selectedFacilityId]) => {
      if (!eapSummary || !selectedFacilityId) {
        return null;
      }
      // don't show the facility twice if they are selecting the best facility
      if (selectedFacilityId === eapSummary.bestFacility?.id) {
        return null;
      }
      return eapSummary.potentialFacilities.find((f) => f.id === selectedFacilityId);
    }),
  );

  @Input()
  public set eapSummary(v: EapSummary) {
    this.eapSummary$$.next(v);
  }

  @Input()
  public set canAddEap(v: boolean) {
    this.canAddEap$$.next(v);
  }

  @Input()
  public set selectedFacilityId(v: string) {
    this.selectedFacilityId$$.next(v);
  }

  public get selectedFacilityId() {
    return this.selectedFacilityId$$.value;
  }

  public get eapSummary() {
    return this.eapSummary$$.value;
  }

  public get canAddEap() {
    return this.canAddEap$$.value;
  }

  public copyToClipboard(text: string) {
    this.clipboard.copy(text);
    this.snackbar.open('Copied to clipboard', 'Dismiss', { duration: 2000 });
  }
}

import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NetworkableDestroyableComponent } from '../networkable-destroyable.component';
import { UserManagementService } from '../../services/user-management.service';
import { SnackBarService } from '../../services/snackbar.service';

@Component({
  selector: 'td-create-driver',
  templateUrl: './create-driver.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CreateDriverComponent extends NetworkableDestroyableComponent {
  public form: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private ref: MatDialogRef<CreateDriverComponent>,
    private snackbar: SnackBarService,
    private umService: UserManagementService,
    @Inject(MAT_DIALOG_DATA) public companyData: { id: string; name: string },
  ) {
    super();
    this.form = this.fb.group({
      driverName: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required]],
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }

  public async createAdmin() {
    if (this.isActive || this.form.invalid) {
      return;
    }
    try {
      this.networkActive$$.next(true);
      const val = this.form.value;
      val.companyId = this.companyData.id;
      const adminID = await this.umService.createNewDriver(val);
      if (!!adminID) {
        this.ref.close(adminID);
        this.snackbar.showMessage('Driver created');
      }
    } finally {
      this.networkActive$$.next(false);
    }
  }
}

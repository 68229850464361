import { Pipe, PipeTransform } from '@angular/core';
import { DisputeStatusOld } from '../global-types';

const statuses: Record<DisputeStatusOld, string> = {
  [DisputeStatusOld.dispute_pending]: 'Dispute Pending',
  [DisputeStatusOld.dispute_accepted]: 'Dispute Accepted',
  [DisputeStatusOld.grace_period_applied]: 'Grace Period Applied',
  [DisputeStatusOld.free_pass_applied]: 'Free Pass Applied',
  [DisputeStatusOld.dispute_denied]: 'Dispute Denied',
};

@Pipe({
  name: 'disputeStatus',
  standalone: false,
})
export class DisputeStatusPipe implements PipeTransform {
  public transform(value: DisputeStatusOld): string {
    return statuses[value] || value;
  }
}

import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { stringOrDateToDate } from '../utilities/dateTime';
import { LongLat, XYPoint } from '../global-types';
import { TimezoneService } from '../services/timezone.service';
import { firstValueFrom, lastValueFrom, Subject, takeUntil, tap } from 'rxjs';
import { DateTime } from 'luxon';
import { take } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxMatDatepickerInput } from '@ngxmc/datetime-picker';

@Directive({
  selector: '[tdDatePicker]',
  standalone: false,
})
export class DatePickerDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input() public lnglat: LongLat | XYPoint = null;
  @Input() public lng: number = null;
  @Input() public lat: number = null;
  private hint: HTMLDivElement;
  private destroy$$ = new Subject<void>();

  constructor(
    private datePicker: NgxMatDatepickerInput<Date>,
    private formControl: NgControl,
    private elRef: ElementRef<HTMLInputElement>,
    private timezoneService: TimezoneService,
    private matDialog: MatDialog,
  ) {}

  public ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.setupDateHintInTimezone();
  }

  @HostListener('click', ['$event'])
  public onClick(event: Event) {
    event.stopPropagation();
    this.datePicker.writeValue(stringOrDateToDate(this.formControl.value) || new Date());
    this.datePicker._datepicker.open();
    /*let compRef: MatDialogRef<NgxMatDatetimeContent<Date>>;
    for (let openDialog of this.matDialog.openDialogs) {
      if (openDialog.componentInstance instanceof NgxMatDatetimeContent) {
        compRef = openDialog;
        break;
      }
    }
    compRef.componentInstance.datepicker.*/
  }

  public ngAfterViewInit(): void {
    this.formControl.valueChanges.pipe(takeUntil(this.destroy$$)).subscribe(() => {
      this.setupDateHintInTimezone();
    });
    this.setupDateHintInTimezone();
  }

  public async setupDateHintInTimezone() {
    try {
      if (!this.lnglat && !(this.lng && this.lat)) {
        return;
      }
      if (!this.formControl.value) {
        return;
      }
      const timezone = await lastValueFrom(
        this.timezoneService
          .queryTimezoneForLocation$(
            this.lat || (this.lnglat as XYPoint)?.y || (this.lnglat as LongLat)?.latitude,
            this.lng || (this.lnglat as XYPoint)?.x || (this.lnglat as LongLat)?.longitude,
          )
          .pipe(take(1)),
      );
      const value = this.formControl.value;
      const formattedDate = this.getFormattedDate(value, timezone.name);
      if (this.hint) {
        this.hint.remove();
      }
      const hint = this.elRef.nativeElement.parentElement.parentElement.parentElement.querySelector('.mat-hint');
      if (hint) {
        const newHint = document.createElement('div');
        newHint.classList.add('removeable-hint');
        newHint.innerHTML = `${formattedDate}`;
        this.hint = newHint;
        hint.append(newHint);
      } else {
        const parent = document.createElement('div');
        parent.className = 'mat-form-field-subscript-wrapper';
        const wrapper = document.createElement('div');
        wrapper.className = 'mat-form-field-hint-wrapper';
        const hint = document.createElement('div');
        hint.className = 'mat-hint';
        hint.innerText = formattedDate;
        wrapper.appendChild(hint);
        parent.appendChild(wrapper);
        this.hint =
          this.elRef.nativeElement.parentElement.parentElement.parentElement.parentElement.appendChild(parent);
      }
    } catch (e) {
      console.error(e);
    }
  }

  private getFormattedDate(date: Date | string, timezone: string): string {
    const inTz =
      typeof date === 'string' || date instanceof String
        ? DateTime.fromISO(date as string, {
            zone: timezone,
          })
        : DateTime.fromJSDate(date, {
            zone: timezone,
          });
    return inTz.toFormat('h:mma M/d/yyyy ZZZZ', {
      locale: 'en-us',
    });
  }
}

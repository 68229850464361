import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayOfWeek',
  standalone: false,
})
export class DayOfWeekPipe implements PipeTransform {
  public transform(value: number[] | number): string[] {
    if (!value && value !== 0) {
      return [];
    }
    if (Array.isArray(value)) {
      return value.map(DayOfWeekPipe.dayIndexToName).filter((v) => !!v);
    } else {
      return [DayOfWeekPipe.dayIndexToName(value, value)];
    }
  }

  private static dayIndexToName(value: number, index: number) {
    if (!value && value !== 0) {
      return '';
    }
    switch (index) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      default:
        return 'Unknown';
    }
  }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '../../../../shared/services/snackbar.service';
import { NetworkActiveComponent } from '../../../../shared/components/networkActive.component';
import { CarrierListService } from '../carrier-list.service';
import { BasicUser } from '../../../../shared/global-types';
import { FusedObservable } from '../../../../shared/utilities/fusedObservable';

@Component({
  selector: 'td-assign-carrier-representative',
  templateUrl: './assign-carrier-representative.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AssignCarrierRepresentativeComponent extends NetworkActiveComponent {
  public searchFc = new FormControl<string>(null);
  public dispatcherControl = new FormControl<string>(null, []);
  public dispatchers$: Observable<BasicUser[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: string; carrierRepresentativeId?: string },
    private carrierListService: CarrierListService,
    private ref: MatDialogRef<AssignCarrierRepresentativeComponent>,
    private snackbar: SnackBarService,
  ) {
    super();
    this.dispatchers$ = new FusedObservable(
      this.carrierListService.assignableDispatcherUsers$,
      this.searchFc.valueChanges,
      ['userName'],
    ).fused$;
    this.dispatcherControl.setValue(this.data.carrierRepresentativeId);
  }

  public async reassignCarrierRepresentative() {
    if (this.networkActive$$.value || !this.data.id) {
      return;
    }

    try {
      this.networkActive$$.next(true);

      if (this.dispatcherControl.value) {
        let dispatcher = this.dispatcherControl.value;
        if (dispatcher === 'unassign') {
          dispatcher = null;
        }
        const success = await this.carrierListService.reAssignCarrierRepresentative(this.data.id, dispatcher);
        if (success) {
          this.snackbar.showMessage('New Representative Assigned');
          this.ref.close();
        }
      }
    } finally {
      this.networkActive$$.next(false);
    }
  }
}

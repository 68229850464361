import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[tdHighlightText]',
  standalone: false,
})
export class HighlightTextDirective implements OnInit, OnChanges {
  @Input() public highClass = 'text-stickyNoteBrown';
  @Input() public highlightIndex: number[][];
  @Input() public text: string;

  constructor(private element: ElementRef) {}

  public ngOnInit(): void {
    this.redraw();
  }

  private redraw() {
    if (!this.highlightIndex) {
      return;
    }
    let text = this.text;
    const start = `<span class="${this.highClass}">`;
    const end = `</span>`;
    for (const highlightIndex1 of this.highlightIndex) {
      if (highlightIndex1.length !== 2) {
        continue;
      }
      text = [
        text.slice(0, highlightIndex1[0]),
        start,
        text.slice(highlightIndex1[0], highlightIndex1[1] + 1),
        end,
        text.slice(highlightIndex1[1] + 1),
      ].join('');
    }
    this.element.nativeElement.innerHTML = text;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.redraw();
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { isToday, isTomorrow } from 'date-fns';
import { getUserShortTimezone } from '../utilities/dateTime';

@Pipe({
  name: 'standardTime',
  standalone: false,
})
export class StandardTimePipe extends DatePipe implements PipeTransform {
  // @ts-ignore
  public transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
    if (!value) {
      return '—';
    }
    return super.transform(value, standardTimeFormat(value));
  }
}

export const standardTimeFormat = (value: any): string => {
  const timezone = getUserShortTimezone() || '';

  const asDate = new Date(value);
  let formattingText = `h:mmaaaaa'm' '${timezone}'`;
  if (isToday(asDate)) {
    formattingText = `h:mmaaaaa'm' '${timezone}'`;
  } else if (isTomorrow(asDate)) {
    formattingText = `h:mmaaaaa'm' '${timezone}'`;
  }
  return formattingText;
};

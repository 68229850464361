import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MappableComponent, SATELLITE, STREETS } from '../mappable/mappable.component';
import { equals, flatten } from 'remeda';
import { Position } from 'geojson';
import { LngLatBounds } from 'mapbox-gl';

@Component({
  selector: 'td-geojson-map',
  templateUrl: './geojson-map.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GeojsonMapComponent extends MappableComponent implements OnChanges, AfterViewInit {
  private sourceIDs: string[] = [];
  private layerIDs: string[] = [];
  @Input() public geojsons: (GeoJSON.Polygon | GeoJSON.MultiPolygon)[] = [];
  @Input() public streetStyle = true;

  private mapInit = false;

  constructor() {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.mapInit) {
      return;
    }
    const geoJsons = changes['geojsons'];
    if (geoJsons) {
      if (!equals(geoJsons.currentValue, geoJsons.previousValue)) {
        this.addSourceAndLayers();
      }
    }
  }

  public override onStyleLoad() {
    super.onStyleLoad();
    this.mapInit = true;
    this.map.setStyle(this.streetStyle ? STREETS : SATELLITE);
  }

  private clearLayers() {
    for (const layerID of this.layerIDs) {
      this.map.removeLayer(layerID);
    }
    this.layerIDs = [];
  }

  private clearSources() {
    for (const sourceID of this.sourceIDs) {
      this.map.removeSource(sourceID);
    }
    this.sourceIDs = [];
  }

  private async addSourceAndLayers() {
    const cleanedGeojsons = this.geojsons?.filter((geojson) => geojson && geojson.coordinates);
    if (!cleanedGeojsons?.length || !this.map) {
      return;
    }
    this.clearLayers();
    this.clearSources();
    for (let i = 0; i < cleanedGeojsons.length; i++) {
      const sourceID = `source_${i}`;
      this.map.addSource(sourceID, {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: this.geojsons[i],
        },
      });
      this.sourceIDs.push(sourceID);
      const layerID = `layer_${i}`;
      this.map.addLayer({
        id: layerID,
        type: 'fill',
        source: `source_${i}`,
        paint: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'fill-color': '#088',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'fill-opacity': 0.5,
        },
      });
      this.layerIDs.push(layerID);
      const lineLayerID = `layer_${i}_line`;
      this.map.addLayer({
        id: lineLayerID,
        type: 'line',
        source: `source_${i}`,
        paint: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'line-color': '#088',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'line-width': 3,
        },
      });
      this.layerIDs.push(lineLayerID);
    }
    setTimeout(() => this.fitGeo());
  }

  private fitGeo() {
    const cleanedGeojsons = this.geojsons.filter((geojson) => geojson && geojson.coordinates);
    const bounds = new LngLatBounds();
    for (const geo of cleanedGeojsons) {
      const positions = extractPositions(geo.coordinates);
      if (positions.length) {
        for (const pos of positions) {
          if (pos[0] && pos[1]) {
            bounds.extend([pos[0], pos[1]]);
          }
        }
      }
    }
    this.map.fitBounds(bounds, {
      maxDuration: 300,
      padding: 25,
    });
  }
}

const extractPositions = (coordinates: Position[][][] | Position[][] | Position[]): Position[] => {
  if (isPosition(coordinates[0])) {
    return coordinates as Position[];
  }
  if (isPosition(coordinates[0][0])) {
    return flatten(coordinates as Position[][]);
  }
  return flatten((coordinates as Position[][][]).map(extractPositions));
};

const isPosition = (pos: any): pos is Position => {
  return Array.isArray(pos) && pos.length === 2 && typeof pos[0] === 'number' && typeof pos[1] === 'number';
};

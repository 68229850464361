"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const AbstractTimeExpressionParser_1 = require("../../../common/parsers/AbstractTimeExpressionParser");
class PTTimeExpressionParser extends AbstractTimeExpressionParser_1.AbstractTimeExpressionParser {
  primaryPrefix() {
    return "(?:(?:ao?|às?|das|da|de|do)\\s*)?";
  }
  followingPhase() {
    return "\\s*(?:\\-|\\–|\\~|\\〜|a(?:o)?|\\?)\\s*";
  }
}
exports.default = PTTimeExpressionParser;

@if (clicked$ | async) {
<div class="flex items-center">
  @switch (buttonType) { @case ('button') {
  <button [disabled]="disabled$ | async" mat-button [color]="buttonColor" (click)="confirm.emit(true)">
    {{ confirmText }}
  </button>
  } @case ('raised') {
  <button [disabled]="disabled$ | async" mat-raised-button [color]="buttonColor" (click)="confirm.emit(true)">
    {{ confirmText }}
  </button>
  } @case ('stroked') {
  <button [disabled]="disabled$ | async" mat-stroked-button [color]="buttonColor" (click)="confirm.emit(true)">
    {{ confirmText }}
  </button>
  } @case ('flat') {
  <button [disabled]="disabled$ | async" mat-flat-button [color]="buttonColor" (click)="confirm.emit(true)">
    {{ confirmText }}
  </button>
  } } @if (disabled$ | async) {
  <mat-spinner [diameter]="24" mode="indeterminate"></mat-spinner>
  }
</div>
} @else {
<ng-content></ng-content>
}
